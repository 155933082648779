import {
  Stack,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { 
  InfoTypography,
  TopicCardSignatureStack
} from "./TopicCardSignature.styles";
import type { TopicCardSignatureProps } from "./TopicCardSignature.types";

import {
  Icon
} from "../../components";


export function TopicCardSignature({
  created,
  isModified,
  readingTime,
  size,
  ...props
}: TopicCardSignatureProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <TopicCardSignatureStack 
      flexGrow={ 1 }
      { ...props }
    >
      
      {
        created ? 
          <Stack 
            alignItems={ "center" }
            direction={ "row" } 
            gap={ 1 }>
            <Icon 
              color={ theme.customColors.systemPrimary01 }
              icon={ "Icons_time" } 
              size={ isMobile || size === "small" ? 15 : 20 } 
            />
            <InfoTypography
              fontSize={ size === "small" ? theme.spacing(1.75) : theme.spacing(2) }
            >{ created }</InfoTypography>

            {
              isModified ? 
                
                <InfoTypography  
                  fontSize={ size === "small" ? theme.spacing(1.4) : theme.spacing(2.1) }
                > {  "( " +  t("modified") + " )" } </InfoTypography> : null
            }
          </Stack> : null
      } 

      {
        readingTime ? 

          <Stack 
            alignItems={ "center" }
            direction={ "row" } 
            gap={ 1 }
          >
            <Icon 
              color={ theme.customColors.systemPrimary01 }
              icon={ "icon_glasses" } 
              size={ isMobile || size === "small" ? 15 : 20 } 
            />
            <InfoTypography 
              fontSize={ size === "small" ? theme.spacing(1.75) : theme.spacing(2) }
            > { readingTime } </InfoTypography>
          </Stack> :  
          null
      }
    </TopicCardSignatureStack>
  );
  
}
