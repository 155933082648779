/* eslint-disable max-len */
import {
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  // useLoaderData,
  useNavigate,
  useParams
} from "react-router-dom";

import {
  GridMain,
  HeroBanner,
  Modal,
  ModalEcmComponent,
  Spinner,
  Tabs
} from "../../components";
import {
  STATUS_LO,
  LEVEL,
  TAB_ITEMS_CONFIG
} from "../../consts";
import {
  useEditionsQuery,
  useLearningObjectQuery,
  useRelatedLearningObjectQuery,
  useToolsQuery
} from "../../queries";
import { useSharedPosts } from "../../queries/community";
import { useAuthStore } from "../../stores";
import type {
  Course,
  LearningActivity,
  Level,
  Root,
  StatusLO
} from "../../types";
import { getError } from "../../utils/error";
import {
  filterTabTypes,
  getExploreTabProps
} from "../../utils/explore";

export function ExploreDetail() {
  const isNotFoundCode = "core-005";
  const { t } = useTranslation();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { state: routerState } = useLocation();
  const { id: idString, learningObjectTypology } = useParams<{
    id: string
    learningObjectTypology: Level
  }>();
  const id = idString ? parseInt(idString) : NaN;
  const [statusFilter, setStatusFilter] = useState<StatusLO | null>(null);
  const [openModalEcm,setOpenModalEcm]=useState(false);
  const [
    placeholderLO,
    setPlaceholderLO
  ] = useState<Course | LearningActivity | Root | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);

  const isLearningActivity = (
    learningObjectTypology !== LEVEL.COURSE && learningObjectTypology !== LEVEL.PATH
  );
  const isCourse = (
    learningObjectTypology === LEVEL.COURSE
  );

  const courseId = routerState?.courseId
    ? routerState.courseId as number
    : learningObjectTypology === LEVEL.COURSE
      ? id
      : undefined;

  const rootId = routerState?.rootId
    ? routerState.rootId as number
    : learningObjectTypology === LEVEL.PATH && id
      ? id
      : undefined;
  const loRootId = routerState?.loRootId
    ? routerState.loRootId as number
    : undefined;

  const {
    data: learningObject,
    error: learningObjectError,
    fetchStatus,
    isLoading: learningObjectIsLoading,
    isError: learningObjectIsError,
    isSuccess: learningObjectIsSuccess,
    refetch:refetchLo
  } = useLearningObjectQuery({
    courseId: courseId,
    enabled: Boolean(!isNaN(id) && learningObjectTypology),
    id: id ?? "",
    learningObjectTypology: learningObjectTypology,
    placeholderData: placeholderLO,
    rootId: (isLearningActivity || isCourse ) ?  rootId ?? loRootId : null,
    status: statusFilter
  });

  const {
    data: parentCourse,
    refetch:refetchParentCourse
  } = useLearningObjectQuery({
    enabled: Boolean(courseId),
    id: routerState?.courseId ?? "",
    learningObjectTypology: LEVEL.COURSE,
    rootId: (isLearningActivity || isCourse ) ?  rootId ?? loRootId : null
  });


  // const {
  //   data: parentRoot
  // } = useLearningObjectQuery({
  //   enabled: Boolean(rootId),
  //   id: state?.rootId ?? "",
  //   learningObjectTypology: LEVEL.PATH,
  //   onlyLearningActivity: true
  // });

  const { data: editions } = useEditionsQuery({ 
    classroomId: id?.toString() ?? ""
  });
  const { data: related } = useRelatedLearningObjectQuery({
    learningObjectId: id?.toString() ?? ""
  });
  const { data: tools } = useToolsQuery({
    learningObjectId: learningObject?.id?.toString() ?? ""
  });

  const communityFlag = enableCommunityFlag === true && smartConfiguration?.funcCommunity === true && smartConfiguration?.communityShareContent;

  const { data: sharedPosts } = useSharedPosts({ enabled: communityFlag, loId: id });

  const activities =
    // (parentRoot && "learningActivities" in parentRoot) ? parentRoot.learningActivities : 
    (parentCourse && "learningActivities" in parentCourse) ? parentCourse.learningActivities :
      (learningObject && "learningActivities" in learningObject) ? learningObject.learningActivities :
        [];

  const editionBooked = editions?.find(edition => edition.status === "E") || undefined;
  const hasEdition =  learningObject?.status === "R" && editions?.some(edition => 
    edition.status !== "R" 
  ) || undefined;

  const filteredTabTypes =
    learningObject?.learningObjectTypology ? (
      filterTabTypes({
        activities,
        editions,
        learningObject,
        related,
        sharedPosts,
        tabItems: TAB_ITEMS_CONFIG[learningObject?.learningObjectTypology].tabItems,
        tools
      })
    ) : [];

  const contentTabs = filteredTabTypes?.flatMap((tabType) => (
    learningObject ? (
      getExploreTabProps({
        activities,
        courseId,
        editions,
        filter: statusFilter,
        isDetailPage: true,
        learningObject,
        related,
        rootId: rootId ?? loRootId,
        setFilter: setStatusFilter,
        setOpenModalEcm:setOpenModalEcm,
        sharedPosts,
        t,
        tabType,
        tools
      })) : []
  ));
  
  const isNotFound = getError(learningObjectError)?.[0]?.cause === isNotFoundCode; 
  useEffect(()=> {
    if (learningObjectError && !isNotFound){
      setShowModal(true);
    }
    if(isNotFound){
      navigate("/not-found");
    }
  }, [isNotFound, learningObjectError, navigate]);

  useEffect(() => {
    if (learningObjectIsSuccess) {
      setPlaceholderLO(learningObject);
    }
  }, [learningObject, learningObjectIsSuccess]);
  
 

  return (
    <>
      {
        (learningObjectIsLoading && fetchStatus !== "idle" ) ? (
          <Spinner/>
        ) : 
          (
            isNotFound || !learningObject ? (
              <p>error occurred</p>
            ) : (
              <>
                <HeroBanner
                  badge={ learningObject.badge }
                  booked={
                    (learningObject.status === STATUS_LO.E)
                    || (learningObject.status === STATUS_LO.P)
                  }
                  categories={ learningObject.topicTags || [] }
                  certificate={ learningObject.certificate }
                  coverVideoPublicUrl={ learningObject?.coverVideoPublicURL }
                  courseId={ courseId }
                  coverPublicURL={ learningObject.coverPublicURL?.toString() || "" }
                  duration={ learningObject.duration }
                  ecmType={ learningObject.ecmType }
                  editionEndSubscriptionDate={ editionBooked?.endTime }
                  editionNumber={ editions?.length }
                  editionStartSubscriptionDate={ editionBooked?.startTime }
                  editionVirtualLink={ editionBooked?.link }
                  expirationDate={ learningObject?.expirationDate }
                  endDate={ learningObject?.ecmEndDate ? [
                    learningObject?.ecmEndDate?.[0], 
                    learningObject?.ecmEndDate?.[1],
                    learningObject?.ecmEndDate?.[2]] : undefined }
                  enrollType={ learningObject.enrollType }
                  id={ learningObject?.id }
                  ecmRegistration={ learningObject.ecmRegistration }
                  ecm_specialization={ learningObject.ecm_specialization }
                  isAutoSubscribeEnable={ learningObject.isAutoSubscribeEnable }
                  isFavourite={ learningObject?.isFavourite }
                  isMandatory={ learningObject.isMandatory }
                  isSurvey={ "isSurvey" in learningObject ? learningObject.isSurvey : false }
                  isTest={  "isTest" in learningObject ? learningObject.isTest : false }
                  learningObjectType={ learningObject.learningObjectType }
                  learningObjectTypology={
                    learningObject.learningObjectTypology
                    || learningObjectTypology
                  }
                  percentageOfCompletion={ learningObject.percentageOfCompletion }
                  rating={ learningObject.averageRatingScore ?? 0 }
                  rootId={ rootId ?? loRootId }
                  setOpenModalEcm={ setOpenModalEcm }
                  shortDescription={ learningObject.shortDescription }
                  startDate={ learningObject.ecmStartDate ?  [
                    learningObject?.ecmStartDate?.[0], 
                    learningObject?.ecmStartDate?.[1],
                    learningObject?.ecmStartDate?.[2]] : undefined }
                  status={  hasEdition ? "N" : learningObject?.status }
                  title={ learningObject.title }
                />
                <GridMain padding={ 0 }>
                  <Tabs
                    contents={ contentTabs }
                    titleInset={ true }
                  />
                </GridMain>
              </>
            )
          )
      }

      { 
        learningObject && learningObject.enrollId && openModalEcm ? 
          <ModalEcmComponent
            courseId={ courseId }
            open={ openModalEcm }
            invalidateLearningObects={ ()=> {
              refetchLo();
              if(courseId){
                refetchParentCourse();
              }
              
            } }
            loId={ id }
            enrollId={ learningObject?.enrollId }
            onClose={ ()=> setOpenModalEcm(false) }
            rootId={ rootId }
            sponsors={ learningObject?.sponsors }
          /> : null
      }

      <Modal 
        description={ getError(learningObjectError)?.[0]?.message ?? "" }
        open={ learningObjectIsError && showModal }
        title={ getError(learningObjectError)?.[0]?.name ?? "" }
        onClose={ ()=> { 
          setShowModal(false);
          navigate(-1);
        } }
      />
    </>
  );
}
