import {
  Box,
  BoxProps,
  Chip,
  Grid,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { HTMLProps } from "react";

export const CardGrid = styled(Grid)(({ theme }) => ({
  "> *": {
    padding: theme.spacing(1.5),
    paddingBlock: theme.spacing(0.25)
  },

  ".card-cta":{
    justifyContent:"flex-start",

    ">div": {
      maxWidth: "300px",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        maxWidth: "239px",
        minWidth: "239px",
        width: "100%",

        "&:first-of-type": {
          marginBottom: theme.spacing(1)
        }
      }
    }
  },
  ".card-signature":{
    flexBasis:"content",
    marginLeft:"auto",
    padding:"0",

    ".expiration":{
      display:"none"
    }
  }
}));

export const CardBodyGrid = styled(Grid)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "space-between",

  gap:"16px",
  height:"fit-content",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    gap: "4px",
    padding: "0 !important"
  }
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1.3125,
  textAlign: "start",
  // width: "95%",

  /* CLAMP TEXT TO 2 LINES */
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "2",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    marginBottom: "12px"
  }
}));

export const ImageBox = styled(Box)<
  BoxProps & HTMLProps<HTMLImageElement>
>(({ theme }) => ({
// export const ImageBox = styled("img")(() => ({
  aspectRatio: "9 / 7",
  borderRadius:"8px",
  boxShadow:"16px 16px 22px rgba(0, 0, 0, 0.4)",
  objectFit: "cover",
  padding:  "6px 0 ",
  paddingBlock: " 0 !important",

  height:"236px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    height: "117px"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.3125,

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem"
  }
}));

export const CategoryChip = styled(Chip)(() => ({
  borderRadius: "4px",
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: 1,
  padding: "8px",
  textTransform: "uppercase"
}));
