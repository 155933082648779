import type { MouseEvent } from "react";
import type { GuardedLinkProps } from "./GuardedLink.types";
import {
  ButtonText,
  Link
} from "../../components";

/**
 * the GuardedLink component is meant for buttons which provide access to
 * history based navigation between pages and implements a shutoff by reading
 * the mandatory boolean return value of the onClick handler
 */
export function GuardedLink({
  alignSelf,
  children,
  disableLink = false,
  href,
  onClick,
  target,
  state,
  type,
  ...props
} : GuardedLinkProps) {

  return (
    <Link
      disabled={ disableLink }
      alignSelf={ alignSelf }
      href={ href }
      minWidth={ "128px" }
      state={ state }
      sx={ props.sx }
      tabIndex={ -1 }
      target={ target }
    >
      <ButtonText
        onClick={ (clickEvent: MouseEvent<HTMLButtonElement>) => {
          // legacy code, guarded links should not navigate
          // instead use navigation in component by checking auth store flag
          const validResult = onClick ? onClick(clickEvent) : true;
          !validResult && clickEvent.preventDefault();
      
        } }
        type={ type }
        { ...props }
      >
        { children }
      </ButtonText>
    </Link>
  );
}
