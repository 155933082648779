import i18next from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import ChainedBackend from "i18next-chained-backend";
// import HttpBackend from "i18next-http-backend";
// import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import format from "./i18n-format";
import bundledTranslations from "./translations.json";
import { getLanguage } from ".././utils/language";


// https://www.i18next.com/how-to/add-or-load-translations
// https://www.i18next.com/how-to/backend-fallback
// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/
// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(initReactI18next)
  // .use(ChainedBackend)
  // .use(LanguageDetector) /* TODO: setup properly the language detection */
  .init({
    // backend: {
    //   backends: [
    //     HttpBackend,
    //     resourcesToBackend(bundledTranslations)
    //   ]
    // },
    debug: import.meta.env.DEV,
    fallbackLng: getLanguage().id,
    // lng: "it",
    interpolation: {
      escapeValue: false,
      format
    },
    nonExplicitSupportedLngs: true, // 'it' checks with 'it-IT',
    resources: bundledTranslations,
    supportedLngs: [
      // ISO 639-1 language codes, all ltr ISO languages
      "aa", "ab", "ae", "af", "ak", "am", "an", "ar", "as", "av", "ay", "az", "ba", "be", "bg", 
      "bi", "bm", "bn", "bo", "br", "bs", "ca", "ce", "ch", "co", "cr", "cs", "cu", "cv", "cy", 
      "da", "de", "dv", "dz", "ee", "el", "en", "eo", "es", "et", "eu", "fa", "ff", "fi", "fj", 
      "fo", "fr", "fy", "ga", "gd", "gl", "gn", "gu", "gv", "ha", "he", "hi", "ho", "hr", "ht", 
      "hu", "hy", "hz", "ia", "id", "ie", "ig", "ii", "ik", "io", "is", "it", "iu", "ja", "jv", 
      "ka", "kg", "ki", "kj", "kk", "kl", "km", "kn", "ko", "kr", "ks", "ku", "kv", "kw", "ky", 
      "la", "lb", "lg", "li", "ln", "lo", "lt", "lu", "lv", "mg", "mh", "mi", "mk", "ml", "mn", 
      "mr", "ms", "mt", "my", "na", "nb", "nd", "ne", "ng", "nl", "nn", "no", "nr", "nv", "ny", 
      "oc", "oj", "om", "or", "os", "pa", "pi", "pl", "ps", "pt", "qu", "rm", "rn", "ro", "ru", 
      "rw", "sa", "sc", "sd", "se", "sg", "si", "sk", "sl", "sm", "sn", "so", "sq", "sr", "ss", 
      "st", "su", "sv", "sw", "ta", "te", "tg", "th", "ti", "tk", "tl", "tn", "to", "tr", "ts", 
      "tt", "tw", "ty", "ug", "uk", "ur", "uz", "ve", "vi", "vo", "wa", "wo", "xh", "yi", "yo", 
      "za", "zh", "zu"
    ]
  });
// https://www.i18next.com/overview/configuration-options

export default i18next;
