import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  CardTop,
  CardGrid,
  DescriptionTypography,
  ImageBox,
  TitleTypography
} from "./CardModuleMobile.styles";
import {
  CardCtaModule,
  // CardModuleProps,
  CardSignature,
  StatusBox
} from "../../components";
import {
  useAccent, useType
  // useIconStatus
} from "../../hooks";
// import { hasPassedExpiration } from "../../utils/datetime";
import type { Module } from "../../utils/explore";

export function CardModuleMobile({
  badge,
  certificate,
  coverPublicURL,
  disabled,
  duration,
  enrollType,
  // editionNumber,
  // endDate,
  // expirationDate,
  id,
  isTest,
  isSurvey,
  isAutoSubscribeEnable,
  learningActivityNumber,
  learningObjectType,
  learningObjectTypology,
  percentageOfCompletion,
  shortDescription,
  // source,
  // startDate,
  status,
  title,
  toolNumber,
  ...props
// }: CardModuleProps) {
}: Module) {
  const accent = useAccent({ status });
  // const iconStatus = useIconStatus({ expirationDate, status });
  // const isExpired = hasPassedExpiration(expirationDate);

  const theme = useTheme();
  const { t } =  useTranslation();
  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);


  return (
    <CardGrid container { ...props }>
      <span className={ "type" }> { isTest ? t("test") : isSurvey ? t("survey") :  typeObjectLearning }</span>
      <TitleTypography >
        { title }
      </TitleTypography>
      <CardTop>
        <Grid item
          position={ "relative" }
          height={ "117px" }
          width={ "156px" }
          sx={ {
            filter: "drop-shadow(25px 25px 80px rgba(21, 21, 21, 0.4))"
          } }
        >
          { Boolean(status === "C" ) &&
            <StatusBox
              accent={ status === "C" ? theme.customColors.systemPrimary02 : accent }
              icon={ "completato " }
            />
          }
          <ImageBox
            alt="card cover"
            component="img"
            src={ coverPublicURL }
          />
        </Grid>
        <CardSignature
          // startDate={ startDate }
          // endDate={ endDate }
          // editionNumber={ editionNumber }
          learningActivityNumber={ learningActivityNumber }
          // expirationDate={ expirationDate }
          duration={ duration }
          direction="column"
          justifyContent={ "flex-end" }
          gap={ 1 }
          resources={ toolNumber }
          xs={ 1 }
          learningObjectTypology={ learningObjectTypology }
        />
      </CardTop>
      <DescriptionTypography>
        { shortDescription }
      </DescriptionTypography>
      <CardCtaModule
        ecm_specialization={ false }
        ecmRegistration={ false }
        className={ "card-cta" }
        disableFavourites={ isTest || isSurvey }
        badge={ badge }
        certificate={ certificate }
        booked={ status !== "N" }
        disabled={ disabled }
        enrollType={ enrollType }
        id={ id }
        isAutoSubscribeEnable={ isAutoSubscribeEnable }
        isCard={ true }
        learningObjectType={ learningObjectType }
        learningObjectTypology={ learningObjectTypology }
        percentageOfCompletion={ percentageOfCompletion }
        status={ status }
      />
    </CardGrid>
  );
}
