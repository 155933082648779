
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useOutletContext
} from "react-router-dom";
import {
  CardGrid,
  CardBodyGrid,
  DescriptionTypography,
  ImageBox,
  TitleTypography
} from "./CardModule.styles";
// import type { CardModuleProps } from "./CardMoudle.types";
import {
  CardSignature,
  Icon,
  StatusBox
} from "../../components";
import {
  useAccent,
  useIconStatus,
  useType
} from "../../hooks";
import { OutletContext } from "../../routes";
import { formatDate } from "../../services";
import { hasPassedExpiration } from "../../utils/datetime";
import type { Module } from "../../utils/explore";
import { CardCtaModule } from "../CardCtaModule";


export function CardModule({
  badge,
  certificate,
  coverPublicURL,
  courseId=undefined,
  disabled,
  duration,
  enrollType,
  id,
  isAutoSubscribeEnable,
  isMandatory,
  expirationDate,
  isStandAlone,
  isSurvey,
  isTest,
  learningActivityNumber,
  learningObjectType,
  learningObjectTypology,
  madeAttempts,
  maxAttempts,
  minimumScore,
  percentageOfCompletion,
  rootId=undefined,
  shortDescription,
  status,
  title,
  toolNumber,
  ...props
}: Module) {
  const { t, i18n } = useTranslation();
  const { sectionUrl } = useOutletContext<OutletContext>();
  const isExpired = hasPassedExpiration(expirationDate);
  const accentColor = useAccent({ isExpired, isMandatory, status });
  const iconStatus = useIconStatus({ status });
  const messageStatusBox = (isExpired && expirationDate && status !== "C" && isMandatory )
    ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
    : undefined;
  const theme = useTheme();
  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);
  const navigate = useNavigate();
  const isTestCompleted = (isTest || isSurvey) && status === "C";

  return (
    <CardGrid container { ...props }>
      <Grid
        item
        xs={ false }
        height={ "236px" }
        margin={ "0 0 8px" }
        position={ "relative" }
        width={ "313px" }
        sx={ {
          filter: "drop-shadow(25px 25px 80px rgba(21, 21, 21, 0.4))"
        } }
      >
        { status === "C"  || isMandatory  ?
          <StatusBox
            message={ messageStatusBox }
            accent={ accentColor }
            icon={ iconStatus }
          /> : null
        }

        <ImageBox
          alt="card cover"

          src={ coverPublicURL }
          onClick={ ()=> {
            if(!disabled){
              navigate(`/${sectionUrl}/dettaglio/${id}/${learningObjectTypology}`, {
                state:{
                  courseId:courseId,
                  loRootId:rootId
                }
              });
            }
           
          } }
          
          sx={ {
            cursor:disabled ? "not-allowed" : "pointer"
          } }
        />
      </Grid>
      <CardBodyGrid item xs={ 6.5 } container>
    
        <span className={ "type" }>
          {
            isTest || isSurvey ? 
              <Icon 
                color={ theme.customColors.accentVariantA }
                icon={ isTest ? "test" : "survey" } 
                size={ 20 }/> : null
          }
          { isTest ? t("test") : isSurvey ? t("survey") :  typeObjectLearning }
        </span>
        <TitleTypography variant="h3">
          { title }
        </TitleTypography>

        <DescriptionTypography>
          { shortDescription }
        </DescriptionTypography>
        <CardCtaModule
          badge={ badge }
          certificate={ certificate }
          className={ "card-cta" }
          courseId={ courseId }
          booked={ status !== "N" }
          disabled={ disabled || isTestCompleted }
          disableFavourites={ isTest || isSurvey }
          enrollType={ enrollType }
          ecm_specialization={ false }
          ecmRegistration={ false }
          isSurvey={ isSurvey }
          isTest={ isTest }
          id={ id }
          isStandAlone={ isStandAlone }
          isAutoSubscribeEnable={ isAutoSubscribeEnable }
          isCard={ true }
          learningObjectType={ learningObjectType }
          learningObjectTypology={ learningObjectTypology }
          madeAttempts={ madeAttempts ?? undefined }
          percentageOfCompletion={ percentageOfCompletion }
          rootId={ rootId }
          status={ status }
        />
      </CardBodyGrid>
      <CardSignature
        xs={ 2 }
        direction="column"
        gap={ 1 }
        justifyContent={ "flex-end" }
        sx={ {
          minWidth:"185px"
        } }
        isSurvey={ isSurvey }
        isTest={ isTest }
        minimumScore={ minimumScore ?? undefined }
        temptative={ isTest && maxAttempts ? maxAttempts - (madeAttempts ?? 0) : undefined }
        duration={ duration }
        learningActivityNumber={ learningActivityNumber }
        learningObjectTypology={ learningObjectTypology }
        resources={ toolNumber }
      />
    </CardGrid>
  );
}
