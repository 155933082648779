import { styled } from "@mui/material/styles";
import {
  Swiper,
  SwiperProps
} from "swiper/react";
import type { CarouselProps } from "./Carousel.types";
import { StyledPortalWrapper } from "../../components/CarouselCard/CarouselCard.styles";
import {
  CAROUSEL_TYPE
} from "../../consts";

type StyledSwiperProps =
  SwiperProps &
  Pick<
    CarouselProps,
    "carouselType"
  > & {
    $isMobile?: boolean
    $label?: string
  }

export const StyledSwiper = styled(Swiper)<
  Omit<StyledSwiperProps, "carouselType"> & {
    $carouselType: StyledSwiperProps["carouselType"],
  }
>(({
  theme,
  $carouselType,
  $label,
  $isMobile
}) => ({
  alignItems: ($carouselType === CAROUSEL_TYPE.ZOOM) ? "center": undefined,
  display: ($carouselType === CAROUSEL_TYPE.ZOOM) && !$isMobile ? "flex": undefined,
  minHeight:
    ($carouselType === CAROUSEL_TYPE.HERO)
      ? $isMobile ? "418px" : "450px"
      : $carouselType === CAROUSEL_TYPE.ZOOM  && !$isMobile
        ?  "400px"
        :  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED
          ? $isMobile ? "313px" : "350px"
          : "unset",
  overflow:  $carouselType === CAROUSEL_TYPE.ZOOM ? "visible" : "hidden",
  zIndex: "unset",

  paddingLeft: ($carouselType === CAROUSEL_TYPE.ZOOM
    ||  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED
  ) ?  "20px" : undefined,
  paddingTop:$carouselType === CAROUSEL_TYPE.ZOOM_RELATED ? "80px" : undefined,

  transform: ($carouselType === CAROUSEL_TYPE.ZOOM ) ? "translateX(-20px)" :
    ($carouselType === CAROUSEL_TYPE.ZOOM_RELATED ) ? "translate(-20px, -80px)"
      : undefined,

  
  "&:before":{
    background: theme.linearGradients.gradientDAlternative07,
    bottom:0,
    content: $carouselType === CAROUSEL_TYPE.ZOOM_RELATED ? "''" : "none",
    heigth:"100%",
    position:"absolute",
    right:"-.5px",
    top:0,
    width : "160px",
    zIndex:9999
  },

  "&:has(:hover)":{
    zIndex:  ($carouselType === CAROUSEL_TYPE.ZOOM  ||  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED) ?
      theme.zIndex.appBar : undefined

  },

  ".swiper-pagination": {
    bottom: $carouselType === CAROUSEL_TYPE.HERO  ? "1rem"  : "1.75rem",
    position:( $carouselType === CAROUSEL_TYPE.ZOOM_RELATED ||
     $carouselType === CAROUSEL_TYPE.ZOOM ||
     $carouselType === CAROUSEL_TYPE.HERO) && !$isMobile ? "absolute !important" : "static",
    width:$carouselType === CAROUSEL_TYPE.ZOOM_RELATED ? "50% !important" : undefined
  },

  ".swiper-pagination-bullet": {
    backgroundColor: theme.customColors.systemPrimary01,
    height: "0.75rem",
    width: "0.75rem"
  },

  ".swiper-pagination-bullet-active": {
    borderRadius: "0.9375rem",
    width: "2.5rem"
  },

  ".swiper-slide": {
    alignItems:"center",
    maxWidth: $carouselType === CAROUSEL_TYPE.HERO  ? "100%"  : "fit-content",

    height:"auto",

    ":has(:hover)": {
      zIndex: 9999
    },

    "&.swiper-slide-active":{
      [`${StyledPortalWrapper}`]:{
        transform: $carouselType === CAROUSEL_TYPE.ZOOM ?  "translateX(30px)" : "translateX(35px)"
      }
    },
    "&.swiper-slide-next + div ":{
      "&:hover":{
        [`${StyledPortalWrapper}`]:{
          transform: $carouselType === CAROUSEL_TYPE.ZOOM ?  "translateX(-43px)" : "translateX(30px)"
        }
      }
     
    },

    "&.swiper-slide-next + div + div":{
      "&:hover":{
        [`${StyledPortalWrapper}`]:{
          transform:  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED ?  "translateX(-47px)" : "translateX(35px)"
        }
      }
    }
  },

  ".swiper-wrapper": {
    alignItems: "stretch",
    maxWidth: "100vw",
    overflowY:"visible",
    position:"relative",


    ":has(:hover)": {
      zIndex: ($carouselType === CAROUSEL_TYPE.ZOOM || $carouselType === CAROUSEL_TYPE.ZOOM_RELATED)
        ?  theme.zIndex.appBar :
        1
    }
  },

  ".swiper-button-next, .swiper-button-prev":   {
    bottom: 0,
    height: "243px",
    margin: "auto 0",
    opacity:0,
    right:" -61px",
    top: 0,
    width: "105px",

    "&:after":{

      backgroundRepeat:"no-repeat",
      content:"''",
      height:"40px",
      width:"40px"
    }
  },

  ".swiper-button-next":{
    background: theme.linearGradients.gradientDAlternative07,

    [theme.breakpoints.down("sm")]:{
      display: "none"
    },

    "&:after":{
      // eslint-disable-next-line max-len
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.9985 28C20.6785 28 20.3785 27.8608 20.1785 27.5825C19.8585 27.1252 19.9785 26.5089 20.4185 26.1907L37.9785 14.0031L20.4185 1.81553C19.9585 1.49742 19.8585 0.881084 20.1785 0.4238C20.4985 -0.0334836 21.1185 -0.132893 21.5785 0.185217L39.5785 12.6711C39.8385 12.85 39.9985 13.1681 39.9985 13.4862V14.5002C39.9985 14.8183 39.8385 15.1364 39.5785 15.3153L21.5785 27.8012C21.3985 27.9205 21.1985 27.9801 20.9985 27.9801V28Z' fill='%23${theme.customColors.systemPrimary01.replace("#", "")}'/%3E%3Cpath d='M39 14.9179H1C0.44 14.9179 0 14.4805 0 13.9238C0 13.3671 0.44 12.9297 1 12.9297H39C39.56 12.9297 40 13.3671 40 13.9238C40 14.4805 39.56 14.9179 39 14.9179Z' fill='%23${theme.customColors.systemPrimary01.replace("#", "")}'/%3E%3C/svg%3E")`
    }
  },
  ".swiper-button-prev":{
    background: theme.linearGradients.gradientDAlternative06,
    left:"-41px" ,
    right:undefined,

    [theme.breakpoints.down("sm")]:{
      display: "none"
    },

    "&:after":{
      // eslint-disable-next-line max-len
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='30' viewBox='0 0 42 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0015 1C20.3215 1 20.6215 1.13917 20.8215 1.41752C21.1415 1.8748 21.0215 2.49114 20.5815 2.80925L3.0215 14.9969L20.5815 27.1845C21.0415 27.5026 21.1415 28.1189 20.8215 28.5762C20.5015 29.0335 19.8815 29.1329 19.4215 28.8148L1.42149 16.3289C1.16149 16.15 1.0015 15.8319 1.0015 15.5138L1.0015 14.4998C1.0015 14.1817 1.16149 13.8636 1.42149 13.6847L19.4215 1.19882C19.6015 1.07953 19.8015 1.01988 20.0015 1.01988L20.0015 1Z' fill='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}'/%3E%3Cpath d='M2 14.0821L40 14.0821C40.56 14.0821 41 14.5195 41 15.0762C41 15.6329 40.56 16.0703 40 16.0703L2 16.0703C1.44 16.0703 1 15.6329 1 15.0762C1 14.5195 1.44 14.0821 2 14.0821Z' fill='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}'/%3E%3C/svg%3E")`
    }
  },

  "&:hover":{
    ".swiper-button-next, .swiper-button-prev":{
      "&:not(.swiper-button-disabled)":{
        opacity:1
      },
      zIndex: 9998
    }
  },

  [theme.breakpoints.down("max")]:{

    ".swiper-slide":{
       
      "&.swiper-slide-next + div + div":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED  ?  "40%" : undefined,
            transform:  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED ?  "none" : undefined
          }
        }
      
      }
    }
  },

  "@media (max-width: 1394.95px)":{
    ".swiper-slide": {
      "&.swiper-slide-next + div ":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM  ?  "86px" : undefined,
            transform: 
              $carouselType === CAROUSEL_TYPE.ZOOM 
                ?  "none" : "translateX(30px)"
          }
        }
    
      },
      "&.swiper-slide-next + div + div":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
          }
        }
        
      }
    }
  },

  "@media (max-width: 1313.95px)":{
    ".swiper-slide": {
      "&.swiper-slide-next + div ":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM ? "80%" : undefined,
            transform: $carouselType === CAROUSEL_TYPE.ZOOM ?  "none" : "translateX(30px)"
          }
        }
       
      },
      "&.swiper-slide-next + div + div":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED  ?  "calc(90% + 10%)" : undefined
       
          }
        }
      
      }
    }
  },

  "@media (max-width: 1269px)":{
    ".swiper-slide": {

      "&.swiper-slide-next + div + div":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED  ?  "calc(90%)" : undefined
       
          }
        }
      
      }
    }
  },

  [theme.breakpoints.down("lg")]:{
    ".swiper-slide": {
      "&.swiper-slide-next + div ":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM ? "80%" : "30%"
          }
        }
       
      },
      "&.swiper-slide-next + div + div":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM_RELATED  ? "100%": undefined
          }
        }
       
      }
    }
  },

  "@media (max-width: 1074px)":{
    ".swiper-slide": {
      "&.swiper-slide-next ":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right: $carouselType === CAROUSEL_TYPE.ZOOM ? "88px" : undefined
          }
        }
       
      },
      "&.swiper-slide-next + div ":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right:  $carouselType === CAROUSEL_TYPE.ZOOM ? "0px" : 
              $carouselType === CAROUSEL_TYPE.ZOOM_RELATED  ?  "56%" : undefined,
            transform:$carouselType === CAROUSEL_TYPE.ZOOM ?  "none" : 
              "translateX(30px)"
          }
        }
      
      }
    }
  },

  [theme.breakpoints.down("md")]:{
    ".swiper-slide": {
      "&.swiper-slide-next ":{
        "&:hover":{
          [`${StyledPortalWrapper}`]:{
            right: $carouselType === CAROUSEL_TYPE.ZOOM ? "88px" : "20px"
          }
        }
       
      }
    }
  },

  [theme.breakpoints.down("sm")]:{
    margin: 0,
    paddingTop:"0px",

    transform: ($carouselType === CAROUSEL_TYPE.ZOOM ) ? "translateX(-20px)" :
      ($carouselType === CAROUSEL_TYPE.ZOOM_RELATED ) ? "none"
        : undefined,
  
    ".swiper-slide": {
      maxWidth: ($carouselType === CAROUSEL_TYPE.HERO) || $label === "keep-learning" ? "100%" : "fit-content"
    },

    "&.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic":{
      transform:$carouselType === CAROUSEL_TYPE.ZOOM_RELATED ? "translateX(50%)" : undefined

    },
    ".swiper-pagination": {
      marginTop: "16px",
      position: "static"
     
    }
  }

}));
