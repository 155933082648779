import TabPanel from "@mui/lab/TabPanel";
import {
  Box, Typography 
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";


export const StyledTabsFilters = styled(Box)(( ) => ({
  margin:"0",
  maxWidth:"100%",
  width:"100%",

  ">span":{
    fontSize:"1rem"
  }
}));
export const StyledTab = styled(Tab)(()=> ({
  fontSize:"1rem",
  fontWeight:"500"
}));

export const StyledTabBox = styled(Box)(({ theme }) => ({
  alignItems:"center",
  color:theme.customColors.textPrimary,
  display:"flex",
  justifyContent:"space-between",
  paddingTop:theme.spacing(5),

  [theme.breakpoints.down("sm")]:{
    alignItems: "flex-start"
  },

  ".filters": {
    "&.selected": {
      ".icon": {
        "path":{
          fill:theme.palette.primary?.main
        }
      }
    },

    ".icon": {
      cursor:"pointer",
      marginBottom:"8px",

      height:"17px",
      width:"17px"
    }

  },

  ".MuiFormControl-root  ": {
    visibility:"hidden",

    height: "0",
    width:"0"
  },

  "button": {
    fontWeight:"400",
    margin:"0 0 0 7px",
    minHeight:"unset",
    minWidth:"unset",
    opacity:1,
    padding:"8px 0",

    [theme.breakpoints.down("sm")]:{
      margin:"0 0 0 8px"
    },

    ".icon": {
      height:"17px",
      width:"17px"
    },

    "&.Mui-selected ": {
      ".icon": {
        "path": {
          fill:theme.palette.primary?.main
        },

        "&.square": {
          background: "rgba(244, 62, 99, 0.55)"
        }
      }
    }
  },

  ".MuiTabs-indicator":{
    display:"none"
  },

  ".MuiTabs-root": {
    alignItems:"flex-end"
  }

}));

export const StyledTabContent = styled(TabPanel)(() => ({
  alignItems: "flex-start",
  flexDirection: "column",
  padding: "0"
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  letterSpacing:1,
  lineHeight: 1.3125,
  width:"100%",

  [theme.breakpoints.down("sm")]:{
    fontSize: "1.5rem"
  }
}));
