import {
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  CardsBox,
  NoResultsBox
} from "./Search.styles";
import {
  Button,
  CardFilter,
  Carousel,
  CarouselCard,
  FiltersCarousel,
  GridMain,
  GuardedLink,
  HeroCardSearch,
  Icon,
  Spinner
} from "../../components";
// import { Default as CarouselCardDefaultProps } from "../../components/CarouselCard/CarouselCard.variants";
import { DefaultSearch as HeroCardVariants } from "../../components/HeroCardSearch/HeroCardSearch.variants";
import { 
  STATUS_LO,
  CAROUSEL_TYPE
} from "../../consts";
import {
  useInfiniteSearchQuery,
  useTopRatedQuery,
  useTopicsQuery
} from "../../queries";
// import { filtersCards } from "../../services/mockDetail";
import { useAuthStore } from "../../stores";
import { CustomRegex } from "../../utils/regex";
import { CarouselBox } from "../Explore/Explore.styles";

export function Search() {
  const { t } = useTranslation();
  const {
    pathname,
    state: routerState
  } = useLocation();
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const [selectedTopics, setSelectedTopics] = useState<number[]>([]);

  const searchTerm = CustomRegex.matchPathLeaf(decodeURI(pathname));

  const { data: allTopics } = useTopicsQuery({});

  const topicsQuery = selectedTopics.reduce(
    (currentTopicsQuery, currentTopic, currentTopicIndex) => {
      /*  the uri arrives with ..topics= */
      if (currentTopicIndex == 0) {
        return currentTopicsQuery + currentTopic;
      } else {
        return currentTopicsQuery + "&topic=" + currentTopic;
      }
    },
    ""
  );
  
  const {
    data: results,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading
  } = useInfiniteSearchQuery({
    freeText: searchTerm ?? "",
    topics: selectedTopics
  });

  const topRatedIsEnabled = !isFetching && (
    !results?.pages?.[0]?.learningObjects?.length
    || results?.pages?.[0]?.learningObjects?.length === 0
  );

  const { data: topRated } = useTopRatedQuery({
    enabled: topRatedIsEnabled,
    // additional params
    topics: topicsQuery
  });

  const resultsTopics = results?.pages[0]?.metadata?.topic_ids;


  const filteredTopics =  resultsTopics ? allTopics?.filter(topic => topic.learning_object_associated > 0 && 
    resultsTopics?.some(result => result === topic.id )) :  
    allTopics?.filter(topic => topic.learning_object_associated > 0);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <GridMain>
      <Stack
        gap={ 2 }
        maxWidth="calc(100vw - (100vw - 100%))"
        minWidth={ "100%" }
      >
        { !isMobile && <GuardedLink
          alignSelf="flex-start"
          color="info"
          href={ routerState?.previous ?? "/" }
          startIcon={
            <Icon
              color = { theme.customColors.systemPrimary01 }
              icon="arrow_left"
              size={ 20 }
            />
          }
          variant="text"
          sx={ {
            fontSize: ("1.125rem"),
            fontWeight: 400,
            justifyContent:"flex-start",
            lineHeight: (11 / 9),
            paddingTop:theme.spacing(4),
            paddingX:0
          
          } }
        >
          { t("back") }
        </GuardedLink>

        }
        
        {
          (results) ? (<>
            <Typography
              color={ theme.customColors.textDisabled }
              fontSize={ isMobile? "1.5rem" : "2rem" }
              lineHeight={ 19 / 16 }
              sx={ { padding: "32px 0 0px" } }
            >
              { getResultsLabel(results?.pages?.[0]?.learningObjects ?? []) } <Typography
                color={ theme.palette.text?.primary }
                component="span"
                fontSize="inherit"
                fontWeight={ 700 }
              >
                “{ searchTerm }”
              </Typography>
            </Typography>
            <Typography sx={ { 
              padding: "32px 0 0px", 
              [theme.breakpoints.down("sm")]: {
                fontSize: "0.875rem",
                lineHeight: "1.125rem",
                padding: "0"
              } 
            } }>
              {
                results?.pages[0].metadata.totalElements
                // results?.pages?.reduce((acc, page) => (
                //   acc + page?.learningObjects?.length ?? 0
                // ), 0) ?? "0"
              } risultati trovati
            </Typography>
          </>
           
          ) : null
        }

        {
          isLoading ? (
            <Spinner />
          ) :
            (
              results?.pages?.[0]?.learningObjects &&
            results.pages[0].learningObjects.length > 0
            )
              ? (
                <>
                  {
                    filteredTopics && filteredTopics?.length > 0 ? 
                      <FiltersCarousel label={ "filters-carousel" }>
                        {
                          filteredTopics.map((topic) => (
                            <CardFilter
                              key={ `search-filter--filter-${topic.id}` }
                              title={ topic.name }
                              coverPublicURL={ topic.url.public_url }
                              onClickhandler={ () => {
                                setSelectedTopics((prev) => {
                                  const newState = [ ...prev ];
                                  const topicIndex = newState.indexOf(topic.id);
                                  if (topicIndex >= 0) {
                                    newState.splice(topicIndex, 1);
                                  } else {
                                    newState.push(topic.id);
                                  }
                                  return newState.sort();
                                });
                              } }
                              selected={ selectedTopics.includes(topic.id) }
                            />
                          ) 
                          )
                        }
                      </FiltersCarousel> : null

                  }
                 

                  <CardsBox>
                    {
                      results?.pages?.map((page) => (
                        page?.learningObjects?.map((card, index) => (
                          <CarouselCard
                            key={ `carousel-explore-zoom-2--card-${index}` }
                            carouselId={ `carousel-explore-zoom-2--card-${index}` }
                            carouselType={ CAROUSEL_TYPE.ZOOM }
                            enrollType={ card.enrollType }
                            booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                            categories={ card.topicTags || [] }
                            coverPublicURL={ card?.coverPublicURL }
                            disabled={ false }
                            duration={ card.duration }
                            expirationDate={ card.expirationDate }
                            ecmRegistration={ card.ecmRegistration }
                            ecm_specialization={ card.ecm_specialization }
                            id={ card?.id }
                            isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                            isFavourite={ card.isFavourite }
                            isMandatory ={ card.isMandatory }
                            learningObjectType={ card?.learningObjectType || "" }
                            learningObjectTypology={ card.learningObjectTypology }
                            percentageOfCompletion={ card.percentageOfCompletion }
                            shortDescription={ card.shortDescription }
                            status={ card.status }
                            title={ card.title }
                          />
                        ))
                      ))
                    }
                  </CardsBox>
                  {
                    (hasNextPage && !isFetchingNextPage) ? (
                      <Button
                        disabled={ !hasNextPage }
                        disableRipple
                        onClick={ () => fetchNextPage() }
                        sx={ {
                          gap:"0.5rem",
                          margin:"0 auto",
                          width:"fit-content"
                        } }
                      >
                        { t("load_more") }
                        <Icon 
                          color={ theme.customColors.systemPrimary02 }
                          icon="dropdown" 
                          size={ 20 } 
                        />
                      </Button>
                    ) : null
                  }
                  {
                    isFetchingNextPage ? (
                      <Spinner />
                    ) : null
                  }
                </>
              ) : (
                <>
             
                  <NoResultsBox >
                    <HeroCardSearch
                      { ...HeroCardVariants }
                    />
                  </NoResultsBox>
                  {
                    filteredTopics && filteredTopics?.length > 0 ? 

                      <FiltersCarousel label={ "filters-carousel" }>
                        {
                          filteredTopics.map((topic) => (
                            <CardFilter
                              key={ `search-filter--filter-${topic.id}` }
                              title={ topic.name }
                              coverPublicURL={ topic.url.public_url }
                              onClickhandler={ () => {
                                setSelectedTopics((prev) => {
                                  const newState = [ ...prev ];
                                  const topicIndex = newState.indexOf(topic.id);
                                  if (topicIndex >= 0) {
                                    newState.splice(topicIndex, 1);
                                  } else {
                                    newState.push(topic.id);
                                  }
                                  return newState.sort();
                                });
                              } }
                              selected={ selectedTopics.includes(topic.id) }
                            />
                          ) )
                        }
                      </FiltersCarousel> : 
                      null

                  }
                </>
              )
        }
      </Stack>
      {
        (topRatedIsEnabled) ? (
          <Stack
            width={ "100%" }
            //gap={ isMobile ? 3 : undefined }
          >
            {
              topRated ? (
                <CarouselBox>
                  <Carousel
                    carouselType={ CAROUSEL_TYPE.ZOOM }
                    id={ "explore-zoom-8" }
                    label={ "I più votati" }
                    title={ "I più votati" }
                    titleInset={ true }
                  >
                    {
                      topRated?.map((card, index) => (
                        <CarouselCard
                          key={ `carousel-explore-zoom-8--card-${index}` }
                          carouselId={ `carousel-explore-zoom-8--card-${index}` }
                          carouselType={ CAROUSEL_TYPE.ZOOM }
                          enrollType={ card.enrollType }
                          booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                          categories={ card.topicTags || [] }
                          coverPublicURL={ card?.coverPublicURL }
                          disabled={ false }
                          duration={ card.duration }
                          ecmRegistration={ card.ecmRegistration }
                          ecm_specialization={ card.ecm_specialization }
                          expirationDate={ card.expirationDate }
                          id={ card.id }
                          isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                          isFavourite={ card.isFavourite }
                          isMandatory={ card.isMandatory }
                          learningObjectType={ card?.learningObjectType || "" }
                          learningObjectTypology={ card.learningObjectTypology }
                          percentageOfCompletion={ card.percentageOfCompletion }
                          shortDescription={ card.shortDescription }
                          status={ card.status }
                          title={ card.title }
                        />
                      ))
                    }
                  </Carousel>
                </CarouselBox>
              ) : null
            }
          </Stack>
        ) : null
      }
    </GridMain>
  );

  function getResultsLabel(results: unknown[]) {
    return (results.length < 1)
      ? "Nessun risultato per "
      : "Risultati per ";
  }
}
