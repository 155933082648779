import { Grid, IconButton, Menu,Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  intervalToDuration
} from "date-fns";
import { useState } from "react";
import ProgressBar from "react-customizable-progressbar";
import { useTranslation } from "react-i18next";
import type { 
  LearnerGoalsLabelStatus,
  TrendGoals
} from "types";

import { 
  CardGoalSmall,
  CardGoalSmallContainer,
  CardGoalsBox, 
  CardGoalsItemsBox,
  StyledLabelStatus,
  StyledLevelBox,
  StyledMenuItem,
  StyledTimeBox
} from "./CardUserGoals.styles";

import { UserGoalsProps } from "./CardUserGoals.types";
import { Icon } from "../../components";
import { 
  StyledPercentageItem,
  StyledTitleSummary,
  StyledTooltip 
} from "../../components/CardUserProgress/CardUserProgress.styles";
import { GoalsModal } from "../../components/GoalsModal";
import { GoalsModalSuccess } from "../../components/GoalsModalSuccess";
import { TREND_GOALS } from "../../consts";
import { useGetLearnerGoalsConfigsQuery, useGetLearnerGoalsQuery, usePutLearnerGoalsQuery } from "../../queries";

export function CardUserGoals({
  isProfilePage,
  isOnBoarding = false
}: UserGoalsProps) {
  const theme = useTheme();


  const { 
    data: userGoals, 
    isSuccess: userGoalsSuccess 
  } = useGetLearnerGoalsQuery();


  const { 
    data: userGoalsConfig, 
    isSuccess: userGoalConfigIsSuccess,
    isLoading:userGoalConfigIsLoading
  } = useGetLearnerGoalsConfigsQuery();

  const { mutate: mutateLearnerGoals } = usePutLearnerGoalsQuery(userGoals?.goal?.plan as string);


  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const anchorEl = document.body;
  const [anchorElSelect, setAnchorElSelect] = useState<null | HTMLElement>(null);

  const [tooltipVisible, setTooltipVisible]= useState(false);
  const [isGoalUpdate, setOpenModalSuccess]= useState(false);

  const completedHours = userGoals?.progress.completedTime.hours as number;
  const completedMinutes = userGoals?.progress.completedTime.minutes as number;
  const totalCompletedHours = completedHours + (completedMinutes / 60);

  const interval = intervalToDuration({
    end: !isNaN(Number(totalCompletedHours) || 0) 
    && totalCompletedHours ?  Number(totalCompletedHours * 60) * 60 * 1000 : 0,
    start: 0 }) as Duration ;

  const goalsLabelType = setGoalsLabel(userGoals?.progress.summary  as keyof typeof TREND_GOALS);

  const detectSuccess = (updateGoalSuccess: boolean) => {
    setOpenModalSuccess(updateGoalSuccess);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };
  const onBoardingEnabled =  userGoalConfigIsSuccess && !userGoalConfigIsLoading && userGoalsConfig.enabled && 
  userGoalsConfig.yourGoals.enabled; 
  
  return   (
    (    
      onBoardingEnabled && userGoals && userGoalsSuccess && userGoals?.goal?.plan
    ) ? 
      <>
        <CardGoalsBox 
          className="widget-item"
          $onBoardingPage={ isOnBoarding }
        >
          <Menu
            sx={ {
              zIndex: "1500 !important"
            } }
            id="user-goals"
            anchorEl={ anchorElSelect }
            anchorOrigin={ {
              horizontal: 30,
              vertical: "bottom"
            } }
            transformOrigin={ {
              horizontal: "right",
              vertical: 0
            } }
            open={ Boolean(anchorElSelect) }
            onClose={ () => {
              setAnchorElSelect(null);
            } }
          >
            <StyledMenuItem 
              onClick={ () => {
                setIsOpenModal(true);
                setAnchorElSelect(null);

              } }
            >
              { t("modify") }
            </StyledMenuItem>
            <StyledMenuItem
              onClick={ () => { 
                mutateLearnerGoals();
                setAnchorElSelect(null);
              } }
            >
              { t("keep_learning") }
            </StyledMenuItem>
          </Menu>
          <Grid
            display={ "flex" }
            justifyContent={ "space-between" }
          >
            <Typography
              fontWeight={ 700 }
              fontSize={ isProfilePage || isOnBoarding ? theme.spacing(3) : undefined }
              sx={ {
                marginRight: "10px",

                [theme.breakpoints.down("sm")]:{
                  fontSize: theme.spacing(1.75)
                }
  
              } }
            >
              { t("your_goal") }
            </Typography>
            <IconButton
              onClick={ (clickEvent) => {
                setAnchorElSelect(clickEvent.currentTarget);
              } }
            >
              <Icon 
                size={ 20 } 
                icon="Icons_impostazioni"
                color={ `${theme.customColors.systemPrimary01}` }
              />
            </IconButton>
          </Grid>
          <Typography 
            sx={ {
              display:isProfilePage || isOnBoarding ? "flex" : "none",
              [theme.breakpoints.down("sm")]:{
                fontSize: theme.spacing(1.75)
              }
    
            } }>
            { 
              t("your_goals_text",{ countHour: t("countHour",{ count:userGoals?.goal?.hoursPerMonth }), 
                countMonth: t("countMonth",{ count:userGoalsConfig?.yourGoals.durationInMonths } ) })
            }
          </Typography>
          <>
            <StyledLevelBox>
              <span className="accent">{ userGoals?.goal?.plan.toLowerCase() }</span> 
    
              {  " - " + t("hour", { count: userGoals?.goal?.hoursPerMonth  }) + " / " + t("month") }
            </StyledLevelBox>
            <CardGoalsItemsBox>
              <StyledPercentageItem 
                $right="0px"
                $left="0px"
                margin={ "auto" }
                sx={ {
                  
                } }
                onMouseEnter={ ()=> {
                  setTooltipVisible(true);
                } }
                onMouseLeave={ ()=> {
                  setTooltipVisible(false);
                } }
              >
                <ProgressBar
                  className="centered"
                  radius={ 70 }
                  progress={ (totalCompletedHours / userGoals?.goal.totalHours)  * 100 }
                  strokeColor={ userGoals?.progress.summary === TREND_GOALS.GOAL_NOT_ACHIEVED ? 
                    theme.customColors.textPrimary : theme.customColors.accentVariantB }
                  strokeWidth={ 8 }
                  trackStrokeWidth={ 8 }
                  trackStrokeColor={ theme.customColors.border }
                  initialAnimationDelay={ 3000 }
                  initialAnimation={ true }
                  trackTransition=".1s ease"
                  transition="1s ease"
                />
               
                <StyledTooltip 
                  $bottom="0px"
                  $left="0px"
                  $right="0px"
                  $top="0px"
                  $visible={ tooltipVisible }
                >
                  <div className="tooltip-wrap">
                    { interval.days ? t("day", { count: interval.days }) : null }
                    { interval.hours ? " " + t("hour", { count: interval.hours }) : null }
                    {  interval.minutes ? " " + interval.minutes + " " + t("min") : null } 
                    { !interval.hours && !interval.days && !interval.minutes ? "0 " + t("min") : null }
                  </div>
            
                </StyledTooltip> 
              
              </StyledPercentageItem>
              <StyledTitleSummary height={ "fit-content !important" }>
                {
                  Math.round( (totalCompletedHours / userGoals?.goal.totalHours)  * 100 ) + "%" 
                }
                <span>{ t("completed") }</span>
              </StyledTitleSummary> 
            </CardGoalsItemsBox> 
            {
              goalsLabelType.label && !isOnBoarding ?   
                <StyledLabelStatus 
                  color={ goalsLabelType.labelColor }
                  justifyContent={ isProfilePage || isOnBoarding ? "center" : "flex-start" }
                >
                  <Icon 
                    color={ goalsLabelType.iconColor  }
                    icon={ goalsLabelType.icon }
                    size={ 20 }
                  />
                  { goalsLabelType.label }
                </StyledLabelStatus> : 
                null
            }
          
            { !isOnBoarding && 
              <StyledTimeBox>
                <span>{ t("time_goal") }</span>
                <Grid
                  alignItems={ "center" }
                  display={ "flex" }
                  gap={ 0.5 }
                >
                  <span className="bigger">{ userGoals?.progress.passedDays }</span> 
                  { " / " } 
                  { userGoals?.goal.durationInDays + " " + t("days") }
                </Grid>
              </StyledTimeBox> 
            }
          </>
        </CardGoalsBox>
        { !!isOnBoarding && 
          <CardGoalSmallContainer>
            <CardGoalSmall>
              <Typography
                fontWeight={ 700 }
                fontSize={ isProfilePage || isOnBoarding ? theme.spacing(3) : undefined }
                sx={ {
                  marginRight: "10px",
                  [theme.breakpoints.down("sm")]:{
                    fontSize: theme.spacing(1.75)
                  }
                } }
              >
                { t("study_time_goal") }
              </Typography>
              <div className="goals-time">
                <span className="number">
                  { userGoals?.goal.totalHours }
                </span>
                <span className="label">
                  {  t("hour", { count:userGoals?.goal.totalHours }) }
                </span>
              </div>
            </CardGoalSmall> 
            <CardGoalSmall>
              <Typography
                fontWeight={ 700 }
                fontSize={ isProfilePage || isOnBoarding ? theme.spacing(3) : undefined }
                sx={ {
                  marginRight: "10px",
                  [theme.breakpoints.down("sm")]:{
                    fontSize: theme.spacing(1.75)
                  }
                } }
              >
                { t("time_goal") }
              </Typography>
              <div className="goals-time">
                <span className="number">
                  { userGoals?.goal.durationInDays }
                </span>
                <span className="label">
                  { " " + t("days") }
                </span>
              </div>
            </CardGoalSmall> 
          </CardGoalSmallContainer> 
        }
        
        {
          isOpenModal ? (
            <GoalsModal
              detectSuccess={ detectSuccess }
              anchorEl={ anchorEl }
              anchorOrigin={ {
                horizontal: "center",
                vertical: "center"
              } }
              transformOrigin={ {
                horizontal: "center",
                vertical: "top"
              } }
              closeDrawer={ handleClose }
              onClose={  handleClose }
              open={ isOpenModal }
            />
          ) : null
        }
        <GoalsModalSuccess 
          open={ isGoalUpdate }
          anchorEl={ anchorEl }
          anchorOrigin={ {
            horizontal: "center",
            vertical: "center"
          } }
          transformOrigin={ {
            horizontal: "center",
            vertical: "top"
          } }
          onClose={ () => setOpenModalSuccess(false) }
          closeDrawer={ () => setOpenModalSuccess(false) }/>
      </> 
      : 
      <></>
  
  ) ;

  function setGoalsLabel(label:TrendGoals):LearnerGoalsLabelStatus{
    switch(label){
    case TREND_GOALS.ALMOST_THERE: 
      return {
        icon:"almost_there",
        iconColor:theme.customColors.accentVariantC,
        label:t("almost_there_text"),
        labelColor:theme.customColors.textPrimary
      } ;
    case TREND_GOALS.GOAL_ACHIEVED:
      return {
        icon:"completato",
        iconColor:theme.customColors.accentVariantB,
        label:t("achieved_text"),
        labelColor:theme.customColors.textPrimary
      };
    case TREND_GOALS.GOAL_NOT_ACHIEVED:
      return {
        icon:"Icons_obbligatorio",
        iconColor:theme.customColors.accentVariantC,
        label:t("not_achieved_text"),
        labelColor:theme.customColors.textWarning      
      }; 
    case TREND_GOALS.ON_LINE:
      return {
        icon:"completato",
        iconColor:theme.customColors.accentVariantB,
        label:t("on_line_text"),
        labelColor:theme.customColors.textPrimary
      };
    case TREND_GOALS.UNDER_LINE:
      return {
        icon:"under_line",
        iconColor:theme.customColors.accentVariantC,
        label:t("under_line_text"),
        labelColor:theme.customColors.textPrimary
      };
    case TREND_GOALS.UNKNOWN:
      return {
        icon:"",
        iconColor:"",
        label:"",
        labelColor:theme.customColors.textPrimary
      };
    }
    
    
  
  }
}
