import { 
  Avatar, 
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  format
} from "date-fns";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { 
  OwnerNameTypography, 
  TitleTypography, 
  TopicCardDetailsBox, 
  TopicTypography,
  SubtitleTypography,
  BodyTypography,
  LinkTypography,
  LinkInfoTypography
} from "./TopicCardDetails.style";
import type { TopicCardDetailsProps } from "./TopicCardDetails.types";
// import cover from "../../assets/communityTopic.png";
// import  mockImage  from "../../assets/agendaBg.png";

import { 
  CardCategories, 
  CommentsSection, 
  CommunityVideo, 
  CtaCommunityOverlay, 
  GridMain, 
  Icon,
  Link,
  Spinner,
  TopicCardSignature
} from "../../components";
import { useTopicQuery } from "../../queries/community";
import {
  BackButton
} from "../../routes/CommunityDetails/CommunityDetails.styles";
import { stringAvatar } from "../../utils/community";

export function TopicCardDetails({
  id,
  viewComment=false
}: TopicCardDetailsProps & {
  viewComment?: boolean
}) {

  const { t } = useTranslation();

  const theme = useTheme();

  const commentsSection = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: topicData, isLoading } = useTopicQuery({ discussionId: id });
  const navigate = useNavigate();
  const scrollToComments = () => {
    const offsetTop = commentsSection?.current?.offsetTop ?? 1000;
    const scrollY = offsetTop - 88 - 40; //to make up for the header's height + gap
    document.scrollingElement?.scrollTo({ behavior: "smooth", top: scrollY });
  };

  useEffect(() => {
    setTimeout(() => {
      if(viewComment) {
        scrollToComments();
      }
    }, 500);
  },[viewComment]);


  const ownerUserId = topicData?.data[0].owner_id;
  const ownerUser = topicData?.enrichedInformation?.users?.find(user => user.owner_id === ownerUserId);
  
  
  return (
    <>
    
      { isLoading ? (
        <Spinner
          size={ isMobile? 100 : 200 }
          thickness={ 3 }
        />
      ):
        !topicData || (topicData && topicData?.data.length == 0) ? (
          <Typography 
            padding={ "42px 80px" }
            textAlign={ "center" }
          >
            { t("no_data_available") }
          </Typography>
        ):
          <>
            <TopicCardDetailsBox 
              // cover={ cover }
              cover={ topicData.data[0].attachments?.image?.[0].content }
            >
          
              <GridMain
                paddingBottom={ 0 }
              >
                <BackButton
                  onClick={ () =>  {navigate("/community")} }
                  disableRipple
                >
                  <Icon className={ "icon" } icon={ "arrow_left" }/>
                  { t("back_to_community") }
                </BackButton>
                <Stack 
                  gap={ "16px" } 
                  position={ "relative" }
                  width={ "100%" }
                  marginBottom={ isMobile ? "40px" : "88px" }
                >
                  <Stack 
                    direction={ "row" } 
                    gap={ isMobile ? "8px" : "16px" } 
                    width={ "100%" }
                  >
                    <Avatar
                      { ...stringAvatar( `${ownerUser?.name} ${ownerUser?.surname}`) }
                      src={ ownerUser?.profileImageUrl }
                      sx={ {
                        height: isMobile ? "32px" : "40px",
                        width: isMobile ? "32px" : "40px"
                      } }
                    />
                
                    <Stack 
                      direction={ "row" }
                      alignItems={ "center" } 
                      justifyContent={ "space-between" }
                      width={ "100%" }
                    >
                      <OwnerNameTypography>
                        { `${ownerUser?.name} ${ownerUser?.surname}` }
                      </OwnerNameTypography>
                      <TopicTypography>
                        <Stack 
                          direction={ "row" } 
                          gap={ "8px" }
                        >
                          <Icon
                            icon={ "Icons_Advertising" }
                            width={ isMobile ? "13px" : "18px" }
                            height={ "18px" }
                            color={ theme.customColors.systemPrimary01Alternative01 }
                          />
                          { t("talk") }
                        </Stack>
                      </TopicTypography>
                    </Stack>
                  </Stack>

                  <Stack 
                    gap={ "8px" }
                    width={ isMobile ? "100%" : "75%" }
                  >
                    <TitleTypography>
                      { topicData?.data[0].title }
                    </TitleTypography>
                    <CardCategories 
                      sx={ {
                        width: "100%"
                      } }
                      categories={ topicData?.data[0].topic.map((cat) => cat.name) ?? [] }
                    />
                  </Stack>
                  {
                    topicData?.data[0]?.subtitle ? 
                      <SubtitleTypography>
                        { topicData?.data[0].subtitle }
                      </SubtitleTypography> : 
                      null
                  }
                 
                  <TopicCardSignature
                    width={ isMobile ? "100%" : "75%" }
                    created={ getcreatedLabel(topicData?.data[0].publishDate) }
                    readingTime={  secondsToMinuteslabel(
                      getReadingTime(topicData?.data[0].body) 
                    + Number(
                      topicData?.data[0].attachments.brightcove ? 
                        topicData?.data[0].attachments.brightcove?.[0].duration : "0"
                    )
                    + Number(
                      topicData?.data[0].attachments.document ? 
                        topicData?.data[0].attachments.document?.[0].duration : "0"
                    )
                    ) }
                    size={ "medium" }
                  />
                  <CtaCommunityOverlay
                    id={ id }
                    comments={ topicData?.data[0].comment_count }
                    likes={ topicData?.data[0].likes }
                    commentsOpened={ false }
                    commentsClickAction={ scrollToComments }
                    selectedFilter={ null }
                    selectedTopic={ null }
                  />
                </Stack>

                <Stack 
                  gap={ isMobile ? "0" : "40px" }
                  width={ isMobile ? "100%" : "75%" }
                  position={ "relative" }
                >
                  { topicData.data[0].attachments?.brightcove &&
                    <CommunityVideo
                      // height={ "calc(567px / 16 * 9)" }
                      height={ "calc(70vw / 16 * 9)" }
                      id={ Number(topicData.data[0].attachments?.brightcove?.[0].id) || 1 }
                      title={ topicData.data[0].attachments?.brightcove?.[0].name || "alt title" }
                      videoId={ topicData.data[0].attachments?.brightcove?.[0].content }
                    />
                  }
                  <BodyTypography dangerouslySetInnerHTML={ { __html: topicData?.data[0].body ?? "" } } />
                  {
                    topicData.data[0]?.attachments?.link 
                    ||  topicData.data[0]?.attachments?.document ? 
                      <Stack 
                        gap={ "24px" }
                        width={ "100%" }
                      >
                        { topicData.data[0]?.attachments?.link &&
                          <Stack key={ "Link" } gap={ "8px" }>
                            <Link
                              href={ topicData.data[0].attachments.link[0].content }
                              download={ true }
                              target={ "_blank" }
                              rel="noreferrer"
                            >
                              <Stack direction={ "row" } gap={ "8px" }>
                                <LinkTypography>{ topicData.data[0].attachments.link[0].name }</LinkTypography>
                                <Icon
                                  icon={ "Icons_link" }
                                  width={ "20px" }
                                  height={ "20px" }
                                  color={ theme.palette.primary?.main }
                                />
                              </Stack>
                            </Link>
                          </Stack>
                        }
                        { topicData.data[0]?.attachments?.document &&
                          <Stack key={ "file-" } gap={ "8px" }>
                            <Link
                              href={ topicData.data[0].attachments?.document[0].content }
                              download={ true }
                              target={ "_blank" }
                              rel="noreferrer"
                            >
                              <Stack direction={ "row" } gap={ "8px" }>
                                <LinkTypography>{ topicData?.data[0]?.attachments?.document[0].name }</LinkTypography>
                                <Icon
                                  icon={ "download" }
                                  width={ "20px" }
                                  height={ "20px" }
                                  color={ theme.palette.primary?.main }
                                />
                              </Stack>
                            </Link>
                            <Stack direction={ "row" }>
                              <LinkInfoTypography>
                                {
                                  `${getFileExtension(topicData.data[0].attachments?.document[0].content)}
                              ${bytesToMegabytes(Number(topicData.data[0].attachments?.document[0].size))}` 
                                }
                              </LinkInfoTypography>
                            </Stack>
                          </Stack>
                        }
                      </Stack> : 
                      null
                  }
                 
                </Stack>
    
              </GridMain>
              <GridMain 
              >
                <Stack
                  ref={ commentsSection }
                  width={ isMobile ? "100%" : "75%" }
                  position={ "relative" }
                >
                  <CommentsSection
                    topicOwnerId={ topicData.data?.[0]?.owner_id ?? "0" } 
                    selectedFilter={ null }
                    selectedTopic={ null }
                    discussionId={ id } 
                    className="visible" />
                </Stack>
          
              </GridMain>
            </TopicCardDetailsBox>
          </>
           
      }
    </>
  );
  
  function bytesToMegabytes(bytes: number) {
    const mb = bytes / 1000000;
    return `${mb.toFixed(2)} MB`;
  }
  function getFileExtension(file: string) {
    const fileArray = file.split("?")[0].split(".");
    return fileArray[fileArray.length - 1];
  }

  function getcreatedLabel(created: string) {
    if(created) {
      const date = new Date(created);
      const now = new Date();
      const hours = Math.trunc(((now.getTime() - date.getTime()) / 1000 / 3600));
      if (hours < 1) return t("now");
      if (hours < 24) return `${t("hour", { count: hours })} ${t("ago")}`;
      if (hours < 48) return `${t("day", { count: 1 })} ${t("ago")}`;
      if (hours < 168) return `${t("day", { count: Math.floor(hours / 24) })} ${t("ago")}`;
      if (hours < 336) return `${t("week", { count: 1 })} ${t("ago")}`;
      else return format(date, "dd/MM/yyy");
    } else {
      return "undefined";
    }
    
  }
  function getReadingTime(text: string) {
    const words = text.split(" ").length;
    return words / 2;  //2 words per second
  }

  function secondsToMinuteslabel(duration: number) {
    if(duration < 60) {
      return `${Math.round(duration)} sec`;
    } else {
      return `${Math.round(duration / 60)} min`;
    }
    
  }

}
