import { Stack } from "@mui/material";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {
  Footer,
  Header,
  LoginSpinner,
  Modal,
  Navbar,
  ScrollToTop,
  SessionRevoker,
  UnAuthorizedModal
} from "../../components";
import {
  AUTH_MODE,
  NAVBAR_ITEMS
} from "../../consts";
import {
  useCommunityTrackPathname,
  useCommunityTrackVisibility,
  useContactsWidget,
  useWindowSize
} from "../../hooks";
import { useGetTranslations } from "../../queries";
import {
  useAuthStore,
  useLanguageStore,
  useNavbarStore
} from "../../stores";
import {
  addTimeToCurrentDate,
  getEnvironmentVariables,
  getLastPath,
  throttle
} from "../../utils/general";

/// cfr. https://reactrouter.com/en/main/hooks/use-outlet-context
export type OutletContext = {
  sectionUrl: string
}

const { environment, analytics, favicon } = getEnvironmentVariables();

export function Root() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    pathname,
    state: routerState
  } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  const spinnerLoginVisible = useAuthStore(state => state.spinnerLoginVisible);
  const agreedToConditions = useAuthStore(state => Boolean(state.userInfo?.privacy_consent_flag));
  const authError = useAuthStore(state => state.error);
  const authNotice = useAuthStore(state => state.notice);
  const corporateHasOnBoarding = useAuthStore(state => Boolean(state.corporateInfo?.onboarding));
  const completedOnBoarding = useAuthStore(state => Boolean(state.userInfo?.onboarding_flag));
  const isAuthenticated = useAuthStore(state => Boolean(state.userData && state.userInfo));
  const isUnauthorized = useAuthStore(state => state.isUnAuthorized);
  // const isMoreAuthenticated = useAuthStore(state => Boolean(state.userData?.initiative_id && state.userInfo));
  const hasInitiative = useAuthStore(state => Boolean(state.userData?.initiative_id));
  const navigateTo = useAuthStore(state => state.navigateTo);
  const resetError = useAuthStore(state => state.resetError);
  const resetNavigate = useAuthStore(state => state.resetNavigate);
  const resetNotice = useAuthStore(state => state.resetNotice);
  const seedLogout = useAuthStore(state => state.seedLogout);
  const seedSession = useAuthStore(state => state.seedSession);
  const setSessionSeeding = useAuthStore(state => state.setSessionSeeding);
  const sessionToken = useAuthStore(state => state.sessionToken);
  const switchToInitiative = useAuthStore(state => state.switchToInitiative);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const navbarItem = useNavbarStore(state => state.item);
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const sectionUrl = (navbarItem === NAVBAR_ITEMS.FOR_YOU) ? "per-te" : "esplora";

  const language = useLanguageStore(state => state.language);
  useGetTranslations({ language: language.id });

  //const theme = useTheme();

  useCommunityTrackPathname();
  useCommunityTrackVisibility();
  useContactsWidget(hasInitiative && smartConfiguration?.freshDesk == true);
  // eslint-disable-next-line max-len
  const inititiveUrl =  smartConfiguration?.urlDomain ? `${smartConfiguration?.urlDomain}.${smartConfiguration.urlCorporateDomain}` : `${smartConfiguration?.urlCorporateDomain}`;

  const { width: windowWidth } = useWindowSize();

  const excessWidth = (windowWidth - 1440) + 22; // 22 is a margin value set by the widget script 

  const authMode = searchParams.get("mode") as keyof typeof AUTH_MODE;
  // query param for redirect interaction with admin
  const redirectToTarget = searchParams.get("redirect_to") ?? localStorage.getItem("redirect_to");
  // eslint-disable-next-line max-len
  const redirectTo = redirectToTarget ? `${redirectToTarget}?sessionId=${sessionToken}mode=${authMode}&initiativeUrl=${inititiveUrl}` : redirectToTarget;
  redirectTo && throttledRedirectTo(redirectTo);
  // query param for seeding session
  const seedSessionId = searchParams.get("seed_session");

  // query params for setting logout state
  const errorParams = searchParams.get("error");
  const navigateParam = searchParams.get("navigate");
  
  const mainPage = smartConfiguration?.visForyou === false ?  "/esplora" : "/per-te";
  const mainPageNavItem = smartConfiguration?.visForyou === false ?  "EXPLORE" : "FOR_YOU";
  
  if(seedSessionId){
    setSessionSeeding(true);
    document.cookie = `loadingSpinner=true; expires=${addTimeToCurrentDate(5)}; domain=.${getLastPath()}; path=/`;
  }
  // TODO: QUESTO VA NEL LOADER!!!!
  useEffect(() => {
    /* redirect to initiative subdomain if set in smart configuration (disabled for env local) */
    if (
      (smartConfiguration?.urlCorporateDomain && smartConfiguration.urlCorporateDomain)
      && (`https://${smartConfiguration.urlDomain}.${smartConfiguration.urlCorporateDomain}` !== window.location.origin)
      && (environment !== "LOCAL")
    ) {
      setSpinnerLoginVisible(true);
      switchToInitiative();
    /* let error modal be shown in login screen */
    } else if (authError && pathname === "/access/login") {
      return;
    /* handle auth store redirect (the auth store can't use react-router hooks...) */
    } else if (navigateTo && pathname === navigateTo.target) {
      resetNavigate();
    } else if (navigateTo && pathname !== navigateTo.target) {
      navigate(navigateTo.target, { state: navigateTo.state });
      setNavbarItem(mainPageNavItem);
    /* recover auth state after redirect to initiative sub-domain */
    } else if (
      seedSessionId && 
      authMode 
    ) {
      setSpinnerLoginVisible(true);
      searchParams.delete("mode");
      searchParams.delete("seed_session");
      searchParams.delete("switchToInitiative");
      setSearchParams(searchParams);
      seedSession(authMode, seedSessionId);
    } else if (errorParams && authError === null) {
      seedLogout(errorParams, null);
      navigate("/access/login");
    } else if (navigateParam) {
      searchParams.delete("navigate");
      setSearchParams(searchParams);
      navigate(navigateParam);
    /* go to login */
    } else if (
      !isAuthenticated &&
      pathname !== "/access/login" && pathname !== "/access/password/recovery" &&
      !routerState?.recover === true && !routerState?.firstAccess === true
    ) {
      navigate("/access/login");
    /* go to admin */
    } else if (isAuthenticated && Boolean(redirectTo)) {
      localStorage.removeItem("redirect_to");
      window.location.href = `${redirectTo}`;
    /* go to conditions agreement */
    } else if (
      !agreedToConditions && isAuthenticated &&
      pathname !== "/agreements" && pathname !== "/access/login"// && pathname !== "/"
    ) {
      setSpinnerLoginVisible(false);
      navigate("/agreements", { state: { first:true, isAuthenticated } });
    } else if (
      corporateHasOnBoarding && !completedOnBoarding
      && isAuthenticated && agreedToConditions
      && pathname !== "/access/login"
      && pathname !== "/signup"
      && pathname !== "/signup/profession"
      && pathname !== "/signup/job"
      && pathname !== "/signup/skills"
      && pathname !== "/signup/capabilities"
      && pathname !== "/signup/preferences"
      && pathname !== "/signup/summary"
    ) {
      setSpinnerLoginVisible(false);
      navigate("/signup", { state: { isAuthenticated } });
    /* go to initiative selection */
    } else if (
      isAuthenticated && !hasInitiative && agreedToConditions
      && (completedOnBoarding || !corporateHasOnBoarding)
      && !routerState?.firstAccess && pathname !== "/access/portal"
    ) {
      setSpinnerLoginVisible(true);
      navigate("/access/portal",{ state:{ first:true } });
    /* go to homepage */
    }  else if 
    (
      isAuthenticated && agreedToConditions && hasInitiative &&
      !routerState?.firstAccess && pathname === "/"
    ) 
    {
      setNavbarItem(mainPageNavItem);
      navigate(mainPage);
    }
  });


  const showMainLayout = isAuthenticated && hasInitiative 
  && !pathname.includes("access") 
  && !pathname.includes("signup") && 
  !seedSessionId;

  (authError || authNotice) && console.warn("Root.page", { authError, authNotice });

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ t("app_name") }</title>
        <link rel="icon" type="image/png" href={ `${favicon}` } />
        <style type="text/css">{ `
          /*div#freshworks-container iframe#launcher-frame {
            max-width: 1440px !important;
            ${(excessWidth > 0) ? `padding: 0 ${Math.floor(excessWidth / 2)}px;` : ""}
            width: 100%;
          }*/

          div#freshworks-container iframe#launcher-frame {
            bottom: 22px !important;
          }
        ` }
        </style>
        <script type="text/javascript">{ `${analytics}` }</script>
      </Helmet>
      <div className={ "mask" }></div>
      <Stack minHeight="100vh">
        <ScrollToTop />
        {
          // eslint-disable-next-line no-constant-condition
          false ? <SessionRevoker /> : false
        }
        <Header authenticated={ showMainLayout } />
        { (showMainLayout) ? (
          <Navbar />
        ) : null } 
        <Outlet
          context={ {
            sectionUrl
          } satisfies OutletContext }
        />

        { (showMainLayout) ? (
          <Footer />
        ) : null }
      </Stack>
      <LoginSpinner open={ spinnerLoginVisible }/>
      <UnAuthorizedModal open={ isUnauthorized }/>
      <Modal
        description={ authError?.message ?? "Errore generico" }
        onClose={ () => {
          searchParams.delete("error");
          setSearchParams(searchParams);
          resetError();
        } }
        open={ Boolean(authError) }
        title="Errore"
      />
      <Modal
        description={ authNotice ?? "" }
        onClose={ resetNotice }
        open={ Boolean(!authError && authNotice) }
        title="Attenzione"
      />
    </Fragment>
  );
}

const throttledRedirectTo =
  throttle((redirectTo: string) => {
    redirectTo && localStorage.setItem("redirect_to", redirectTo);
  }, 2000);
