import {
  Stack,
  StepConnector,
  Stepper,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  alpha,
  styled,
  useTheme
} from "@mui/material/styles";
import DOMPurify from "dompurify";
import {
  useEffect,
  useState,
  type ChangeEvent
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import {
  Background,
  Checkbox,
  GuardedLink,
  Icon,
  ListBox,
  Spinner
} from "../../components";
import { StyledStep } from "../../components/OnBoarding/OnBoarding.styles";
import { useOnboardingSteps } from "../../hooks";
import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";

const AgreementsStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.background?.default && alpha(theme.palette.background?.default, 0.8)
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  alignSelf: "flex-start",

  ".MuiSvgIcon-root": {
    transform: "scale(1.4)",

    [theme.breakpoints.down("sm")]: {
      transform: "scale(1.2)"
    }
  }
}));

const StyledLi = styled("li")(({ theme }) => ({
  "p": {
    all: "unset",

    [theme.breakpoints.down("sm")]:{
      fontSize:"0.75rem",
      lineHeight:"0.875rem"
    }
  }
}));

export function Agreements() {
  const { t } = useTranslation();
  const { state: routerState } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const agreedToConditions = useAuthStore(state => state.userInfo?.privacy_consent_flag === true);
  const error = useAuthStore(state => state.error);
  const isAuthenticated = useAuthStore(state => Boolean(state.userData));
  const isLoading = useAuthStore(state => state.isLoading);
  const postAgreementsConsent = useAuthStore(state => state.postAgreementsConsent);

  interface IAgreement {
    isMandatory: boolean,
    selected: boolean,
    text: string
  }
  
  /* eslint-disable max-len */
  const initialAgreements: IAgreement[] = [
    {
      isMandatory: true,
      selected: false,
      text: `<p>${t("privacy_agreement_privacy")}<a target="_blank" href="https://manuals.openlearning.digitedacademy.net/202312_Digit'Ed_Privacy policy piattaforma online_v1.pdf">${t("info_privacy")}</a>.<p>`
    },
    {
      isMandatory: true,
      selected: false,
      text: `<p>${t("privacy_agreement_terms")} <a target="_blank" href="https://manuals.openlearning.digitedacademy.net/20240124_DIGITED_TC Piattaforma_v1.pdf">${t("terms_and_conditions")}</a>, ${t("privacy_agreement_terms_2")}.<p>`
    },
    {
      isMandatory: true,
      selected: false,
      text: `<p>${t("privacy_agreement_exclusion")} <a target="_blank" href="https://manuals.openlearning.digitedacademy.net/20240124_DIGITED_TC Piattaforma_v1.pdf">${t("terms_and_conditions")}</a>: ${t("privacy_agreement_articles")}<p>`
    }
  ];
  /* eslint-enable max-len */

  // eslint-disable-next-line max-len
  const cookieInfoText =  `<p>${t("cookie_description")}<a target="_blank" href="https://manuals.openlearning.digitedacademy.net/202312_Cookie_Policy_Piattaforma online_v1.pdf">${t("cookie_policy")}</a>.<p>`;

  const [agreements, setAgreements] = useState(
    initialAgreements.filter((agreement) => agreement.isMandatory)
  );

  const isValid = agreements.every((agreement) => (
    !agreement.isMandatory || agreement.selected
  ));

  useEffect(() => {
    if (agreedToConditions && !error) {
      navigate("/");
    }
  }, [agreedToConditions, error, navigate]);

  const steps = useOnboardingSteps();
  const stepNumber = 0;

  return (
    <Background>
      <AgreementsStack
        gap={ isMobile ? 3 : 4 }
        paddingY={ 4 }
        paddingX={ isMobile ? 2 : 8 }
        width="clamp(60%, 900px, 90%)"

      >
        {
          routerState?.first ? (
            <Stepper
              connector={
                <StepConnector
                  sx = { {
                    margin: theme.spacing(0, (isMobile ? 0.6 : 1))
                  } }
                />
              }
              sx={ { 
                margin:"0 auto",
                width: "min(100%, 38rem)" 
              } }
            >
              {
                steps.map((label, idx) => (
                  <StyledStep
                    active={ stepNumber === idx }
                    component="span"
                    key={ `step--${idx}` }
                  >
                    {
                      idx < stepNumber ? 

                        <Icon 
                          icon="Icons_modulo-completato" 
                          color={ theme.customColors.accentVariantB }
                          size={ 16 }
                        /> : null
                    }
                    { t(label) }
                  </StyledStep>
                ))
              }
            </Stepper>
          ) : null
        }
        {
          isLoading ? (
            <Spinner />
          ) : (
            <>
              <Stack
                alignItems="flex-start"
                justifyContent="center"
                gap={ 2 }
              >
                <Typography
                  color="primary"
                  fontSize={ isMobile ? "1.25rem" : "2rem" }
                  fontWeight={ 700 }
                  lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
                >
                  { t("before_starting") }
                </Typography>
                <Typography
                  alignItems="flex-start"
                  justifyContent="center"
                  fontSize={ isMobile ? "0.875rem" : "1rem" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 16) }
                >
                  { t("terms_conditions") }
                </Typography>
              </Stack>
              <ListBox component="ul">
                {
                  agreements?.map(({ selected, text }, idx) => (
                    <Stack
                      key={ `agreement--${idx}` }
                      direction="row"
                      alignItems="center"
                      gap={ 1.875 }

                      sx={ {
                        ".MuiButtonBase-root": {
                          padding: "0 !important"
                        }
                      } }
                    >
                      <StyledCheckbox
                        aria-label={ `agreement--${idx}` }
                        onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => {
                          setAgreements((prev) => {
                            const newAgreements = [...prev];
                            newAgreements[idx].selected = changeEvent.target.checked;
                            return newAgreements;
                          });
                        } }
                        value={ selected }
                      />
                      <StyledLi
                        id={ `agreement-text--${idx}` }
                        dangerouslySetInnerHTML={ {
                          __html: DOMPurify.sanitize(text, { ADD_ATTR: ["target"] })
                        } }
                      />
                    </Stack>
                  ))
                }

                <StyledLi
                  id={ "cookie-info" }
                  dangerouslySetInnerHTML={ {
                    __html: DOMPurify.sanitize(cookieInfoText,{ ADD_ATTR: ["target"] })
                  } }
                />
              </ListBox>
              {
                agreements.some((agreement) => agreement.isMandatory) ? (
                  <Typography
                    alignSelf="flex-start"
                    fontSize="0.75rem"
                    lineHeight={ 7/6 }
                  >
                    { `*${t("required_fields")}` }
                  </Typography>
                ) : null
              }
              <GuardedLink
                sx={ {

                  margin: isMobile ? "16px auto 0" : "0 auto" ,
                  width:"fit-content"
                } }
                disabled={ !isValid }
                href={ "/" }
                onClick={ handleAgreements }
                state= { { ...routerState } }
              >
                { t("continue") }
              </GuardedLink>
            </>
          )
        }
      </AgreementsStack>
    </Background>
  );

  function handleAgreements() {
    if (!isValid) {
      return false;
    } else if (isAuthenticated === true && !agreedToConditions) {
      postAgreementsConsent();
      return false;
    } else {
      return true;
    }
  }
}
