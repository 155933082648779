import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useOutletContext
} from "react-router-dom";
import {
  DetailTypography,
  HomeCardStack,
  TitleTypography,
  TypeTypography
} from "./HomeCard.styles";
import { HomeCardProps } from "./HomeCard.types";
import {
  ButtonText,
  CardCategories,
  CardSignature,
  CtaOverlay,
  Icon,
  LinkWithInteractableOverlay,
  StatusBox
} from "../../components";
import {
  ENROLL_TYPE,
  LEVEL
} from "../../consts";
import {
  useAccent,
  useButtonStatusMap,
  useIconStatus,
  useResumeLink,
  useType
} from "../../hooks";
import { useEnrollmentMutation } from "../../queries";
import { OutletContext } from "../../routes";
import { formatDate } from "../../services";
import { hasPassedExpiration } from "../../utils/datetime";

export function HomeCard({
  aiItemType,
  badge,
  certificate,
  courseId,
  coverPublicURL,
  duration,
  ecm_specialization,
  ecmRegistration,
  ecmType,
  enrollType,
  editionNumber,
  endDate,
  expirationDate,
  id,
  isFavourite,
  isMandatory,
  isStandAlone,
  isSuggestedAI,
  isSuggestedHR,
  isSuggestedManager,
  learningObjectType,
  learningObjectTypology,
  percentageOfCompletion = "0",
  rating,
  rootId,
  shortDescription,
  startDate,
  status,
  title,
  ...props
}: HomeCardProps) {
  const { sectionUrl } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const theme= useTheme();
  const { t, i18n } = useTranslation();
  const typeObjectLearning  = useType(learningObjectType, learningObjectTypology);
  const iconStatus = useIconStatus({ isSuggestedAI, isSuggestedHR, isSuggestedManager, status });
  const buttonText = useButtonStatusMap({
    ecm_specialization:ecm_specialization,
    ecmRegistration:ecmRegistration,
    enrollType:enrollType,
    isCard: true,
    isSurvey:false,
    isTest:false,
    learningObjectType:learningObjectType,
    learningObjectTypology:learningObjectTypology,
    madeAttempts:0,
    percentageOfCompletion:percentageOfCompletion
  });

  const {
    mutate: addEnrollmentMutation,
    isSuccess: enrollmentMutationHadSuccess
  } = useEnrollmentMutation({
    enrollType:enrollType,
    learningObjectId: id ?? "",
    learningObjectTypology
  });

  const isExpired = hasPassedExpiration(expirationDate);
  const accent = useAccent({ isExpired, isMandatory, isSuggestedAI, isSuggestedHR, isSuggestedManager, status });

  const messageStatusBox = isSuggestedAI || isSuggestedHR || isSuggestedManager ? aiItemType : 
    (isExpired && expirationDate && status !== "C" && isMandatory )
      ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
      : undefined;

  const resumeLink = useResumeLink({
    ecm_specialization,
    ecmRegistration,
    enrollType,
    id,
    learningObjectType,
    learningObjectTypology,
    sectionUrl,
    status
  });
      
  const resumeLinkLinkedin = learningObjectTypology === LEVEL.DA_LINKEDIN ?
    `/esplora/scorm/${id}/${learningObjectTypology}?courseId=null&rootId=null` :
    undefined;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (resumeLinkLinkedin){
      enrollmentMutationHadSuccess &&  window.open(resumeLinkLinkedin, "_blank", "noreferrer");
    } else {
      enrollmentMutationHadSuccess && navigate(resumeLink);
    }
  }, [
    enrollmentMutationHadSuccess,
    navigate,
    resumeLink,
    resumeLinkLinkedin
  ]);

  return (
    <HomeCardStack
      component="section"
      $coverPublicURL={ coverPublicURL }
      gap={ 2 }
    >
      {
        (
          status === "C" 
          || (
            isMandatory
            && status === "E"
            || status === "P"
          ) 
          || isSuggestedAI
          || isSuggestedHR
          || isSuggestedManager
        ) ? 
          (
            <StatusBox
              accent={ accent  }
              icon={ iconStatus }

              sx={ {
                height:"60px",
                width: isExpired || isSuggestedAI || isSuggestedHR || isSuggestedManager ? "fit-content" : "60px",

                left:"-40px",
                padding:isExpired || isSuggestedAI || isSuggestedHR  || isSuggestedManager ? "0 16px" : 0,
                top:"0",

                "svg":{
                  height:"30px !important",
                  width:"30px !important"
                }
              } }
              message={ messageStatusBox }
            />
          ) : null
      }
      {
        typeObjectLearning || ecmType ? (
          <TypeTypography>
            { ecmType ? ecmType.toLocaleUpperCase() :  typeObjectLearning?.toUpperCase() }
          </TypeTypography>
        ) : null
      }
      <TitleTypography>
        { title }
      </TitleTypography>
      <CardCategories { ...props  } isHome = { true }/>
      {
        shortDescription ? (
          <DetailTypography>
            { shortDescription }
          </DetailTypography>
        ) : null
      }
      <Grid
        item
        container
        gap={ 4 }
      >
        <CardSignature
          startDate={ startDate }
          endDate={ endDate }
          editionNumber={ editionNumber }
          alignItems="center"
          gap={ isMobile ? 4 : 2 }
          expirationDate={ expirationDate }
          duration={ duration }
          learningObjectTypology={ learningObjectTypology }
        />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        gap={ 4 }
      >
        <LinkWithInteractableOverlay
          href={ `/${sectionUrl}/dettaglio/${id}/${learningObjectTypology}` }
          tabIndex={ 0 }
        >
          <Grid
            alignItems={ "center" }
            display={ "flex" }
            position={ "relative" }
            gap={ isMobile ? 3 : 2 }
            zIndex={ theme.zIndex.appBar }
          >
            <ButtonText
              onClick={ (e)=> {
                e.preventDefault();
                if(
                  (enrollType === ENROLL_TYPE.AUTO_ENROLL ||
                    enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL)
                    && (
                      !status ||
                      status === "N"
                    )
                    && !isExpired
                ) {
                  addEnrollmentMutation();
                } else if(resumeLinkLinkedin && resumeLink.includes("guarda")){
                  window.open(resumeLinkLinkedin, "_blank", "noreferrer");
                } else {
                  navigate(resumeLink);
                }
              } }
              sx={ {
                [theme.breakpoints.down("sm")]: {
                  borderRadius: "2px",
                  minWidth: "151px",
                  zIndex:theme.zIndex.appBar
                }
              } }
            >
              {  
                t(buttonText[status]?.label)
              }
            </ButtonText>
            {
              (badge || certificate) ? (
                <Box
                  onClick={ (e)=> {
                    if(badge?.isEnable || certificate?.isEnable){
                      e.preventDefault();
                      navigate("/profile");
                    } else {
                      e.preventDefault();
                    }
                  } }
                  sx={ {
                    cursor:(badge?.isEnable || certificate?.isEnable) ? "pointer" :"not-allowed"
                  } }
                >
                  <Icon
                    color={ (badge?.isEnable || certificate?.isEnable) ?
                      theme.customColors.systemPrimary02 :
                      theme.customColors.systemPrimary01 }
                    icon={ "Icons_certificato_disabled" }
                    size={ 24 }
                    style={ { 
                      height: isMobile ? "28px" : "24px",
                      marginLeft: isMobile ? 0 : theme.spacing(0.2)
                    } }
                  />
                </Box>
              ) : null
            }
          </Grid>
        </LinkWithInteractableOverlay>
      </Grid>
      <CtaOverlay
        isStandAlone={ Boolean(isStandAlone) }
        courseId={ courseId }
        isFavourite={ isFavourite }
        id={ id }
        learningObjectTypology={ learningObjectTypology }
        rating={ rating }
        rootId={ rootId }
        share={ true }
      />
    </HomeCardStack>
  );
}
