import {
  Box,
  Typography,
  type BoxProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { cardWidth } from "../../components/CarouselCard/CarouselCard.styles";

export const CardsBox = styled(Box)<
  BoxProps & { $hasFavourites?: boolean }
>(({ $hasFavourites=true, theme }) => ({
  alignItems:"center",
  display: "grid",
  gridGap: "1rem",
  gridRowGap: "2.5rem",
  gridTemplateColumns: $hasFavourites ? `repeat(auto-fill, ${cardWidth}px)` : undefined,
  justifyContent: $hasFavourites ? "space-around" : undefined,
  padding: $hasFavourites ? "32px 0 0" : "64px 0 16px",

  "&:has(.carousel-card--active)": {
    ">div:not(.carousel-card--active)":{
      pointerEvents:"none"
    }
  },

  [theme.breakpoints.down("sm")]:{
    gridRowGap:"1rem",
    gridTemplateColumns: "repeat(1, 1fr)",
    padding: $hasFavourites ? "24px 0 0" : "64px 0 16px",

    ">div":{
      height:"190px",
      margin:0,
      maxHeight:"190px",
      maxWidth:"unset",
      width:"100%",

      ">section":{
        maxHeight:"190px",
        minHeight:"190px"
      }
    }
  }
}));

export const HeroBox = styled(Box)(({ theme }) => ({
  marginTop: "2.5rem",
  maxWidth: "100%",
  minWidth: "calc(100% + 40px)",
  position:"relative",
  width: "100%",

  [theme.breakpoints.down("sm")]:{
    marginTop: theme.spacing(4),
    minWidth: "100%",
    width: "100%"
  }
}));

export const LastSavedTypography = styled(Typography)(({ theme }) => ({
  alignItems:"center",
  background:theme.customColors.backgroundSaved,
  borderRadius:"0px 4px 4px 0px",
  boxShadow: "8px 8px 15px rgba(0, 0, 0, 0.15)",
  color:theme.customColors.systemSecondary05,
  display:"flex",
  fontWeight:500,
  gap: theme.spacing(2),
  justifyContent:"space-between",
  maxWidth:"132px",
  padding: theme.spacing(1,2),
  position:"absolute",
  top:theme.spacing(3.25),
  whiteSpace:"nowrap",
  width:"fit-content",
  zIndex:"2",

  [theme.breakpoints.down("sm")]:{
    lineHeight: "1.3125rem",
    minHeight: "40px",
    minWidth: "132px",
    top: theme.spacing(5)
  }
}));
