
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import {
  CardTeacherBox,
  DescriptionTypography,
  ImageBox,
  TextContent,
  TitleTypography
} from "./CardTeacher.styles";
import { CardTeacherProps } from "./CardTeacher.types";
import { useTeacherQuery } from "../../queries";
import { PopUpTeacherComponent } from "../PopUpTeacher";

export function CardTeacher({
  id,
  image,
  overTitle,
  title,
  ...props
}: CardTeacherProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { data: teacher } = useTeacherQuery({
    enabled: isOpen,

    // additional params
    teacherId: id
  });

  return (
    <>
      <CardTeacherBox
        container
        gap={ 3 }
        onClick={ ()=> { setIsOpen(true) } }
        { ...props }
      >
        <ImageBox
          image={ image }
        >
          { image ?
            <img src={ image } alt="card teacher"/> :
            <Typography
              fontWeight={ 700 }
              fontSize={ "4rem" }
              height={ "fit-content" }
              margin={ "auto" }
              color={ theme.customColors.backgroundPrimaryLighter }
            >
              { title?.split(" ").map(i => i.charAt(0)).toString().toUpperCase().split(",") }
            </Typography>
          }
        </ImageBox>
        <TextContent>
          <TitleTypography>
            { title }
          </TitleTypography>
          <DescriptionTypography>
            { overTitle }
          </DescriptionTypography>
        </TextContent>
      </CardTeacherBox>

      <Dialog
        maxWidth={ false }
        open={ isOpen }
        onClose={ () => { setIsOpen(false) } }
        scroll={ "paper" }
        sx={ {
          ".MuiDialog-paper ": {
            background:"transparent",
            borderRadius:"8px",
            maxHeight:"calc(100% - 32px)"
          }
        } }
      >
        <PopUpTeacherComponent
          className={ `${isOpen ? "open" : ""}` }
          coverPublicURL={ image }
          email={ teacher?.email || "" }
          onClose={ () => { setIsOpen(false) } }
          shortDescription={ teacher?.description || "" }
          title={ title }
        />
      </Dialog>
    </>
  );
}
