import {
  Box,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SwiperCore, {
  A11y,
  Mousewheel,
  Pagination
} from "swiper";
import {
  SwiperRef,
  SwiperSlide
} from "swiper/react";
import { StyledSwiper } from "./Carousel.styles";
import { CarouselProps } from "./Carousel.types";
import {
  ButtonLink,
  Link,
  Spinner
} from "../../components";
import {
  cardHeight,
  cardHeightMobile
} from "../../components/CarouselCard/CarouselCard.styles";
import {
  CAROUSEL_TYPE
} from "../../consts";
import { useCalendarSelectedDateStore } from "../../stores";
/* eslint-disable import/no-unresolved */
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/pagination";
/* eslint-enable import/no-unresolved */

SwiperCore.use([A11y, Mousewheel, Pagination]);

type SlideProps =
  | {
    readonly carouselType: "DEFAULT" | "HERO",
    readonly id: never,
    readonly referenceRectangleWidth: number
  }
  | {
    readonly carouselId: string | null,
    readonly carouselType: "ZOOM" | "ZOOM_RELATED",
    readonly id: string,
    readonly referenceRectangleWidth: number
  }

export function Carousel({
  carouselType=CAROUSEL_TYPE.DEFAULT,
  children,
  hasNavigation=false,
  hasPagination=true,
  href=undefined,
  id,
  isFetching=false,
  itemsLength,
  isFetchingNextPage,
  fetchNextPage,
  label,
  title,
  isCalendar=false,
  ...props
}: CarouselProps & {
  fetchNextPage?:()=> void
  href?: string
  isCalendar?: boolean
  isFetching?: boolean
  isFetchingNextPage?:boolean

}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const{  state:routerState } = useLocation();
  const { t } = useTranslation();
  const carouselRef = useRef<SwiperRef | null>(null);
  const referenceBoxRef = useRef<HTMLDivElement | null>(null);
  const calendarSelectedDate = useCalendarSelectedDateStore((state) => state.selectedDate);
  const slideTo = useCalendarSelectedDateStore((state) => state.slideTo);
  const[lastIndex,setLastIndex]=useState(0);

  const [loaded, setLoaded] = useState(false);


  const slides = useMemo(
    () => {

       
      const slidesArray: string[] = [];
      const newChildren = Children.map( children as ReactElement<SlideProps>,
        (child) => {

          const { id: slideId } = child.props;
          slidesArray.push(slideId);

          const childProps = (carouselType === CAROUSEL_TYPE.ZOOM || carouselType === CAROUSEL_TYPE.ZOOM_RELATED)
            ? {
              carouselId: id,
              carouselType
            } : {};
          return (
            <SwiperSlide 
            >
              {
                isValidElement(child)
                  ? cloneElement(
                    child,
                    childProps
                  ) : child
              }
            </SwiperSlide>
          );
        });


      return newChildren;
      
    }, [carouselType, children, id]
  );
 
  useEffect(() => {
    if(isCalendar) {
      carouselRef.current?.swiper.slideTo(slideTo);
    }
  },
  [calendarSelectedDate, isCalendar, slideTo]);


  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <Box ref={ referenceBoxRef }
      sx={ {
        minWidth: (carouselType === CAROUSEL_TYPE.ZOOM || carouselType === CAROUSEL_TYPE.ZOOM_RELATED ) ?
          "calc(100% + 20px)" :
          undefined
      } }
    >
      {
        loaded ? (
          <>
            {
              (title || (carouselType === CAROUSEL_TYPE.ZOOM)) ? (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  marginBottom={ isMobile ? "1rem" : !isFetching ? "-3.5rem" : 0 }
                  position="relative"
                >
                  {
                    title ? (
                      <Typography
                        variant={ "h4" }
                        fontWeight={ "700" }
                        sx={ {
                          [theme.breakpoints.down("sm")]: {
                            fontSize:"1.5rem",
                            fontWeight: "700",
                            lineHeight: "1.25",
                            wordBreak: "break-word"
                          }
                        } }
                      >
                        { title }
                      </Typography>
                    ) : null
                  }
                  {
                    ( slides?.length && (carouselType === CAROUSEL_TYPE.ZOOM)) ? (
                      <Link
                        href={ href != undefined ? href : `/esplora/categories/${label}` }
                        state={ {
                          ...routerState,
                          titleCards:title
                        } }
                      >
                        { itemsLength  &&
                          <ButtonLink
                            disabled={ isFetching }
                            disableRipple
                            sx={ {
                              cursor:"pointer",
                              fontSize:"1.125rem",
                              fontWeight:"500",
                              minWidth:"unset",
                              padding:0,
                              textDecoration:"underline",
                              whiteSpace:"nowrap",
                              zIndex:theme.zIndex.mobileStepper,

                              "&:hover": {
                                background:"none",
                                boxShadow:"none"
                              },
                              [theme.breakpoints.down("sm")]: {
                                fontSize:"0.75rem",
                                paddingRight: "20px"
                              },

                              "&.Mui-disabled": {
                                color:theme.customColors.backgroundDisabled,
                                textDecoration:"none"
                              }
                            } }
                          >
                            { t("discover_all") }
                          </ButtonLink>
                        }
                      </Link>
                    ) : null
                  }
                </Stack>
              ) : null
            }
            {
              isFetching ? (
                <Spinner minHeight={ isMobile ? cardHeightMobile : cardHeight }/>
              ) : (
                <StyledSwiper
                  key={ label }
                  ref={ carouselRef }
                  a11y={ { enabled: true } }
                  direction={ "horizontal" }
                  mousewheel={ {
                    forceToAxis:true
                  } }
                  
                  initialSlide={ isCalendar ? slideTo : lastIndex ? lastIndex :  0 }
                  navigation={ hasNavigation }
                  onDrag={ ()=> {
                    if(fetchNextPage){
                      fetchNextPage();
                    }
                  } }
                  onSlideChange={ ()=> {
                    if(fetchNextPage && slides && carouselRef.current?.swiper.realIndex === slides?.length  - 3){
                      setLastIndex(carouselRef.current?.swiper.realIndex);
                      fetchNextPage();
                    }
                  } }
                  pagination={
                    hasPagination ? {
                      clickable: true,
                      dynamicBullets: carouselType === CAROUSEL_TYPE.ZOOM_RELATED || 
                      carouselType === CAROUSEL_TYPE.ZOOM,
                      dynamicMainBullets:  carouselType === CAROUSEL_TYPE.ZOOM ? 2 : 3
                    } : false
                  }
                  slidesPerView={ (carouselType === CAROUSEL_TYPE.HERO) ? 1 : "auto"  }
                  $carouselType={ carouselType }
                  $isMobile={ isMobile }
                  $label={ label }
                  breakpoints={ {
                    0: {
                      spaceBetween: (
                        (carouselType === CAROUSEL_TYPE.DEFAULT) ||
                    (carouselType === CAROUSEL_TYPE.HERO)
                      ) ? 8 : 16
                    },
                    600: {
                      spaceBetween: (carouselType === CAROUSEL_TYPE.HERO) ? 42 : 30
                    }
                  } }

                  { ...props }
                >
                  { slides }
                  {
                    isFetchingNextPage ?

                      <SwiperSlide>
                        <Spinner size={ 30 }/>
                      </SwiperSlide> : null
                  }
                </StyledSwiper>
              )
            }
          </>
        ) : null
      }
    </Box>
  );
}
