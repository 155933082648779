import {
  Box,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import {
  alpha,
  styled
} from "@mui/material/styles";
import {
  PickersDay,
  PickersDayProps
} from "@mui/x-date-pickers";
import { useState } from "react";
import { Icon } from "../../components";

export const CalendarContainerStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  padding: theme.spacing(2),

  "& .MuiDateCalendar-root .MuiPickersCalendarHeader-labelContainer": {
    "button": {
      display: "none"
    },
    "div": {
      fontSize: "min(0.875rem, 1.5vw)",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        fontWeight: "700",
        lineHeight: "1,3125rem",
        textTransform: "capitalize"
      }
    }
  },
  ".MuiPickersCalendarHeader-root": {
    margin: "0",
    marginBottom: "0.65rem",
    // marginLeft: "1rem",
    marginLeft: "auto",
    // marginRight: "1rem",
    marginRight: "auto",
    padding: "0",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
      // marginLeft: "0.5rem",
      // marginRight: "0.5rem",
      maxHeight: "21px",
      minHeight: "21px",
      pointerEvents: "none"
    }
  },

  ".MuiPickersCalendarHeader-labelContainer": {
    display: "block",
    position: "absolute",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left"
    }
  },

  ".MuiPickersCalendarHeader-label": {
    margin: 0
  },

  ".MuiPickersArrowSwitcher-root": {
    gap: "3.5rem",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },

  ".MuiDayCalendar-weekDayLabel": {
    color: theme.customColors.textMandatory,
    fontSize: "0.75rem",
    fontWeight: "700",
    height: "1.5625rem",
    width: "1.5625rem",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.5076rem"
    }
  },

  ".MuiPickersDay-hiddenDaySpacingFiller": {
    height: "1.5625rem",
    width: "1.5625rem"
  },

  ".MuiDayCalendar-slideTransition": {
    minHeight: "14.5rem",
    [theme.breakpoints.down("sm")]: {
      minHeight: "41px"
    } 
  },

  ".MuiYearCalendar-root": {
    width: "100%"
  },

  ".MuiDayCalendar-header": {
    justifyContent: "space-around"
    // padding: theme.spacing(0, 1)
  },

  ".MuiDayCalendar-weekContainer": {
    justifyContent: "space-around",
    //padding: theme.spacing(0, 1)
    [theme.breakpoints.down("sm")]: {
      margin: "0"
    }
  },

  ".MuiDayCalendar-monthContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    // padding: theme.spacing(1),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down("sm")]: {
      gap: "0",
      justifyContent: "center",
      marginBottom: "16px",
      maxHeight: "25px"
      // padding: "0 8px"
    }
  }

}));

export const CourseStack = styled(Stack)(() => ({
  // minWidth: "46%",
  cursor: "pointer",
  overflow: "hidden",
  whiteSpace: "nowrap"
}));

export const CurrentDayOfWeekTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textMandatory,
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.1875rem"
}));

export const CurrentDayTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "2.25rem",
  fontWeight: "700",
  lineHeight: "2.75rem",
  textAlign: "center"
}));

export const SelectedDayTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "2.0625rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    margin: "16px 0"
  }
}));

export const CourseTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: "0.75rem",
  padding: "0.125rem",
  whiteSpace: "nowrap"
}));

export function CalendarArrow({ icon, iconGlow } : {icon: string, iconGlow: string}) {
  const theme = useTheme();
  const [showingIcon, setShowingIcon] = useState<string>(icon);

  return <Box
    sx={ { 
      display: "flex",

      "& svg:hover":{ 
        filter: `drop-shadow(0px 0px 2px ${theme.customColors.systemPrimary02})` 
      },
      
      "& path":
      {
        fill:  theme.customColors.systemPrimary02
      }
    } }
    
  >
    <Icon icon={ showingIcon } width={ "2rem" }
      onMouseDown={ () => setTimeout(() => setShowingIcon(iconGlow), 1) }
      onTouchStart={ () => setShowingIcon(iconGlow) }
      onMouseUp={ () => setTimeout(() => setShowingIcon(icon), 1) }
      onTouchEnd={ () => setShowingIcon(icon) }/>
  </Box>;
}

export const DotSpan = styled("span")(({ theme }) => ({
  backgroundColor: theme.customColors.textDisabledAlternative,
  borderRadius: "50%",
  bottom: 0,
  position: "absolute",
  transition: "max-height 450ms ease-in, max-width 450ms ease-in",

  height: "0.3rem",
  maxHeight: "1rem",
  maxWidth: "1rem",
  width: "0.3rem"
}));

export const StyledPickersDay = styled(PickersDay<Date>)<
  PickersDayProps<Date> & {
    $activityColor: string,
    $inBetween: boolean,
    $sameDay: boolean,
    $selected: boolean
  }
>(({
  $activityColor,
  $inBetween,
  $sameDay,
  $selected,
  theme
}) => ({
  // aspectRatio: $selected ? "1" : undefined,
  backgroundColor: $selected ? $activityColor : "none",
  // boxShadow: $selected && !$sameDay ? `0px 0px 8px ${activityColor}` : "none",
  color: $selected
    ? theme.palette.common?.black
    : $sameDay
      ? $activityColor
      : theme.customColors.textPrimary,
  fontSize: "0.75rem",
  fontWeight: "700",
  height: "1.5625rem",
  width: "1.5625rem",
  [theme.breakpoints.down("sm")]: {
    lineHeight: "1,5076rem"
  },

  "&:focus": {
    backgroundColor: $selected ? $activityColor : "none"
  },

  "&:hover": {
    // aspectRatio: "1",
    backgroundColor: $selected ? $activityColor : "none",
    border: `2px solid ${alpha($activityColor, 0.6)}`,
    boxShadow: `0px 0px 12px ${$activityColor}, inset 0px 0px 4px ${$activityColor}`,
    
    [`${DotSpan}`]: {
      transition: "max-height 50ms ease-in, max-width 50ms ease-out",

      maxHeight: 0,
      maxWidth: 0
    }
  },

  "&:active": {
    // aspectRatio: "1",
    background: alpha($activityColor, 0.25),
    boxShadow: `0px 0px 12px ${$activityColor}, inset 0px 0px 4px ${$activityColor}`
  },

  [theme.breakpoints.down("sm")]: {
    display: !$inBetween ? "none" : "inline-flex"
  }
}));

