import {
  alpha,
  Box,
  BoxProps,
  Grid,
  GridProps,
  Stack,
  StackProps,
  Typography,
  TypographyProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ElementType } from "react";
import { PlayerLoaderBox } from "../../components/PlayerLoader/PlayerLoader.styles";
import { _Box } from "../../styles/mui-referenced";
import type { LearningObject } from "../../types";

export const cardHeight = 243; //px
const cardHeightSmall = 177; //px
export const cardWidth = 431; //px
export const cardWidthSmall = 315; //px

const slideHeight = 378; //px
const slideHeightSmall = 315; //px
export const slideWidth = 506; //px
export const slideWidthSmall = 406; //px

const cardWidthMobile = 315; //px
export const cardHeightMobile = 177; //px

export const CarouselCardAnimatedStack = styled(Stack)<
  StackProps & {
    $delay:string
  }
>(({ $delay }) => ({
  display: "grid",
  gap:"0.5rem",
  gridTemplateRows: "0fr",
  maxWidth:"100%",
  opacity:0,
  transition:`all .2s linear ${$delay}s`,
  zIndex:1,

  "& > *": {
    overflow: "hidden"
  }
}));

export type CarouselCardGridProps =
  GridProps
  & Pick<
    LearningObject,
    "coverPublicURL"
  >
  & {
    component: ElementType
    $noHover?: boolean
    $small?: boolean
    $zoomable?: boolean
    $zoomed?: boolean
  }

export const CarouselCardGrid = styled(Grid)<CarouselCardGridProps>(({
  coverPublicURL,
  theme,
  $small
}) => ({
  background: `${theme.linearGradients.gradientCAlternative01}, url(${coverPublicURL})`,
  backgroundPosition: "center",
  backgroundRepeat:"no-repeat",
  backgroundSize:"cover",
  borderRadius: "0.5rem",
  cursor:"pointer",
  opacity: 1,
  padding:theme.spacing(0, 5,3),
  position: "relative",

  height: $small ? slideHeightSmall : slideHeight,
  maxHeight: $small ? cardHeightSmall : cardHeight,
  maxWidth: $small ? cardWidthSmall : cardWidth,
  width: $small ? slideWidthSmall : slideWidth,
    
  transition: getAnimationParams([
    { delay:20, duration: 80, property: "opacity", timing: "ease-in-out" }
  ]),

  [theme.breakpoints.down("sm")]: {
    background: `$${theme.linearGradients.gradientCAlternative01}, url(${coverPublicURL})`,
    backgroundSize: "cover",
    padding: "16px 24px 16px 24px",
    width:"100%",

    height: cardHeightMobile,
    maxHeight: cardHeightMobile,

    ".MuiChip-root ": {
      height: "auto"
    }
  }
}));

export const CarouselSlideBox = styled(_Box)<
  BoxProps & { $small?: boolean, $disabled?:boolean }
>(({ $disabled = false,$small, theme }) => {
  const slideBoxHeight = $small ? cardHeightSmall : cardHeight;
  const slideBoxWidth = $small ? cardWidthSmall : cardWidth;
  const portalHeight = $small ? slideHeightSmall : slideHeight;
  const portalWidth = $small ? slideWidthSmall : slideWidth;

  return {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    pointerEvents:$disabled ? "none" : "auto",
    position: "relative",

    height: slideBoxHeight,
    maxWidth: slideBoxWidth,
    minWidth: slideBoxWidth, /* setting an explicit width breaks the carousel */

    "&:hover":{
      [`${CarouselCardGrid}`]:{
        opacity: 0,
        [theme.breakpoints.down("sm")]: {
          opacity: 1
        }
      },

      [`${CarouselCardAnimatedStack}`]:{
        gridTemplateRows: "1fr",
        opacity:1
      },
      
      [`${StyledPortalWrapper}`]:{
        opacity:1,
        transform:"scale(1)",

        height:portalHeight,
        maxWidth:portalWidth,
        width:portalWidth,

        transition: getAnimationParams([
          { delay:0, duration: 150, property: "width", timing: "ease-out" },
          { delay:0, duration: 150, property: "height", timing: "ease-out" },
          { delay:20, duration: 100, property: "opacity", timing: "ease-out" },
          { delay:0, duration: 20, property: "transform", timing: "linear" }
        ])
      },

      [`${PlayerLoaderBox}`]:{
        position:"relative",

        "&:before":{
          background: theme.linearGradients.gradientD,
          content: "''",
          inset: 0,
          position: "absolute",
          zIndex: 1,

          height: "100%",
          width: "100%"
        }
      }
    },

    [theme.breakpoints.down("sm")]:{
      height: cardHeightMobile,
      maxWidth: cardWidthMobile,
      minWidth: cardWidthMobile
    }
  };
});

export const DetailTypography = styled(Typography)<
  TypographyProps & { $clamp?: number }
>(({ $clamp=2 }) => ({
  /* CLAMP TEXT TO X LINES */
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": $clamp.toString(),

  display: "-webkit-box",
  overflow: "hidden",
  overflowWrap:"break-word",
  textOverflow: "ellipsis"
}));

export const DateTypography = styled(Box)(({ theme }) => ({
  alignItems:"center",
  display:"flex",
  fontSize:theme.spacing(2),
  gap:theme.spacing(1),
  marginTop:theme.spacing(1.25)
}));

export const StyledDurationBadge = styled(Box)<
 BoxProps & { $completed: boolean }
>(({ $completed,  theme  }) => ({
  alignItems: "center",
  background: $completed ? "#757575" : theme.customColors.backgroundPrimaryCta
    && alpha(theme.customColors.backgroundPrimaryCta, 0.8),
  borderRadius: theme.spacing(1),
  color: theme.customColors.textTag,
  display: "flex",
  fontSize: theme.spacing(1.5),
  fontWeight: 700,
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  pointerEvents:"none",
  position: "absolute",

  [theme.breakpoints.down("sm")]:{
    lineHeight: "1.16667",
    padding: theme.spacing(1),
    right: theme.spacing(3),
    
    "svg": {
      height: "15px",
      widht: "15px"
    }
  }
}));
export const TitleTypography = styled(Typography)<
  TypographyProps & { $clamp?: number }
  >(({ $clamp=1 }) => ({
    /* CLAMP TEXT TO X LINES */
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": $clamp.toString(),

    display: "-webkit-box",
    maxWidth: "100%",
    overflow: "hidden",
    overflowWrap:"break-word",
    textOverflow: "ellipsis",

    fontWeight:"700"
  }));

export type coordinate = {
  bottom?:number
  left?:number
  right?:number
  top?:number
}

export const StyledPortalWrapper = styled(Box)<
  BoxProps & { 
    $accent:string,
    $coordinate?: coordinate,
    $small:boolean
  }
  >(({ $accent, $small, theme }) => { 

    const slideBoxHeight = $small ? cardHeightSmall : cardHeight;
    const slideBoxWidth = $small ? cardWidthSmall : cardWidth;
    return {
      borderRadius: "0.5rem",
      filter: `drop-shadow(0px 0px 4px ${$accent})`,
      opacity:0,
      outline: `0.125rem solid ${$accent}`,
      pointerEvents: "auto",
      position:"absolute", 
      transform: "scale(0.5)",
      zIndex:"99999",

      height:slideBoxHeight,
      maxWidth:slideBoxWidth,
      width:slideBoxWidth,

      transition: getAnimationParams([
        { delay:0, duration: 100, property: "width", timing: "linear" },
        { delay:0, duration: 100, property: "height", timing: "linear" },
        { delay:0, duration: 100, property: "opacity", timing: "linear" },
        { delay:0, duration: 100, property: "transform", timing: "linear" }
        
      ]),

      [theme.breakpoints.down("sm")]:{
        height: cardHeightMobile,
        maxWidth: cardWidthMobile,
        minWidth: cardWidthMobile
      }
    };});

function getAnimationParams(
  entries: {
    delay:number,
    duration: number
    property: string,
    timing: string
  }[]
) {
  return entries.map(({  delay, duration, property, timing }) => (
    `${property} ${duration}ms ${timing} ${delay}ms`
  )).join(", ");
}
