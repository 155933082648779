/*********************/
import { v4 as uuidv4 } from "uuid";
import { fetcherFactory } from "../../services";
import { secondsTo8061Duration } from "../../utils/datetime";
import { getEnvironmentVariables } from "../../utils/general";
/*********************/

/**
 * tracciamento xapi
 * @param param0
 */
const { 
  //basePath,  // TO DO CHANGE WITH BASEPATH 
  xapiTracker
} = getEnvironmentVariables();
const basePath = xapiTracker;

export function videoStatement({
  accessToken,
  corporateId,
  courseId,
  description,
  duration,
  id,
  idUser,
  organizationId,
  parentId,
  sessionToken,
  title,
  type,
  userName,
  userEmail,
  tentativeId
}: {
  accessToken:string,
  corporateId:number | string,
  description: string
  duration: number
  id: number
  idUser: number
  organizationId: number
  title: string
  type: string
  userEmail: string
  userName: string
  courseId: string | null
  parentId: string | null
  sessionToken:string,
  tentativeId: number | null
}) {
  const fetcher = fetcherFactory();
  const uid = uuidv4();
  // I am the xhml call thing from the S4C saga POST_STATEMENTS._REQUEST
  const body = {
    actor: {
      mbox: `mailto:${userEmail}`,
      name: `${userName}`,
      objectType: "Agent"
    },
    context:{
      "contextActivities": {},
      "registration": sessionToken
    },
    id: uid, // ID corso unique id
    object: {
      definition: {
        description: {
          "it-IT": description || ""
        },
        name: {
          "it-IT": title || ""
        }
      },
      // eslint-disable-next-line max-len
      id:`https://test-openlearning-learningobject.s3.eu-south-1.amazonaws.com/${organizationId}/${idUser}/${parentId}/${courseId}/${id}/${tentativeId}`,
      objectType: "Activity"
    },
    result: type !== "initialized" ? {
      completion: type === "completed",
      duration: secondsTo8061Duration(duration),
      score: { scaled: 0.95 },
      success: type === "completed"
    } : undefined,
    verb: {
      display: getDisplayVerb(type),
      id: `http://adlnet.gov/expapi/verbs/${type}`
    },
    version: "1.0.0"
  };
  
  fetcher(
    `${basePath}/${corporateId}/statements`,
    {
      body: JSON.stringify(body),
      headers:{
        "authorization":accessToken ,
        "content-type":"application/json",
        "X-Experience-Api-Version":"1.0.0"
      },
      method: "POST"
    }
  ).then((res )=> {
   
    if( res && res?.bodyUsed){
      return res.json();
    }
    else {
      return res;
    }
   
  }).catch((err)=> {
    // eslint-disable-next-line no-console
    console.error(err, "error post brigthcove");
  });

}

export function videoSave({
  accessToken,
  corporateId,
  courseId,
  duration,
  id,
  idUser,
  organizationId,
  parentId,
  sessionToken,
  userName,
  userEmail,
  tentativeId
}: {
  accessToken:string,
  corporateId:string | number,
  duration: number
  id: number
  idUser: number
  organizationId: number
  userEmail: string
  userName: string
  courseId: string | null
  parentId: string | null
  sessionToken:string,
  tentativeId: number | null
}) {
  const fetcher = fetcherFactory();

  // I am the xhml call thing from the S4C saga POST_STATEMENTS._REQUEST

  const activityId =
    // eslint-disable-next-line max-len
    `https://test-openlearning-learningobject.s3.eu-south-1.amazonaws.com/${organizationId}/${idUser}/${parentId}/${courseId}/${id}/${tentativeId}`;
  const registration = sessionToken;
  const agent = JSON.stringify({ "mbox": `mailto:${userEmail}`, "name": `${userName}`, "objectType": "Agent" });
  const body = {
    attemptComplete: false,
    attemptDuration: duration,
    location: 1
  };
  
    
  fetcher(
    // eslint-disable-next-line max-len
    `${basePath}/${corporateId}/activities/state?stateId=bookmarking-data&activityId=${activityId}&agent=${encodeURI(agent)}&registration=${registration}`,
    {
      body: JSON.stringify(body),
      headers:{
        "authorization": accessToken,
        "content-type":"application/json",
        "X-Experience-Api-Version":"1.0.0"
      },
      method: "POST"
    }
  ).then((res )=> {
    return  res;
  }).catch((err)=> {
    // eslint-disable-next-line no-console
    console.error(err, "error post save brigthcove");
  });

}
export function videoTracker({
  accessToken,
  corporateId,
  courseId,
  id,
  idUser,
  organizationId,
  parentId,
  sessionToken,
  userName,
  userEmail,
  tentativeId
}: {
  accessToken:string,
  id: number
  idUser: number
  organizationId: number
  sessionToken:string
  userEmail: string
  userName: string
  corporateId:number | string
  courseId: string | null
  parentId: string | null,
  tentativeId: number | null
}) {
  const fetcher = fetcherFactory();
  // I am the xhml call thing from the S4C saga POST_STATEMENTS._REQUEST

  const activityId =
    // eslint-disable-next-line max-len
    `https://test-openlearning-learningobject.s3.eu-south-1.amazonaws.com/${organizationId}/${idUser}/${parentId}/${courseId}/${id}/${tentativeId}`;
  const registration = sessionToken; // TO CHANGE
  const agent = JSON.stringify({ "mbox": `mailto:${userEmail}`, "name": `${userName}`, "objectType": "Agent" });


  const result =  fetcher(
    // eslint-disable-next-line max-len
    `${basePath}/${corporateId}/activities/state?stateId=bookmarking-data&activityId=${activityId}&agent=${encodeURI(agent)}&registration=${registration}`,
    {
      headers:{
        "authorization": accessToken ,
        "X-Experience-Api-Version":"1.0.0"
      },
      method: "GET"
    }
  ).then((res )=> {

    return res ? res?.json() : res;
  }).catch((err)=> {
    // eslint-disable-next-line no-console
    console.error(err, "error post brigthcove");
  });
  return result;

}

function getDisplayVerb(type: string) {
  switch (type) {
  case "initialized":
    return {
      "en-US": "initialized",
      "it-IT": "ha inizializzato"
    };
  case "completed":
    return {
      "en-US": "completed",
      "it-IT": "ha completato"
    };
  case "terminated":
    return {
      "en-US": "terminated",
      "it-IT": "ha terminato"
    };
  default:
    return {};
  }
}
