import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
import {
  ResultsStack,
  ResultTypography,
  SearchCancel,
  StyledTextInput
} from "./SelectInput.styles";
import type { SelectInputProps } from "./SelectInput.types";
import { Icon, type IconProps } from "../../components";
import { useIntersectionObserver } from "../../hooks";
import { type OnBoardingItemType } from "../../queries/onboarding";


export function SelectInput({
  callBack,
  onChange,
  onMouseDown,
  label,
  onBlur,
  placeholder,
  setValue,
  setValueSelected,
  type,
  resultsApi,
  value,
  ...props
}: SelectInputProps) {

  const selectInputRef = useRef<HTMLInputElement>(null);
  const isOpen = selectInputRef?.current === document.activeElement;
  const [focusInput, setFocusInput]=useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const containerRef = useRef<HTMLDivElement | null>(null);
  const intersectionObserver = useIntersectionObserver({
    callback: () => { callBack()  },
    container: containerRef
  });
  
  return (
    <Stack
      direction="row"
      gap={ 1.5 }
      width={ props.fullWidth ? "100%" : "50%" }
    >
   

      <Box
        position="relative"
        width="100%"
      >
        { label ? 
          <Typography 
            fontSize={ isMobile ? theme.spacing(1.75) : theme.spacing(2) }
            marginBottom={ isMobile ? 0 :  1 }>
            { label }
          </Typography> 
          :  null
        }
        <StyledTextInput
          ref={ selectInputRef as RefObject<HTMLDivElement> }
          fullWidth
          placeholder={ placeholder }
          InputProps={ {
            endAdornment: type === "cancel" ? (
              <SearchCancelElement
                show={ !isOpen }
                value={ value }
                setValue={ setValue }
                setValueSelected={ setValueSelected }
                size="1.2rem"
              />
            ) : (
              <Box
                sx={ {
                  height:"fit-content",
                  lineHeight:0,
                  transform:focusInput ? "rotate(180deg)" : "none",
                  transition:"all .3s linear"
                } }
              >
                <Icon 
                  color={ theme.customColors.systemPrimary02 } 
                  icon="dropdown" 
                  size={ 20 }/>
              </Box>
             
            ) }
          }
          onChange={ (changeEvent: ChangeEvent<HTMLInputElement>) => { 
            onChange(changeEvent);
          } }
          onMouseDown={ () => { 
            onMouseDown();
            setFocusInput(true);
           
          } }
          onBlur={ ()=> {
           
            setTimeout(()=> {
              setFocusInput(false);
              if(onBlur){
                onBlur();
              }
            },100);
            
          } }
          tabIndex={ 0 }
          value={ value }
        />
        {
          ( resultsApi && focusInput) ? (
            <ResultsStack ref={ containerRef }>
              {
                resultsApi?.length ? 
                  resultsApi
                    ?.map((result, idx) => (
                      <ResultTypographyItem
                        key={ `search-result--${idx}` }
                        item={ result }
                        observer={ intersectionObserver }
                        observeItem={ (idx + 1)  === resultsApi.length - 1 }
                        setFocusInput={ setFocusInput }
                        setItemSelected={ setValueSelected }
                      />
                    )) : 

                  <ResultTypography> no results</ResultTypography>
              }
            </ResultsStack>
          ) : null
        }
      </Box>
    </Stack>
  );
}
function ResultTypographyItem({
  observer,
  observeItem=false,
  item,
  setFocusInput,
  setItemSelected
  // ...props
} : {
  observer: IntersectionObserver | null
  observeItem?: boolean
  item: OnBoardingItemType
  setFocusInput:Dispatch<SetStateAction<boolean>>
  setItemSelected:Dispatch<SetStateAction<Pick<OnBoardingItemType, "id" | "name">| null>>
}){

  const selfRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    observeItem && selfRef.current && observer?.observe(selfRef.current);
  }, [observer, observeItem]);

  return (
    <ResultTypography
      ref={ selfRef }
      onClick={ () => {
        setItemSelected({ ...item });
        setFocusInput(false);
      } }
    >
      { item.name }
    </ResultTypography>
  );
}

function SearchCancelElement({
  show,
  value,
  setValue,
  setValueSelected,
  ...props
}: Omit<
  IconProps,
  "icon"
> & {
    show: boolean,
    value: string,
    setValue: Dispatch<SetStateAction<string>>
    setValueSelected:Dispatch<SetStateAction<Pick<OnBoardingItemType, "id" | "name"> | null>>
  }
) {
  return show ? (
    <SearchCancel
      cursor={ value ? "pointer" : undefined }
      icon="close"
      onClick={ () => { setValue(""); setValueSelected(null)} }
      { ...props }
    />
  ) : null;
}
