import BrightcoveReactPlayerLoader from "@brightcove/react-player-loader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useRef,
  useState,
  useEffect
} from "react";
import { PlayerLoaderBox } from "./PlayerLoader.styles";
import type {
  BrightcovePlayer,
  PlaybackStatus,
  PlayerLoaderProps
} from "./PlayerLoader.types";
import {
  videoSave,
  // putActivity,
  videoStatement, videoTracker
} from "./queries";
import { useAuthStore } from "../../stores";
import { getEnvironmentVariables } from "../../utils/general";

/**
 * use a wrapper to check if a player instance is already initialized
 * @param options add from official @brightcove/player-loader docs as required
 * @returns
 */
export function PlayerLoader({
  autoplay=false,
  canPlayBack,
  canProceed,
  className,
  courseId,
  coverFallBack,
  id,
  onEndAction,
  parentId,
  shortDescription,
  skipTracking=false,
  // startFrom=0,
  title,
  triggerPause=false,
  tentativeId,
  videoId,
  ...props
}: PlayerLoaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const userData = useAuthStore(state => state.userData);
  const session = useAuthStore(state => state.session);
  const sessionToken = useAuthStore(state => state.sessionToken);

  const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus>("stop");
  const [renderError, setRenderError] = useState(false);
  const hasStarted = useRef<boolean>(false);
  const playerRef = useRef<BrightcovePlayer>();
  const { brightcoveAccountId, brightcovePlayerId } = getEnvironmentVariables();

  // NOTE, atm the terminated statement is not handled by the BE
  // NOTE!, the return statement of this side effect runs erratically, disposing of 
  //        the player when entering with a new video id rather than when leaving
  // useEffect(()=> {
  //   function disposePlayer() {
  //     if (playerRef.current && !playerRef.current.isDisposed_) {
  //       if (
  //         playerRef.current.hasStarted_ &&
  //         playerRef.current.cache_?.currentTime < playerRef.current.cache_?.duration &&
  //         !skipTracking &&
  //         userData?.id_user
  //       ) {
  //         const time = playerRef.current.currentTime();
  //         // putActivity({ body: { val: time.toString() } });

  //         // const endTime = Math.max(Math.round(time - startFrom), 0);
  //         const endTime = time;

  //         videoStatement({
  //           courseId: courseId,
  //           description: shortDescription,
  //           duration: endTime,
  //           id,
  //           idUser: userData.id_user,
  //           organizationId: userData.organization_id,
  //           parentId: parentId,
  //           title: title,
  //           type: "terminated",
  //           userEmail: userData.email,
  //           userName: userData.external_id
  //         });
  //       }
  //       playerRef.current.dispose();; // is it necessary to manually unmount?
  //     }
  //   }
  //   return () => disposePlayer();
  // }, [courseId, id, parentId, skipTracking, shortDescription, title, userData]);

  useEffect(()=> {
    if (
      playerRef.current
      && playerRef?.current?.currentTime() > 0.01
      && userData && triggerPause
    ) {
      videoSave({
        accessToken: session?.getAccessToken()?.getJwtToken() ?? "",
        corporateId: userData.organization_id ?? "",
        courseId: courseId,
        duration: playerRef.current.currentTime(),
        id: id,
        idUser: userData.id_user,
        organizationId: userData.organization_id,
        parentId: parentId,
        sessionToken: sessionToken ?? "",
        tentativeId: tentativeId,
        userEmail: userData.email,
        userName: userData.external_id
      });
    }
  }, [
    courseId, 
    id, 
    tentativeId,
    parentId,
    playerRef, 
    session,
    sessionToken,
    triggerPause, 
    userData
  ]);

  return (
    <PlayerLoaderBox
      className={ `${className} ${getPlaybackClass(playbackStatus)} ${canPlayBack ? "canPlayBack" : ""}` }
      $image={ renderError ? coverFallBack : undefined }
      { ...props }
    >
      {
        !renderError ? (
          <>
            <BrightcoveReactPlayerLoader
              accountId={ brightcoveAccountId }
              poster={ coverFallBack }
              playerId={ brightcovePlayerId }
              videoId={ videoId }
              onFailure={ onFailure }
              onSuccess={ onSuccess }
              options={ {
                autoplay:false
              } }
            />
            { props.children }
          </>
        ) : (
          <>
            { props.children }
          </>
        )
      }
    </PlayerLoaderBox>
  );

  function onFailure(error: Error) {
    // eslint-disable-next-line no-console
    console.error(error, "error");
    setRenderError(true);
  }

  function onSuccess(
    success: { ref: { player_: BrightcovePlayer}}
  ) {
    const {
      player_
      // controlBar
    } = success.ref;

    player_?.on("error", ()=> {
      setRenderError(true);
    });

    player_?.on("loadedmetadata", ()=> {
      if (userData){
        videoTracker({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        })
          ?.then(res => {
            if (res && player_ && res.attemptDuration > 0.01 && autoplay){
              player_?.currentTime(res?.attemptDuration );
              player_?.play();
            } else {
              playerRef.current?.currentTime(0);
              playerRef.current?.play();
            }
          });
      }
    });

    player_?.on("playing", ()=> {
      window.addEventListener("blur", ()=> {
        player_?.pause();
        player_?.muted(true);
      });
    });

    player_?.on("play", () => {
      if (isMobile){
        player_.requestFullscreen();
        // screen.orientation.lock("landscape");
      }
      player_?.muted(false);
      setPlaybackStatus("play");

    
      if (skipTracking) return;
      if (!hasStarted.current && userData) {

        videoStatement({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          description: shortDescription,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          title: title,
          type: "initialized",
          userEmail: userData.email,
          userName: userData.external_id
        });
        hasStarted.current = true;

        // putActivity({
        //   body: { val: player_.currentTime().toString() }
        // });
      }
    });

    player_?.on("pause", () => {
      player_?.muted(true);
      setPlaybackStatus("pause");
    
      if(player_.currentTime() > 0.01 && userData){
        videoSave({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        });
      }

    });

    player_?.on("ended", () => {
      if (onEndAction) { onEndAction() }
      if (isMobile) { 
        player_.exitFullscreen();
        // screen.orientation.lock("natural");
      }
      setPlaybackStatus("stop");
      if (skipTracking) return;
      if (player_.currentTime() > 0.01 && userData) {
        videoStatement({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          description: shortDescription,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          title: title,
          type: "completed",
          userEmail: userData.email,
          userName: userData.external_id
        });
        videoSave({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          duration: 0,
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        });
        canProceed && canProceed(true);
      }
    });
    playerRef.current = player_;
  }

  function getPlaybackClass(status: PlaybackStatus) {
    switch(status) {
    case "pause":
    case "stop":
      return " brightcove-is-paused";
    case "play":
      return " brightcove-is-playing";
    default:
      return "";
    }
  }
}
