
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledButtonBookmark,
  StyledButtonLink,
  StyledCoverLoader,
  StyledIcon,
  StyledPlayerLoader, 
  StyledStatusBoxPlayer,
  VideoStack
} from "./Video.styles";
import { VideoComponentProps } from "./Video.types";
import {
  CtaOverlay, 
  GridMain, 
  Icon,
  ScormWrapper,
  StatusBox,
  VideoCaptionOverlay
} from "../../components";
import {
  LEVEL,
  STATUS_LO
} from "../../consts";
import { useInvalidateStartOrResumeQuery } from "../../queries";
import { useNavigate } from "../../services";


const EXTERNAL_LEVEL = {
  [LEVEL.DA_SCORM]: LEVEL.DA_SCORM,
  [LEVEL.DA_XAPI]: LEVEL.DA_XAPI,
  [LEVEL.DA_YOUTUBE]: LEVEL.DA_YOUTUBE
} as const;
type ExternalContent = keyof typeof EXTERNAL_LEVEL

export function VideoComponent({
  autoplay,
  borderRadius,
  courseDetails,
  courseId,
  coverFallBack,
  height,
  id,
  isFavourite,
  isMandatory,
  isStandAlone,
  learningObjectType,
  learningObjectTypology,
  moduleNumber,
  parentId,
  rating,
  rootDetails,
  shortDescription,
  status,
  title,
  videoId,
  tentativeId,
  width="100%",
  ...props
}: VideoComponentProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const [buttonSelected, setButtonSelected] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [externalIsOpen ,setExternalIsOpen] = useState<ExternalContent | null>(null);
  const [triggerPausePlayer, setTriggerPausePlayer] = useState(false);

  const { invalidateQueryStartOrResume } = useInvalidateStartOrResumeQuery({
    learningObjectId: parentId ?? "",
    learningObjectTypology: rootDetails?.learningObjectTypology ?? courseDetails?.learningObjectTypology ?? null
  });

  const isExternal =
    learningObjectTypology === EXTERNAL_LEVEL.DA_SCORM
    || learningObjectTypology === EXTERNAL_LEVEL.DA_XAPI;

  const isFromYoutube = learningObjectTypology === EXTERNAL_LEVEL.DA_YOUTUBE;


  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const uri = `${window.location.origin}/esplora/scorm/${id}/${learningObjectTypology}`;
  const params = `courseId=${courseId ?? null}&rootId=${parentId ?? null}`;
 

  return (
    <VideoStack
      $isExternal = { isExternal }
      height={ isExternal ? height : isMobile ? "100%" : height }
      width={ width }
      { ...props }
    >
      {
        (isExternal || !Number(videoId)) ? (
          <StyledCoverLoader
            minHeight={ "750px" }
            position="relative"
            $cover={ externalIsOpen ? undefined : coverFallBack }
            $videoVisible={ Boolean(externalIsOpen) }
            sx={ {
              [`${theme.breakpoints.down("sm")}`]:{
                minHeight:"450px"
              }
            } }
          >
            <GridMain
              margin={ "0 auto" }
              minHeight={ "750px" }
              position={ "absolute" }
              sx={ { inset:0 } }
            >
              <StyledButtonBookmark
                disableRipple
                $isFromYoutube={ isFromYoutube }
                onClick={ () => { showTabs() } }
                $selected={ buttonSelected }
                sx={ { "&:hover": { border:"none" } } }
              >
                <div></div>
              </StyledButtonBookmark>
              {
                status === STATUS_LO.C ? (
                  <StyledStatusBoxPlayer>
                    <StatusBox
                      accent={ theme.customColors.systemSecondary02 }
                      icon={ "completato" }
                    />
                  </StyledStatusBoxPlayer>
                ) : null
              }
              <StyledButtonLink
                onClick={ ()=> { navigate(-1) } }
                sx={ { padding:0 } }
              >
                <StyledIcon
                  icon={ "arrow_left" }
                  height={ "20px" }
                  width={ "16px" }
                />
                { t("back") }
              </StyledButtonLink> 
              {
                canProceed ? (
                  <StyledButtonLink
                    onClick={ invalidateQueryStartOrResume }
                    sx={ { padding: 0 } }
                  >
                    <StyledIcon
                      icon={ "arrow_right" }
                      height={ "20px" }
                      width={ "16px" }
                    />
                    { t("explore_forward") }
                  </StyledButtonLink>
                ) : null
              }
              {
                !externalIsOpen ? (
                  <VideoCaptionOverlay
                    courseDetails={ courseDetails }
                    learningObjectType={ learningObjectType }
                    learningObjectTypology={ learningObjectTypology }
                    moduleNumber={ moduleNumber }
                    rootDetails={ rootDetails }
                    title={ title }
                  />
                ) : null
              }
              <CtaOverlay
                id={ id }
                isFavourite={ isFavourite }
                learningObjectTypology={ learningObjectTypology }
                rating={ rating }
                share={ true }
              />
              {
                !externalIsOpen ? (
                  <Box
                    className={ "icon-play" }
                    sx={ { pointerEvents: "auto" } }
                    onClick={ ()=> { 
                      if(isExternal){
                        window.open(`${uri}?${params}`, "_blank", "noreferrer");
                      }
                      else if(isFromYoutube){
                        setExternalIsOpen(learningObjectTypology);
                      }
                 
                    } }
                  >
                    <Icon icon={ "Icons_play" } size={ 25 }/>
                  </Box>
                ) : null
              }

            </GridMain>

            {
              externalIsOpen && isFromYoutube ? 
                <ScormWrapper
                  activityId={ id }
                  // close={ () => setExternalIsOpen(null) }
                  courseId={ courseId }
                  // fullScreen={ externalIsOpen !== EXTERNAL_LEVEL.DA_YOUTUBE }
                  isOpen={ Boolean(externalIsOpen) }
                  learningObjectTypology={ learningObjectTypology }
                  parentId={ parentId }
                /> : null
            }
        
          </StyledCoverLoader> 
        ) : (
          <StyledPlayerLoader
            autoplay={ autoplay }
            borderRadius={ borderRadius?.toString() }
            canPlayBack={ status !== STATUS_LO.C && isMandatory }
            canProceed={ setCanProceed }
            courseId={ courseId }
            coverFallBack={ coverFallBack }
            id={ id }
            onEndAction={ ()=> { isStandAlone || invalidateQueryStartOrResume() } }
            parentId={ parentId }
            position="relative"
            shortDescription={ shortDescription }
            title={ title }
            triggerPause={ triggerPausePlayer }
            videoId={ videoId }
            tentativeId={ tentativeId }
            // $cover={ coverFallBack }
          >
            {
              <GridMain
                margin={ "0 auto" }
                height={ "100%" }
                position={ "absolute" }
                sx={ { inset:0 } }
              >
                {
                  status === "C" ? (
                    <StyledStatusBoxPlayer>
                      <StatusBox
                        accent={ theme.customColors.systemSecondary02 }
                        icon={ "completato" }
                      />
                    </StyledStatusBoxPlayer>
                  ) : null
                }
                <StyledButtonBookmark
                  disableRipple
                  $isFromYoutube={ false }
                  onClick={ () => { showTabs() } }
                  $selected={ buttonSelected }
                  sx={ { "&:hover": { border:"none" } } }
                >
                  <div></div>
                </StyledButtonBookmark>
                <StyledButtonLink
                  onClick={ ()=> {
                    setTriggerPausePlayer(true);
                    navigate(-1);
                  } }
                  sx={ { padding: 0 } }
                >
                  <StyledIcon
                    icon={ "arrow_left" }
                    width={ "16px" }
                    height={ "20px" }
                  />
                  { t("back") }
                </StyledButtonLink>
                <VideoCaptionOverlay
                  courseDetails={ courseDetails }
                  learningObjectType={ learningObjectType }
                  learningObjectTypology={ learningObjectTypology }
                  rootDetails={ rootDetails }
                  title={ title }
                />
                <CtaOverlay
                  id={ id }
                  isFavourite={ isFavourite }
                  learningObjectTypology={ learningObjectTypology }
                  rating={ rating }
                  share={ true }
                />
              </GridMain>
            }
          </StyledPlayerLoader>
        )
      }
    </VideoStack>
  );

  function showTabs() {
    const $tabsComponent = document.querySelector(".tab-component") as HTMLDivElement;
    if ($tabsComponent) {
      if (buttonSelected) {
        setButtonSelected(false);
        $tabsComponent.classList.remove("is-visible");
      } else {
        setButtonSelected(true);
        $tabsComponent.classList.add("is-visible");
      }
    }
  }
}
