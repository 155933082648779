/* eslint-disable max-len */

import { getEnvironmentVariables } from "./general";
import type {  Language } from "../types";

export function persistLanguage (item: Language){
  const { corporateDomain } = getEnvironmentVariables();
  document.cookie = `languageStored=${JSON.stringify(item)}; domain=${corporateDomain}; path=/`;
}

export function getLanguage() {
  const itemStored = document.cookie
    .split("; ")
    .find((row) => row.startsWith("languageStored="))
    ?.split("=")[1];

  if (itemStored) {
    return JSON.parse(itemStored) as Language;
  } else {
    return { id: "it", label: "Italian" } as Language;
  }
}
