
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  StyledCardGoals,
  StyledGridBadges
} from "./CardGoals.styles";
import {
  BadgeGoals,
  ButtonLink,
  Link
} from "../../components";
import { NAVBAR_ITEMS } from "../../consts";
import { useAchievementsQuery } from "../../queries";
import { useAuthStore, useNavbarStore } from "../../stores";

export function CardGoals() {
  const MAX_ITEMS = 6;

  const theme = useTheme();
  const { t } = useTranslation();
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  const {
    data: userAchievements
  } = useAchievementsQuery({});

  return smartConfiguration?.widgetBadge === true && userAchievements && userAchievements?.length > 0 ? (
    <StyledCardGoals>
      {
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          position="relative"
        >
          <Typography
            fontWeight={ "700" }
            sx={ {
              maxWidth:"100%",
              overflowWrap:"break-word"
            } }
          >
            { t("your_goals") }
          </Typography>
          {
            ( userAchievements && userAchievements.length > MAX_ITEMS) ? (
              <Link href="/profile" 
                onClick={ ()=> { setNavbarItem(NAVBAR_ITEMS.NONE) } }
              >
                <ButtonLink
                  disableRipple
                  sx={ {
                    cursor:"pointer",
                    fontSize:"1rem",
                    fontWeight:"700",
                    padding:0,
                    textDecoration:"underline",
                    zIndex:"2",

                    "&:hover": {
                      background:"none",
                      boxShadow:"none"
                    },

                    [theme.breakpoints.down("sm")]: {
                      fontSize:"0.75rem"
                    }
                  } }
                >
                  { t("view_all") }
                </ButtonLink>
              </Link>
            ) : null
          }
        </Stack>
      }
      <StyledGridBadges>
        { userAchievements?.slice(0,6).map((item, index)=> (
          <BadgeGoals 
            key={ index.toString() }
            small={ true }
            badge={ item.badge }
            certificate={ item.certificate }
            
          />
        )) }
      </StyledGridBadges>
    </StyledCardGoals>
  ) : null;
}
