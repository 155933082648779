import {
  Grid,
  Modal,
  Stack,
  Typography,
  styled
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { t } from "i18next";
import { useState } from "react";
import {
  ButtonText,
  Icon
} from "..";
import { useLanguageStore } from "../../stores";
import type { Language } from "../../types";

const StyledModalContent = styled(Grid)(({ theme }) => ({
  alignItems:"center",
  background: theme.linearGradients.gradientB ,
  borderRadius:theme.spacing(0.5),
  display:"flex",
  flexDirection:"column",
  gap:theme.spacing(2),
  height:"fit-content",
  margin:"auto",
  maxHeight:"400px",
  maxWidth:"670px",
  padding:theme.spacing(3,5),
  width:"100%",

  ".icon":{
    cursor:"pointer"
  },

  [`${theme.breakpoints.down("sm")}`]:{
    padding:theme.spacing(2.5)
  }
}));
  
export function LanguageModal({
  close,
  languages,
  open=false
} : {
  close: () => void
  languages: Language[]
  open: boolean
}) {
  const theme = useTheme();

  const language = useLanguageStore(state => state.language);
  const setLanguage = useLanguageStore(state => state.setLanguage);

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(language);


  return (
    <Modal open={ open }
      sx={ {
        "&.MuiModal-root":{
          alignItems:"center",
          display:"flex",
          justifyContent:"center",
          zIndex:99999
        }
      } }
    >
   
      <StyledModalContent
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          width="100%"
        >
          <Icon
            color={ theme.customColors.systemPrimary02 }
            icon="close"
            onClick={ close }
            size={ 20 }
          />
        </Stack>
        <Typography
          color={ theme.customColors.textPrimary }
          fontWeight={ 700 }
          fontSize={ 32 }
        >
          { t("language_select") }
        </Typography>
        <Grid
          className="scrollable"
          container
          justifyContent="center"
          columns={ 2 }
          gap={ 2 }
          padding={ 1 }
          maxHeight={ "50%" }
        >
          {
            languages.map(({ id, label }) => (
              <Grid
                item
                key={ `lang-opt--${id}` }
                border={ `1px solid ${
                  id === selectedLanguage.id
                    ? theme.customColors.borderAccent
                    : theme.customColors.borderTag
                }`
                }
                borderRadius={ theme.spacing(1) }
                height={ "48px" }
                minWidth={ "25%" }
                padding={ theme.spacing(2) }
                boxShadow={
                  id === selectedLanguage.id
                    ? "0px 0px 8px 0px #A6FF03"
                    : undefined
                }
                sx={ {
                  cursor: "pointer"
                } }
              >
                <Typography
                  fontWeight={ 700 }
                  lineHeight={ "18px" }
                  onClick={ () => { setSelectedLanguage({ id, label }) } }
                  sx={ {
                    color: id === selectedLanguage.id
                      ? theme.customColors.borderAccent
                      : theme.customColors.borderTag
                  } }
                >
                  { label || id }
                </Typography>
              </Grid>
            ))
          }
        </Grid>
        <ButtonText
          onClick={ () => {
            setLanguage(selectedLanguage);
            close();
          } }
        >
          { t("confirm") }
        </ButtonText>
      </StyledModalContent>
 
    </Modal>
  );
}
