import { create } from "zustand";
import type { Language } from "../types";
import {
  getLanguage,
  persistLanguage
} from "../utils/language";

type LanguageState = {
  readonly language: Language
}

type LanguageActions = {
  readonly setLanguage: (language: Language) => void
}

const initialLangaugeState: LanguageState = {
  language: getLanguage()
};

export const useLanguageStore = create<LanguageState & LanguageActions>((set) => ({
  ...initialLangaugeState,
  setLanguage: (language) => {
    set({ language });
    persistLanguage(language);
  }
}));
