import {
  Stack,
  debounce,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  CarouselBox,
  HeroBox
} from "./Explore.styles";
import {
  BigCarouselCard,
  CardFilter,
  Carousel,
  CarouselCard,
  FiltersCarousel,
  GridMain,
  // Icon,
  Spinner,
  WidgetDrawer
} from "../../components";
// import { Button } from "../../components/Button/Button.component";
import {
  STATUS_LO,
  CAROUSEL_TYPE,
  PAGE_NAME,
  SERVICE_TYPES
} from "../../consts";

import {
  RelativePath,
  useContentQuery,
  useInfiniteContentQuery,
  useStructuresQuery,
  useTopicsQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import type {
  LearningObject,
  MetaData
} from "../../types";

export function Explore() {
  // const { t }=useTranslation();
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTopics, setSelectedTopics] = useState<number[]>([]);
  const { data: pageStructure 
  } = useStructuresQuery({
    pageName: PAGE_NAME.EXPLORE_OVERVIEW
  });



  const {
    data: allTopics,
    isFetching: topicsAreFetching
  } = useTopicsQuery({});
  const [topCarousel, setTopCarousel] = useState<RelativePath | null>(null);
  const [carousels, setCarousels] = useState<RelativePath[]>([]);
  
  const [carouselLenght,setCarouselLenght]=useState(0);
  const [maxCarousels,setMaxCarousels]=useState(3);
  const [isLoadingMoreCarousels,setIsLoadingMoreCarousels]=useState(false);

  const itemsRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const targetElement = containerRef.current;

  const topicsQueryString = selectedTopics.reduce(
    /* the uri arrives with ..topics= */
    (currentTopicsQuery, currentTopic, currentTopicIndex) => {
      if (currentTopicIndex == 0 && currentTopic) {
        return currentTopicsQuery + currentTopic;
      } else {
        return currentTopicsQuery + "&topics=" + currentTopic;
      }
    },
    ""
  );
  let maxItems = maxCarousels;
  let startLoadMore = carouselLenght && carouselLenght - 1 >= maxItems;
  let isLoadingMore = false;

  useEffect(() => {
    
    const newTopCarousel = pageStructure?.relativePaths.find((relativePath) => (
      relativePath.serviceType === SERVICE_TYPES.sliderTopContentExplore
    ));
    setTopCarousel(newTopCarousel ?? null);

    const newCarousels = pageStructure?.relativePaths.filter((relativePath) => (
      relativePath.serviceType !== SERVICE_TYPES.sliderTopContentExplore
    ));
    const filteredCarousels = newCarousels?.filter((c) => c.serviceType !== "carConteSpeciDurata");
    setCarouselLenght(filteredCarousels?.length ?? 0);
    setCarousels(filteredCarousels?.splice(0,maxCarousels) ?? []);
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    pageStructure
  ]);

  useEffect(()=> {

    const newCarousels = pageStructure?.relativePaths.filter((relativePath) => (
      relativePath.serviceType !== SERVICE_TYPES.sliderTopContentExplore
    ));
    const filteredCarousels = newCarousels?.filter((c) => c.serviceType !== "carConteSpeciDurata");
    setCarousels(filteredCarousels?.splice(0,maxCarousels) ?? []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[maxCarousels]);

  useEffect(()=> {
    if(targetElement && carouselLenght){
      window.addEventListener("scroll",debounce(()=>{ loadMore()},1000)); 
    }
    else {
      window.removeEventListener("scroll",()=>{ loadMore()}); 
    }
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[carouselLenght,targetElement]);

  function loadMore(){
    if( 
      targetElement && (targetElement?.getBoundingClientRect().bottom - 250 <= window.innerHeight)
    ) {

      if(startLoadMore){
        const enableLoadMore = (carouselLenght - 1 >= maxItems);
        if(enableLoadMore){
          if(!isLoadingMore){
            isLoadingMore = true;
            setIsLoadingMoreCarousels(true);
            setMaxCarousels(prev => prev + 1);
            maxItems = maxItems + 1;
            setTimeout(()=> {
              isLoadingMore = false;
            },1000);
          }
         
        }
        else {
          startLoadMore = false;
          isLoadingMore = false;
          setIsLoadingMoreCarousels(false);
        }
      
      }
      
    }
  }
  
  return (
    <GridMain >
      
      <WidgetDrawer />
      {
        topCarousel ? (
          <ExploreBigCarousel
            key={ `carousel--${topCarousel.id}` }
            relativePath={ topCarousel }
            topicsQueryString= { topicsQueryString }
          />
        ) : null
      }
      {
        topicsAreFetching ? (
          <Spinner width="100%" size={ 100 }/>
        ) : (
          <FiltersCarousel label={ "filters-carousel" }>
            {
              allTopics?.map((topic) => (
                (topic.learning_object_associated > 0) ? (
                  <CardFilter
                    key={ `explore-filter--filter-${topic.id}` }
                    coverPublicURL={ topic.url.public_url }
                    onClickhandler={ () => {
                      const currentSelectedCategories = [...selectedTopics];
                      if (selectedTopics.includes(topic.id)) {
                        currentSelectedCategories.splice(
                          currentSelectedCategories.indexOf(topic.id),
                          1
                        );
                      } else {
                        currentSelectedCategories.push(topic.id);
                        currentSelectedCategories.sort();
                      }
                      setSelectedTopics(currentSelectedCategories);
                    } }
                    title={ topic.name }
                    selected={ selectedTopics.includes(topic.id) }
                  />
                ) : null
              ))
            }
          </FiltersCarousel>
        )
      }

      {
        <Stack
          minHeight={ "calc(100vh - 550px)" }
          ref={ containerRef }
          width={ "100%" }
          gap={ isMobile ? 3 : undefined }
        >
          {
            carousels.map((carousel,index) => (
              <ExploreCarousel
                carouselLenght={ carouselLenght }
                maxItems={ maxCarousels }
                setIsLoadingMoreCarousel={ setIsLoadingMoreCarousels }
                reference={ index === carousels.length - 1 ? itemsRef : null } 
                key={ `carousel--${carousel.id}` }
                relativePath={ carousel }
                topicsQueryString= { topicsQueryString }
              />
            ))
          }
          {
            isLoadingMoreCarousels ? 
              <Spinner size={ 30 }/>
              : null
          }
        </Stack>
      }
    
    </GridMain>
  );
 
}

function ExploreBigCarousel({
  relativePath
} : {
  relativePath: RelativePath
  topicsQueryString: string
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: carouselCards,
    isFetching
  } = useContentQuery<
    & Record<string, LearningObject[]>
    & { metadata: MetaData }
  >({
    path: relativePath.apiPath,
    queryKey: [relativePath.title],

    // additional params
    topics: ""
  });

  const responseKey = carouselCards ? (Object.keys(carouselCards).find((key) => key !== "metadata") ?? "") : "";
  const carouselData = (carouselCards && responseKey) ? carouselCards[responseKey] : [];
  return isFetching ? ( 
    <Spinner
      // similar height of BigCarouselCard
      minHeight={ isMobile ? "366px" : "450px" }
      size={ 90 }
      width="100%"
    /> 
  ) : (
    (carouselCards && carouselCards.metadata?.totalElements > 0) ? (
      <HeroBox>
        <Carousel
          carouselType={ CAROUSEL_TYPE.HERO }
          label="hero-explorePage"
        >
          {
            carouselData?.map((card, index) => (
              <BigCarouselCard
                key={ `explore-hero--card-${index}` }
                coverPublicURL={ card.coverPublicURL }
                duration={ card.duration }
                editionNumber={ card?.editionNumber }
                endDate={ card?.editions?.[0].endDate }
                enrollType={ card.enrollType }
                expirationDate={ card.expirationDate }
                id={ card.id }
                isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                isHighLightEdition={ card?.editions?.[0]?.isHighlightEdition ?? false }
                labelMoreInfo={ true }
                learningObjectType={ card.learningObjectType }
                learningObjectTypology={ card.learningObjectTypology }
                percentageOfCompletion={ card.percentageOfCompletion }
                shortDescription={ card.shortDescription }
                simple={ true }
                startDate={ card?.editions?.[0].startDate  }
                status={ card.status || "N" }
                title={ card.title }
              />
            )
            ) 
          }
        </Carousel>
      </HeroBox>
    ) : null
  );
}
function ExploreCarousel({
  carouselLenght,
  maxItems,
  reference,
  relativePath,
  setIsLoadingMoreCarousel,
  topicsQueryString
} : {
  carouselLenght:number
  maxItems:number
  reference:RefObject<HTMLDivElement> | null
  relativePath: RelativePath
  setIsLoadingMoreCarousel:Dispatch<SetStateAction<boolean>>
  topicsQueryString: string
}) {
  const {
    data: carouselCards,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching
  } = useInfiniteContentQuery<
    & Record<string, LearningObject[]>
    & { metadata: MetaData }
  >({
    pageSize:4,
    path: relativePath.apiPath,
    queryKey: [relativePath.title, topicsQueryString],
    // additional params
    topics: topicsQueryString
  });
  const { t }=useTranslation();
  const title = (relativePath?.serviceType && relativePath?.serviceType !== "visCarCategory") ? 
    t(relativePath?.serviceType) : relativePath?.title;

  const responseKey = carouselCards ? 
    (Object.keys(carouselCards.pages?.[0]).find((key) => key !== "metadata") ?? "") : "";
  const carouselData = (carouselCards && responseKey) ? carouselCards?.pages?.reduce((acc, page) => {
    const contents = page?.[responseKey] ?? [];
    return acc.concat(contents);
  }, [] as LearningObject[]) : [];

  useEffect(()=> {
    if(!carouselCards || !carouselCards?.pages[0]?.metadata?.totalElements ){
      setIsLoadingMoreCarousel(Boolean(carouselLenght - 1 >= maxItems));
      window.scrollTo(window.scrollX, window.scrollY - 10);
      window.scrollTo(window.scrollX, window.scrollY + 10);

    }else {
      setIsLoadingMoreCarousel(false);
    }
  },[carouselCards, carouselLenght, maxItems, setIsLoadingMoreCarousel]);

  return (
    (Number(carouselCards?.pages[0].metadata?.totalElements) > 0) ? (
      <CarouselBox  
        ref={ reference }   
      >
        <Carousel
          carouselType={ CAROUSEL_TYPE.ZOOM }
          id={ `explore-zoom-${relativePath.title}-${relativePath.id}` }
          // label is used as unique id for url param, rehydration from url navigation, etc.
          hasNavigation={ true }
          isFetching= { isFetching && !isFetchingNextPage }
          label={ relativePath.title }
          title={ title }
          titleInset={ true }
          itemsLength={ carouselCards?.pages[0].metadata?.totalElements }
          fetchNextPage={ !isFetching && 
            hasNextPage && !isFetchingNextPage && 
            carouselData && 
           carouselData.length < 8  ? fetchNextPage : undefined }
        >
          {
            carouselData?.map((card) => (
              <CarouselCard
                booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                carouselId={ `carousel-explore-zoom-${relativePath.title.replaceAll(" ", "-")}` }
                carouselType={ CAROUSEL_TYPE.ZOOM }
                categories={ card.topicTags || [] }
                coverPublicURL={ card?.coverPublicURL?.toString() || "" }
                coverVideoPublicURL={ card.videoPublicURL }
                disabled={ false }
                duration={ card.duration }
                ecm_specialization={ card.ecm_specialization }
                ecmRegistration={ card.ecmRegistration }
                ecmType={ card.ecmType }
                editionNumber={ card.editionNumber }
                endDate={ card.ecm_specialization  && card.ecmEndDate ? 
                  [card?.ecmEndDate?.[0], card.ecmEndDate[1], card.ecmEndDate[2]] : 
                  card.editions?.[0].endDate }
                enrollType={ card.enrollType }
                expirationDate={ card.expirationDate }
                id={ card.id }
                isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                isFavourite={ card.isFavourite }
                isMandatory={ card.isMandatory }
                isSurvey={ card.isSurvey }
                isTest={ card.isTest }
                key={ `carousel-explore-zoom-${relativePath.title.replaceAll(" ", "-")}--card-${card.id}` }
                learningObjectType={ card.learningObjectType }
                learningObjectTypology={ card.learningObjectTypology  }
                percentageOfCompletion={ card.percentageOfCompletion }
                queryKey={ "mandatory" }
                shortDescription={ card.shortDescription }
                startDate={  card.ecm_specialization && card.ecmStartDate ? [
                  card.ecmStartDate[0], 
                  card.ecmStartDate[1], 
                  card.ecmStartDate[2]] :  
                  card.editions?.[0].startDate  }
                status={ card.status  || "N" }
                title={ card.title }
                // updateQueryFunction={ (method)=> {
                //   setTimeout(()=> {
                //     setMandatoryUpdate(`${method}-${card.id}`);
                //   },100);
                // } }
              />
            ))
          }
        </Carousel>
      </CarouselBox>
    ) : null
  );
}


