import {
  Stack,
  Typography,
  Box
} from "@mui/material";
import {
  alpha,
  styled , useTheme } from "@mui/material/styles";
import {
  PickersDay,
  PickersDayProps
} from "@mui/x-date-pickers";
import { useState } from "react";
import { Icon } from "../../components";
import { ButtonText } from "../../components/Button";

export const CalendarContainerStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  minHeight: "26.375rem",
  //height: "23.375rem",
  padding: theme.spacing(2, 2, 3, 2),
  position: "relative",
  width: "min(34.0625rem, 98%)",

  [theme.breakpoints.down("sm")]: {
    margin: "0",
    minHeight: "auto",
    width: "100%"
  },

  ".MuiDateCalendar-root": {
    margin: "0 0 0 auto",
    maxHeight: "100%",

    [theme.breakpoints.down("sm")]: {
      margin: "0 auto"
    }
  },

  "& .MuiDateCalendar-root .MuiPickersCalendarHeader-labelContainer": {
    "button": {
      display: "none"
    },
    "div": {
      fontSize: "1.5rem",
      fontWeight: "700",

      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.3125rem",
        textTransform: "capitalize"
      }
    }
  },
  ".MuiPickersCalendarHeader-root": {
    margin: "0",
    marginBottom: "0.65rem",
    marginLeft: "1rem",
    padding: "0",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
      maxHeight: "20px"
    }
  },

  ".MuiPickersCalendarHeader-labelContainer": {
    display: "block",
    position: "absolute",
    textAlign: "center",
    width: "100%"
  },

  ".MuiPickersCalendarHeader-label": {
    margin: 0
  },

  ".MuiPickersArrowSwitcher-root": {
    //justifyContent: "space-between",
    gap: "4rem",
    justifyContent: "space-around",
    width: "100%"
  },

  ".MuiDayCalendar-weekDayLabel": {
    color: theme.customColors.textMandatory,
    fontSize: "1rem",
    fontWeight: "700",
    height: "1.5625rem",
    // width: "1.5625rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem"
    }
  },

  // ".MuiPickersDay-hiddenDaySpacingFiller": {
  //   height: "1.5625rem",
  //   width: "1.5625rem"
  // },

  ".MuiDayCalendar-slideTransition": {
    minHeight: "21rem",

    [theme.breakpoints.down("sm")]: {
      minHeight: "auto"
    }
  },

  ".MuiYearCalendar-root": {
    width: "100%"
  },

  ".MuiDayCalendar-monthContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "0.4rem",
    padding: theme.spacing(0.8, 0, 1.5, 0),

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px"
    }
  },

  ".MuiDayCalendar-header": {
    justifyContent: "space-around",
    padding: theme.spacing(0, 1)
  },

  ".MuiDayCalendar-weekContainer": {
    justifyContent: "space-around",
    padding: theme.spacing(0, 1),

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      gap: "0",
      height: "40px",
      margin: "0",
      padding: "0"
    }
  }

  // ".MuiDayCalendar-weekContainer": {
  //   [theme.breakpoints.down("sm")]: {
  //     margin: "0"
  //   }
  // }

}));

export const CourseStack = styled(Stack)(() => ({
  // minWidth: "46%",
  overflow: "hidden",
  whiteSpace: "nowrap"
}));

export const CurrentDayOfWeekTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textMandatory,
  fontSize: "1.5rem",
  fontWeight: "400",
  lineHeight: "1.8125rem"
}));

export const CurrentDayTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "4rem",
  fontWeight: "700",
  lineHeight: "4.875rem",
  textAlign: "center"
}));

export const SelectedDayTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "2.0625rem"
}));

export const CourseTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: "0.75rem",
  padding: "0.125rem",
  whiteSpace: "nowrap"
}));

export function CalendarArrow({ icon, iconGlow } : {icon: string, iconGlow: string}) {
  const theme = useTheme();
  const [showingIcon, setShowingIcon] = useState<string>(icon);

  return <Box
    sx={ { 
      display: "flex",

      "& svg:hover":{ 
        filter: `drop-shadow(0px 0px 2px ${theme.customColors.systemPrimary02})`
      },
      
      "& path":
      {
        fill:  theme.customColors.systemPrimary02      } }
    }
  >
    <Icon icon={ showingIcon } width={ "2rem" }
      onMouseDown={ () => setTimeout(() => setShowingIcon(iconGlow), 1) }
      onTouchStart={ () => setShowingIcon(iconGlow) }
      onMouseUp={ () => setTimeout(() => setShowingIcon(icon), 1) }
      onTouchEnd={ () => setShowingIcon(icon) }/>
  </Box>;
}

export const StyledPickersDay = styled(PickersDay<Date>)<
  PickersDayProps<Date> & {
    $activityColor: string,
    $sameDay: boolean,
    $selected: boolean
  }
>(({
  $activityColor,
  $sameDay,
  $selected,
  theme
}) => ({
  aspectRatio: $selected ? "1" : undefined,
  backgroundColor: $selected ? $activityColor : "none",
  border: "2px solid transparent",

  // boxShadow: $selected && !$sameDay ? `0px 0px 8px ${activityColor}` : "none",
  color: $selected
    ? theme.palette.common?.black
    : $sameDay
      ? $activityColor
      : theme.customColors.textPrimary,
  fontSize: "1rem",
  fontWeight: "700",
  height: "2.5rem",
  width: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    height: "1.5625rem",
    lineHeight: "1.5076rem",
    width: "1.5625rem"
  },

  "&:focus": {
    backgroundColor: $selected ? $activityColor : "none"
  },

  "&:hover": {
    backgroundColor: $selected ? $activityColor : "none",
    border: `2px solid ${alpha($activityColor, 0.6)}`,
    boxShadow: `0px 0px 12px ${$activityColor}, inset 0px 0px 4px ${$activityColor}`,
    
    [`${DotSpan}`]: {
      transition: "max-height 50ms ease-in, max-width 50ms ease-out",

      maxHeight: 0,
      maxWidth: 0
    }
  },

  "&:active": {
    aspectRatio: "1",
    background: alpha($activityColor, 0.25),
    boxShadow: `0px 0px 12px ${$activityColor}, inset 0px 0px 4px ${$activityColor}`
  }
}));

export const DotSpan = styled("span")(({ theme }) => ({
  backgroundColor: theme.customColors.textDisabledAlternative,
  borderRadius: "50%",
  bottom: 0,
  position: "absolute",
  transition: "max-height 450ms ease-in, max-width 450ms ease-in",

  height: "0.5rem",
  maxHeight: "1rem",
  maxWidth: "1rem",
  width: "0.5rem",

  [theme.breakpoints.down("sm")]:{  
    bottom: "-6px",
    maxHeight: "4px",
    maxWidth: "4px"
  }
}));

export const SmartLearningButton = styled(ButtonText)(({ theme }) => ({
  backdropFilter: "blur(4px)",
  backgroundColor: theme.customColors.backgroundPrimaryLighter 
    && alpha(theme.customColors.backgroundPrimaryLighter, 0.4),
  border: `0.5px solid ${theme.customColors.borderTag}`,
  borderRadius: "8px",
  bottom: 0,
  boxShadow: "4px 4px 12px 0px #15151540",
  justifyContent: "flex-start",
  marginBottom: "24px",
  minWidth: "57px",
  overflow: "hidden",
  padding: "16px",
  position: "absolute",
  transition: "all .3s linear",
  width: "57px",

  [theme.breakpoints.down("sm")]:{ 
    marginBottom: "0",
    position: "relative"
  },

  "&:hover":{
    backgroundColor: theme.customColors.backgroundPrimaryLighter 
      && alpha(theme.customColors.backgroundPrimaryLighter, 0.4),
    width: "270.63px"
  }
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "24px",
  fontWeight: "700",
  lineHeight: "30px",
  whiteSpace: "nowrap"
}));

export const FormLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "19.36px",
  width: "51px"
}));

export const FormSubLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "14.52px"
}));

export const AvailableHoursTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "21px",
  minHeight: "21px"
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "36px",
  textAlign: "center",
  textShadow:" 0px 0px 20px #000",
  
  [theme.breakpoints.down("sm")]:{  
    fontSize: "24px",
    lineHeight: "30px",
    width: "331px"
  }
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "center",
  [theme.breakpoints.down("sm")]:{  
    fontSize: "16px",
    lineHeight: "21px"
  }
}));

export function OpenCalendarArrow({ isOpen, ...props } : { isOpen: boolean }) {

  const theme = useTheme();

  return <Box
    sx={ {
      alignSelf: "center",
      display: "flex",
      right: "16px",
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
    } }
    { ...props }
  >
    <Icon 
      icon={ "dropdown" } 
      width={ "20px" }
      color={ theme.customColors.backgroundPrimaryCta }
    />
  </Box>;
}
