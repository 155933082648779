import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { LearningObject } from "../../types";

export const PopUpTeacherBox = styled(Grid)<GridProps>(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;",
  color:theme.customColors.textPrimary,
  display:"flex",
  gap:"1rem",
  maxWidth:"660px",
  padding: "40px 32px",

  ".icon":{
    cursor:"pointer",
    margin:"0 0 0 auto",
    "path":{
      fill:theme.palette.primary?.main
    }
  },

  ".MuiSvgIcon-root ": {
    color:theme.palette.primary?.main,
    cursor:"pointer",
    margin: "0 0 0 auto"
  },

  [theme.breakpoints.down("sm")]:{
    gap:"0.5rem",
    padding: "16px 20px",

    ".icon":{
      margin:"0 0 0 auto"
    }
  }
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 400,
  lineHeight: 1.3125,

  [theme.breakpoints.down("sm")]:{
    fontSize: "0.75rem"
  }
}));

export const ImageBox = styled(Box)<
  BoxProps & Pick<LearningObject, "coverPublicURL">
  >(({ coverPublicURL, theme }) => ({
    background: coverPublicURL ? undefined : theme.customColors.backgroundSecondary,
    borderRadius:"8px",
    display: "flex",
    marginBottom:"23px",
    position:"relative",

    height: "336px",
    width:"100%",

    "&::after": {
      background: theme.linearGradients.gradientC,
      borderRadius:"8px",
      content: "''",
      position: "absolute",

      bottom: 0,
      left:0,
      right: 0,
      top:0,

      height:"100%",
      width:"100%"
    },

    "img": {
      borderRadius:"8px",
      objectFit: "cover",
      objectPosition: "center center",
      position: "relative",

      height:"100%",
      width:"100%"
    },

    [theme.breakpoints.down("sm")]:{
      height: "195px",
      marginBottom:"0"
    }
  }));

export const TextContent = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:"1rem",

  [theme.breakpoints.down("sm")]:{
    gap: "0.5rem"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.21875,
  textTransform: "capitalize",

  [theme.breakpoints.down("sm")]:{
    fontSize: "1rem"
  }
}));
