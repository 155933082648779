import { CustomRegex } from "./regex";
import {
  ENROLL_TYPE,
  LEVEL
} from "../consts";
import type {
  Level,
  TimeSlot,
  enrollType
} from "../types";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const throttle = (
  func: (...args: any) => void,
  delay = 500
) => {
  let lastCall = 0;
  return (...args: any) => {
    const now = new Date().getTime(); 
    if (now - lastCall < delay) { 
      return; 
    } 
    lastCall = now; 
    func(...args); 
  };
};
/* eslint-enable @typescript-eslint/no-explicit-any */

// TODO: handle with useTranslation


export function getDetailsPath (learningObjectTypology?: Level) {
  switch (learningObjectTypology) {
  case LEVEL.COURSE:
    return {
      path: "COURSE",
      type: "courses"
    };
  case LEVEL.PATH:
    return {
      path: "ROOT",
      type: "roots"
    };
  default:
    return {
      path: "ACTIVITY",
      type: "activity"
    };
  }
}

export function getLearningObjectType (learningObjectTypology: Level) {
  switch (learningObjectTypology) {
  case LEVEL.COURSE:
  case LEVEL.PATH:
    return learningObjectTypology?.toLowerCase();
  default :
    return "activity";
  }
}

export function getEnrollmentType (enrollmentype: enrollType) {
  switch (enrollmentype) {
  case  ENROLL_TYPE.TOP_DOWN:
    return "TOP";
  case ENROLL_TYPE.REQUESTED_AUTO_ENROLL:
    return "BOTTOM";
  default :
    return "AUTO";
  }
}

export function addParams(url:string, tags: Record<string, string>) {
  return Object.entries(tags).reduce((acc, [param, value]) => (
    acc?.replace(param, value)
  ), url);
}

export function parseJwt (token: string) {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function(c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));
    return JSON.parse(jsonPayload);
  } catch(error) {
    // eslint-disable-next-line no-console
    console.error("Could not parse JWT token", { error, token });
  }
}

/**
 * interpolate page structure paths with parameter values, the replace method
 * is safe in regard to parameters which can't be found in the path
 * @param path the page structure path
 * @param params a map of api parameter names to parameters values
 * @returns the interpolated api path
 *
 * ex.
 * /learning-catalogue/{corporateId}/{initiativeId}/learnerFavouriteLearningObjects
 * ?pageNumber={pageNumber}&pageSize={pageSize}
 */
export function replacePathParams(path: string, params: Record<string, string>) {
  const newPath = CustomRegex.replaceArrayQueryParams(path);
  if (!newPath) return "";
  return Object.entries(params)
    .reduce((acc, [paramName, paramValue]) => (
      acc?.replace(
        `{${paramName}}`,
        Array.isArray(paramValue)
          ? paramValue.sort().join(",")
          : paramValue ?? ""
      )
    ), newPath);
}

export function getEnvironmentVariables() {
  const environmentVariables = import.meta.env;

  const adminLink = environmentVariables.VITE_APP_ADMIN_LINK as string;
  const appName = environmentVariables.VITE_APP_NAME as string;
  const analytics = environmentVariables.VITE_ANALYTICS as string;
  const basePath = environmentVariables.VITE_APP_BASE_URL as string;
  const brightcoveAccountId= environmentVariables.VITE_BRIGHTCOVE_ACCOUNT_ID as string;
  const brightcovePlayerId= environmentVariables.VITE_BRIGHTCOVE_PLAYER_ID as string;
  const clientId = environmentVariables.VITE_CLIENT_ID as string;
  const corporateDomain = environmentVariables.VITE_CORPORATE_DOMAIN as string;
  const corporateLanguage = environmentVariables.VITE_CORPORATE_LANGUAGE as string;
  const digitedLogo = environmentVariables.VITE_APP_LOGO_RGB as string;
  const digitedLogoFooter = environmentVariables.VITE_APP_LOGO_FOOTER as string;
  const environment = environmentVariables.VITE_APP_NODE_ENV as string;
  const favicon = environmentVariables.VITE_APP_FAVICON as string;
  const learnerLink = environmentVariables.VITE_APP_LEARNER_LINK as string;
  const scormJs = environmentVariables.VITE_SCORM_JS as string;
  const scormTracker = environmentVariables.VITE_SCORM_TRACKER as string;
  const ssoBaseUrl = environmentVariables.VITE_SSO_BASE_URL as string;
  const ssoClientId = environmentVariables.VITE_SSO_CLIENT_ID as string;
  const ssoRedirectUri = environmentVariables.VITE_SSO_REDIRECT_URI as string;
  const ssoUserPoolId = environmentVariables.VITE_SSO_USER_POOL_ID as string;
  const ssoUserPoolName = environmentVariables.VITE_SSO_USER_POOL_NAME as string;
  const userPoolId = environmentVariables.VITE_USER_POOL_ID as string;
  const userPoolName = environmentVariables.VITE_USER_POOL_NAME as string;
  const xapiTracker = environmentVariables.VITE_XAPI_TRACKER as string;
  const xapiChatBot = environmentVariables.VITE_CHATBOT_XAPI_KEY as string;
  const aryannaUri = environmentVariables.VITE_ARYANNA_URI as string;

  return {
    adminLink,
    analytics,
    appName,
    aryannaUri,
    basePath,
    brightcoveAccountId,
    brightcovePlayerId,
    clientId,
    corporateDomain,
    corporateLanguage,
    digitedLogo,
    digitedLogoFooter,
    environment,
    favicon,
    learnerLink,
    scormJs,
    scormTracker,
    ssoBaseUrl,
    ssoClientId,
    ssoRedirectUri,
    ssoUserPoolId,
    ssoUserPoolName,
    userPoolId,
    userPoolName,
    xapiChatBot,
    xapiTracker
  };
}

export function timeSlotToLabels(timeSlot: TimeSlot) {
  switch(timeSlot) {
  case "NO_PREFERENCE" as TimeSlot:
    return ["Nessuna preferenza", ""];
  case "MORNING" as TimeSlot:
    return ["9 - 13", "Mattina"];
  case "EVENING" as TimeSlot:
    return ["13 - 18", "Pomeriggio"];
  case "NIGHT" as TimeSlot:
    return ["18 - 22", "Sera"];
  default:
    throw new Error();
  }
}
export function addEscapeString(string:string){
  if(string){
    return string.replace(/[\\"']/g, "\\$&")?.replace("/\u0000/g", "\\0");
  }
  else {
    return "";
  }
}
export function getCookieByName(name:string){
  const value = `; ${document.cookie}`;
  const parts = value?.split(`; ${name}=`) ?? [];
  if ( parts && parts.length === 2) {
    return parts?.pop()?.split(";").shift();
  }
}
export function addTimeToCurrentDate(time:number){
  let timeObject = new Date();
  const milliseconds = time * 1000; // 10 seconds = 10000 milliseconds
  timeObject = new Date(timeObject.getTime() + milliseconds);
  return timeObject.toUTCString();
}
export function getLastPath(){
  const pathArray = window.location.origin.split(".");
  const pathArrayLenght = pathArray.length;
  return `${pathArray[pathArrayLenght -2]}.${pathArray[pathArrayLenght -1]}`;
}


