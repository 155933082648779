
import {
  useInfiniteQuery,
  type QueryKey,
  useQuery,
  useMutation,
  useQueryClient
} from "@tanstack/react-query";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import type {
  CommentResponseType,
  CommunityResponseType,
  DiscussionResponseType,
  LikeResponseType,
  SharedPost,
  TagResponseType
} from "./community.types";
import {
  ERRORS,
  POST_TYPE
} from "../consts";
import { fetcherFactory } from "../services";

import { useAuthStore } from "../stores";
import { secondsTo8061Duration } from "../utils/datetime";
import { getEnvironmentVariables } from "../utils/general";

const {
  basePath,
  xapiTracker
} = getEnvironmentVariables();

const cmswrapperApiPath = "/cmswrapper";
const addPostApiPath = "/api/v1/fe_learner/post";

export const useInfiniteFeedQuery = ({
  enabled=true,
  itemsPerPage=10,
  postType,
  sharedId,
  topics
} : {
  enabled?: boolean
  itemsPerPage?: number
  postType?:string | null
  topics: string[]
  sharedId?: string
}) => {
  const fetcher = fetcherFactory();

  itemsPerPage;
  topics;
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const isEnabled = Boolean(
    enabled &&
      corporateId &&
      initiativeId &&
      idToken &&
      sessionToken
  );

  let queryKeyString = "useInfiniteFeedQuery";

  if(topics.length > 0){
    queryKeyString += topics?.join(",");
  }
  if(postType){
    queryKeyString += postType;
  }
  if(!postType){
    queryKeyString === queryKeyString;
  }

  return useInfiniteQuery<
      unknown,
      unknown,
      CommunityResponseType,
      QueryKey
    >({
      enabled: isEnabled,
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length;
        const totalPages = (lastPage as CommunityResponseType)?.pager.total_pages ?? 0;
        return (nextPage < totalPages) ? { nextPage } : undefined;
      },
      queryFn: ({ pageParam }) => {
        const uri = "/api/v1/fe_learner/community";
        let params = `page=${pageParam?.nextPage ?? 0}&page_size=${itemsPerPage}&lang=it`;
        if (sharedId) {
          params += `&shared_id=${sharedId}`;
        }
        if (topics.length > 0) {
          params += `&topic=${topics.join(",")}`;
        }
        if(postType){
          params += `&content_type=${postType}`;
        }

        const endpoint = `${uri}?${params}`;
        return fetcher(
          `${basePath}${cmswrapperApiPath}`,
          {
            body: JSON.stringify(
              {
                "body": null,
                "endpoint": endpoint,
                "method": "GET",
                "x-initiative-id": initiativeId
              }
            ),
            headers:{
              "authorization": idToken,
              "Content-Type": "application/json",
              "x-ada-session-token": sessionToken
            },
            method: "POST"
          }
        )
          .then((res) => {
            return res.json();
          })
          .catch((error) => {
            if (error === ERRORS.UNAUTHORIZED) {
              setUnauthorized(true);
              throw error; // rethrow so that react query doesn't complain about undefined return value
            } else {
              throw error;
            }
          });
      },
      queryKey: [queryKeyString ]
    });
};

export const useInfiniteCommentQuery = ({
  enabled=true,
  itemsPerPage=3,
  discussionId
} : {
      enabled?: boolean,
      itemsPerPage?: number,
      discussionId: string
    }) => {
  const fetcher = fetcherFactory();

  itemsPerPage;
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isEnabled = Boolean(
    enabled &&
        corporateId &&
        idToken &&
        initiativeId &&
        discussionId &&
        sessionToken
  );

  return useInfiniteQuery<
        unknown,
        unknown,
        CommentResponseType,
        QueryKey
      >({
        enabled: isEnabled,
        getNextPageParam: (lastPage, pages) => {
          const nextPage = pages.length;
          const totalPages = (lastPage as CommentResponseType)?.pager.total_pages ?? 0;
          return (nextPage < totalPages) ? { nextPage } : undefined;
        },
        queryFn: ({ pageParam }) => fetcher(
          `${basePath}${cmswrapperApiPath}`,
          {
            body: JSON.stringify(
              {
                "body": null,
                // eslint-disable-next-line max-len
                "endpoint": `/api/v1/fe_learner/comment?entity_id=${discussionId}&page=${pageParam?.nextPage ?? 0}&page_size=${itemsPerPage}`,
                "method": "GET",
                "x-initiative-id": initiativeId
              }
            ),
            headers:{
              "authorization": idToken,
              "Content-Type": "application/json",
              "x-ada-session-token": sessionToken

            },
            method: "POST"
          }

        )
          .then((res) => {
            return res.json();
          })
          .catch((error) => {
            if (error === ERRORS.UNAUTHORIZED) {
              setUnauthorized(true);
              throw error; // rethrow so that react query doesn't complain about undefined return value
            } else {
              throw error;
            }
          }),
        queryKey: ["useInfiniteCommentQuery", discussionId]
      });
};

export function useCommentPostQuery({
  body,
  discussionId,
  selectedFilter,
  selectedTopic
}: {
  body: string
  discussionId: string
  selectedFilter:string | null
  selectedTopic:string | null
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": {
              body: body,
              owner_id: userId
            },
            "endpoint": `/api/v1/fe_learner/comment?entity_id=${discussionId}`,
            "method": "POST",
            "x-initiative-id": initiativeId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      let queryFeedinvalidate = "useInfiniteFeedQuery";
      if(selectedFilter){
        queryFeedinvalidate += selectedFilter;
      }
      if(selectedTopic){
        queryFeedinvalidate += selectedTopic;
      }
    
      queryClient.invalidateQueries({ queryKey: ["useInfiniteCommentQuery", discussionId] });
      queryClient.invalidateQueries({ queryKey: ["useTopicQuery" , discussionId ] });
      queryClient.invalidateQueries({ queryKey: [queryFeedinvalidate] });
    }
  });
}

export function useCommentPutQuery() {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      body,
      commentId
    }: {
      body: string
      commentId: string
      discussionId: string
      parentCommentId?: string
    }) => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": {
              body: body
            },
            "endpoint": `/api/v1/fe_learner/comment?comment_id=${commentId}`,
            "method": "PUT",
            "x-initiative-id": initiativeId,
            "x-user-id": userId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: (data, { discussionId, parentCommentId }) => {
      queryClient.invalidateQueries({ queryKey: ["useInfiniteCommentQuery", discussionId] });
      if(parentCommentId) {
        queryClient.invalidateQueries({ queryKey: ["useInfiniteReplyQuery", parentCommentId] });
      }
    }
  });
}

export function useCommentDeleteQuery({
  commentId,
  discussionId,
  parentCommentId,
  selectedFilter,
  selectedTopic
}: {
  commentId: string
  discussionId: string
  parentCommentId?: string
  selectedFilter:string | null,
  selectedTopic:string | null
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": null,
            "endpoint": `/api/v1/fe_learner/comment?comment_id=${commentId}`,
            "method": "DELETE",
            "x-initiative-id": initiativeId,
            "x-user-id": userId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),

    onSuccess: () => {
      let queryFeedinvalidate = "useInfiniteFeedQuery";
      if(selectedFilter){
        queryFeedinvalidate += selectedFilter;
      }
      if(selectedTopic){
        queryFeedinvalidate += selectedTopic;
      }
      queryClient.invalidateQueries({ queryKey: ["useInfiniteCommentQuery", discussionId] });
      queryClient.invalidateQueries({ queryKey: ["useTopicQuery" , discussionId ] });
      queryClient.invalidateQueries({ queryKey: [queryFeedinvalidate] });

      if(parentCommentId) {
        queryClient.invalidateQueries({ queryKey: ["useInfiniteReplyQuery", parentCommentId] });
      }
    }
  });
}

export function useReplyPostQuery() {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      body,
      commentId
    }: {
      body: string
      commentId: string
      discussionId: string
    }) => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": {
              body: body,
              owner_id: userId
            },
            "endpoint": `/api/v1/fe_learner/comment?comment_id=${commentId}`,
            "method": "POST",
            "x-initiative-id": initiativeId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: (data, { commentId, discussionId }) => {
      queryClient.invalidateQueries({ queryKey: ["useInfiniteReplyQuery", commentId] });
      queryClient.invalidateQueries({ queryKey: ["useInfiniteCommentQuery", discussionId] });
      queryClient.invalidateQueries({ queryKey: ["useTopicQuery" , discussionId ] });
    }
  });
}

export const useInfiniteReplyQuery = ({
  enabled=true,
  itemsPerPage=10,
  commentId
} : {
  enabled?: boolean,
  itemsPerPage?: number,
  commentId: string
}) => {

  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  return useInfiniteQuery<
        unknown,
        unknown,
        CommentResponseType,
        QueryKey
      >({
        enabled: Boolean(
          enabled
          && idToken
          && sessionToken
          && corporateId
          && initiativeId
          && commentId
        ),
        getNextPageParam: (lastPage, pages) => {
          const nextPage = pages.length;
          const totalPages = (lastPage as CommentResponseType)?.pager.total_pages ?? 0;
          return (nextPage < totalPages) ? { nextPage } : undefined;
        },
        queryFn: ({ pageParam }) => fetcher(
          `${basePath}${cmswrapperApiPath}`,
          {
            body: JSON.stringify(
              {
                "body": null,
                // eslint-disable-next-line max-len
                "endpoint": `/api/v1/fe_learner/comment?comment_id=${commentId}&page=${pageParam?.nextPage ?? 0}&page_size=${itemsPerPage}`,
                "method": "GET",
                "x-initiative-id": initiativeId
              }
            ),
            headers:{
              "authorization": idToken,
              "Content-Type": "application/json",
              "x-ada-session-token": sessionToken
            },
            method: "POST"
          }
        )
          .then((res) => {
            return res.json();
          })
          .catch((error) => {
            if (error === ERRORS.UNAUTHORIZED) {
              setUnauthorized(true);
              throw error; // rethrow so that react query doesn't complain about undefined return value
            } else {
              throw error;
            }
          }),
        queryKey: ["useInfiniteReplyQuery", commentId]
      });
};

export function useLikeQuery({
  enabled=true,
  entityId,
  userId
}: {
  enabled?: boolean,
  entityId: string,
  userId: string
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  return useQuery<
    unknown,
    unknown,
    LikeResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && sessionToken
      && corporateId
      && idToken
      && initiativeId
      && entityId
      && userId
    ),
    queryFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": null,
            "endpoint": `/api/v1/fe_learner/likes?type=node&entity_id=${entityId}`,
            "method": "GET",
            "x-initiative-id": initiativeId,
            "x-user-id": userId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: ["useLikeQuery", entityId, userId]
  });
}

export function useLikePostQuery({
  entityId,
  selectedFilter,
  selectedTopic
}: {
  entityId: string,
  selectedFilter:string | null,
  selectedTopic:string | null
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": {
              entity_id: entityId,
              type: "node",
              user_id: userId
            },
            "endpoint": "/api/v1/fe_learner/likes",
            "method": "POST",
            "x-initiative-id": initiativeId
            
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      let queryToinvalidate = "useInfiniteFeedQuery";
      if(selectedFilter){
        queryToinvalidate += selectedFilter;
      }
      if(selectedTopic){

        queryToinvalidate += selectedTopic;
      }
      queryClient.invalidateQueries({ queryKey: ["useLikeQuery" , entityId, userId.toString()] });
      queryClient.invalidateQueries({ queryKey: ["useTopicQuery" , entityId ] });
      queryClient.invalidateQueries({ queryKey: [queryToinvalidate] });
    }
  });
}

export function usePublishPostQuery({
  description,
  langCode="it",
  postType,
  selectedFilter,
  selectedTopic,
  ...props
} :
  & {
    description?: string
    langCode?: "en" | "it"
    selectedFilter:string | null,
    selectedTopic:string | null,
  }
  & (
    | {
      linkLabel?: string
      linkValue?: string
      postType: "FREE"
    }
    | {
      loAuthor: string
      loId: string
      loType: string
      postType: "SHARED"
    }
  )
) {
  const queryClient = useQueryClient();
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const authorization = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";

  const body =
    postType === POST_TYPE.FREE ? {
      "attachments": {
        "link": ("linkValue" in props && "linkLabel" in props && props.linkValue)
          ? [ { "content": props.linkValue, "name": props.linkLabel } ]
          : []
      },
      "body": description,
      "langcode": langCode,
      "post_type": postType.toLowerCase()
    } : {
      "body": description,
      "langcode": langCode,
      "lo_author": ("loAuthor" in props && props.loAuthor) ? props.loAuthor : "",
      "lo_id": ("loId" in props && props.loId) ? props.loId : "",
      "lo_type": ("loType" in props && props.loType) ? props.loType : "",
      "post_type": postType.toLowerCase()
    };
  
  const invalidateUriShared = ("loId" in props && props.loId) ? `${basePath}/sharedPost/${props.loId}` : "";
  const invalidateParamsShared = `initiativeId=${initiativeId}&corporateId=${corporateId}`;

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify({
          "body": body,
          "endpoint": `${addPostApiPath}`,
          "method": "POST",
          "user-id": userId.toString(),
          "x-initiative-id": initiativeId.toString()
        }),
        headers: {
          "Authorization": authorization,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
     
      let queryToinvalidate = "useInfiniteFeedQuery";

      if(selectedFilter){
        queryToinvalidate += selectedFilter;
      }
      if(selectedTopic){
        queryToinvalidate += selectedTopic;
      }
      if (postType === POST_TYPE.FREE) {

        queryClient.invalidateQueries({ queryKey: [
          queryToinvalidate
        ] });
      } else if (postType === POST_TYPE.SHARED) {
        queryClient.invalidateQueries({ queryKey: [
          queryToinvalidate
        ] });
        queryClient.invalidateQueries({ queryKey: [
          sessionToken, 
          invalidateUriShared, 
          invalidateParamsShared
        ] });
      }
    }
  });
}

export function useModifyPostQuery({
  description,
  id,
  langCode="it",
  postType,
  selectedFilter,
  selectedTopic,
  ...props
} :
  & {
    description?: string
    id: string
    langCode?: "en" | "it",
    selectedTopic:string | null,
    selectedFilter:string | null
  }
  & (
    | {
      linkLabel?: string
      linkValue?: string
      postType: "FREE"
    }
    | {
      loId:string
      postType: "SHARED"
    }
  )
) {
  const queryClient = useQueryClient();
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const authorization = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userId = useAuthStore(state => state.userData?.id_user) ?? "";

  const body =
    postType === POST_TYPE.FREE ? {
      "attachments": {
        "link": ("linkValue" in props && props.linkValue && "linkLabel" in props)
          ? [ { "content": props.linkValue, "name": props.linkLabel } ]
          : []
      },
      "body": description,
      "langcode": langCode,
      "post_type": postType.toLowerCase()
    } : {
      "body": description,
      "langcode": langCode,
      "post_type": postType.toLowerCase()
    };

  const invalidateUriShared = ("loId" in props && props.loId) ? `${basePath}/sharedPost/${props.loId}` : "";
  const invalidateParamsShared = `initiativeId=${initiativeId}&corporateId=${corporateId}`;

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify({
          "body":body,
          "endpoint": `/api/v1/fe_learner/post?post_id=${id}`,
          "method": "PUT",
          "user-id": userId.toString(),
          "x-initiative-id": initiativeId.toString()
        }),
        headers:{
          "Authorization":authorization,
          "Content-Type": "application/json",
          "x-ada-session-token":sessionToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      let queryToinvalidate = "useInfiniteFeedQuery";
      let queryToinvalidateShare =  sessionToken + invalidateUriShared + invalidateParamsShared;


      if(selectedFilter){
        queryToinvalidateShare += selectedFilter;
        queryToinvalidate += selectedFilter;
      }
      if(selectedTopic){
        queryToinvalidateShare += selectedTopic;
        queryToinvalidate += selectedTopic;
      }
      if (postType === POST_TYPE.FREE) {
        queryClient.invalidateQueries({ queryKey: [
          queryToinvalidate
        ] });
      } else if (postType === POST_TYPE.SHARED) {
        queryClient.invalidateQueries({ queryKey: [
          queryToinvalidate
        ] });
        queryClient.invalidateQueries({ queryKey: [
          queryToinvalidateShare
        ] });
        queryClient.invalidateQueries({ queryKey: [
          sessionToken, invalidateUriShared, invalidateParamsShared
        ] });
        
      }
    }
  });
}

export function useLikeDeleteQuery({
  entityId,
  selectedFilter,
  selectedTopic
}: {
  entityId: string,
  selectedFilter:string | null,
  selectedTopic:string | null
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;


  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": null,
            "endpoint": `/api/v1/fe_learner/likes?type=node&entity_id=${entityId}`,
            "method": "DELETE",
            "x-initiative-id": initiativeId,
            "x-user-id": userId

          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      let queryToinvalidate = "useInfiniteFeedQuery";
      if(selectedFilter){
        queryToinvalidate += selectedFilter;
      }
      if(selectedTopic){
        queryToinvalidate += selectedTopic;
      }
      queryClient.invalidateQueries({ queryKey: ["useLikeQuery" , entityId, userId.toString()] });
      queryClient.invalidateQueries({ queryKey: ["useTopicQuery" , entityId ] });
      queryClient.invalidateQueries({ queryKey: [queryToinvalidate] });
    }
  });
}

export function useUploadFileQuery() {
  return useMutation({
    mutationFn: (file: File) => {  //fetcher goes here
      const body = new FormData();
      body.append("file", file, file.name);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(JSON.stringify({ id: 123 }));
        }, 2000);
      });
    }
  });
}

export function useDeleteFileQuery() {
  return useMutation({
    mutationFn: (fileId: number) => {  //fetcher goes here
      const body = { fileId: fileId };
      JSON.stringify(body);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(JSON.stringify({ result: "success" }));
        }, 2000);
      });
    }
  });
}

export function useSharedPosts({
  enabled,
  loId
} : {
  enabled?: boolean
  loId: number
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const uri = `${basePath}/sharedPost/${loId}`;
  const params = `initiativeId=${initiativeId}&corporateId=${corporateId}`;

  return useQuery<
    unknown,
    unknown,
    SharedPost,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && loId
    ),
    queryFn: () => fetcher(
      `${uri}?${params}`,
      {
        headers:{
          "authorization": accessToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken
        }
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: SharedPost) => {
        return data;
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [sessionToken, uri, params]
  });
}

export const useInfiniteSharedQuery = ({
  loId,
  enabled=true,
  itemsPerPage=10
} : {
   loId:number,
    enabled?: boolean,
    itemsPerPage?: number
  }) => {
  const fetcher = fetcherFactory();

  itemsPerPage;

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const isEnabled = Boolean(
    enabled &&
      corporateId &&
      idToken &&
      initiativeId &&
      sessionToken
  );

  return useInfiniteQuery<
      unknown,
      unknown,
      CommunityResponseType,
      QueryKey
    >({
      enabled: isEnabled,
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length;
        const totalPages = (lastPage as CommunityResponseType).pager.total_pages ?? 0;
        return (nextPage < totalPages) ? { nextPage } : undefined;
      },
      queryFn: ({ pageParam }) => {
        // eslint-disable-next-line max-len
        let endpoint = `/api/v1/fe_learner/community?page=${pageParam?.nextPage ?? 0}&page_size=${itemsPerPage}&lang=it&lo_id=${loId}`;
      
        return fetcher(
          `${basePath}${cmswrapperApiPath}`,
          {
            body: JSON.stringify(
              {
                "body": null,
                "endpoint": endpoint,
                "method": "GET",
                "x-initiative-id": initiativeId
              }
            ),
            headers:{
              "authorization": idToken,
              "Content-Type": "application/json",
              "x-ada-session-token": sessionToken
            },
            method: "POST"
          }
        )
          .then((res) => {
            return res.json();
          })
          .catch((error) => {
            if (error === ERRORS.UNAUTHORIZED) {
              setUnauthorized(true);
              throw error; // rethrow so that react query doesn't complain about undefined return value
            } else {
              throw error;
            }
          });
      },
      queryKey: ["useInfiniteFeedSharedQuery"]
    });
};

export function useTagsQuery({
  enabled=true,
  search
} : {
  enabled?: boolean
  search: string
}) {


  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  return useQuery<
    unknown,
    unknown,
    TagResponseType,
    QueryKey
  >({
    enabled: Boolean(enabled && 
      search.length >= 3 && 
      idToken && 
      corporateId && 
      initiativeId && 
      sessionToken),

    queryFn: () => {  //fetcher goes here
      return {
        "data": [
          {
            "color": "#339933",
            "id": "1",
            "name": `${search} 1`

          },
          {
            "color": "",
            "id": "2",
            "name": `${search} 2`
          
          },
          {
            "color": "",
            "id": "3",
            "name": `${search} 3`
         
          },
          {
            "color": "",
            "id": "4",
            "name": `${search} 4`
       
          }
        ],
        "pager": {
          "current_page": 0,
          "page_size": 10,
          "total_elements": 2,
          "total_pages": 1
      
        },
        
        "count_data_obj": 2
      };
    },
    queryKey: ["useTagsQuery", search]
  });
}

export function useTopicDeleteQuery() {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;


  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      topicId
    }: {
      topicId: number
    }) => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": null,
            "endpoint": `/api/v1/fe_learner/topic?topic_id=${topicId}&user_id=${userId}`,
            "initiativeid": initiativeId,
            "method": "DELETE"
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useInfiniteFeedQuery"] });
    }
  });
}
export function usePostDeleteQuery({
  id,
  isModerator,
  postType,
  selectedFilter,
  selectedTopic,
  ...props
} :
  & { 
    id: string 
    isModerator:boolean,
    selectedFilter:string | null,
    selectedTopic:string | null
  }
  & (
    | {
      postType: "FREE"
    }
    | {
      loId:string
      postType: "SHARED"
    }
  )) {
  const fetcher = fetcherFactory();
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const userData = useAuthStore(state => state.userData);
  const userId = userData!?.id_user;

  const invalidateUriShared = ("loId" in props && props.loId) ? `${basePath}/sharedPost/${props.loId}` : "";
  const invalidateParamsShared = `initiativeId=${initiativeId}&corporateId=${corporateId}`;


  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": null,
            "endpoint": `/api/v1/fe_learner/post?post_id=${id}`,
            "method": "DELETE",
            "x-initiative-id": initiativeId,
            "x-moderator-flag":isModerator,
            "x-user-id":userId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
  
      const sharedId = searchParams.get("scrollTo");
      let queryToinvalidate = "useInfiniteFeedQuery";
      const queryToinvalidateShare =  [sessionToken , invalidateUriShared , invalidateParamsShared];

      if(selectedFilter){
        queryToinvalidate += selectedFilter;
      }
      if(selectedTopic){
        queryToinvalidate += selectedTopic;
      }
      if (postType === POST_TYPE.FREE) {
        queryClient.invalidateQueries({ queryKey: [
          queryToinvalidate
        ] });
      } else if (postType === POST_TYPE.SHARED) {
        if(sharedId && sharedId === id) {
          searchParams.delete("scrollTo");
          setSearchParams(searchParams);
          queryClient.invalidateQueries({ queryKey: [
            queryToinvalidate
          ] });
        }else {
          queryClient.invalidateQueries({ queryKey: [
            queryToinvalidate
          ] });
          queryClient.invalidateQueries({ queryKey: [
            { ...queryToinvalidateShare }
          ] });
          queryClient.invalidateQueries({ queryKey: [
            sessionToken, invalidateUriShared, invalidateParamsShared
          ] });
        }
       
      }
    }
  });
}

export function useTopicQuery({
  enabled=true,
  discussionId
}: {
  enabled?: boolean,
  discussionId: string
}) {
  const fetcher = fetcherFactory();

  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  return useQuery<
    unknown,
    unknown,
    DiscussionResponseType,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && sessionToken
      && corporateId
      && idToken
      && initiativeId
      && discussionId
    ),
    queryFn: () => fetcher(
      `${basePath}${cmswrapperApiPath}`,
      {
        body: JSON.stringify(
          {
            "body": null,
            "endpoint": `/api/v1/fe_learner/discussion?lang=it&discussion_id=${discussionId}`,
            "method": "GET",
            "x-initiative-id": initiativeId
          }
        ),
        headers:{
          "authorization": idToken,
          "Content-Type": "application/json",
          "x-ada-session-token": sessionToken

        },
        method: "POST"
      }

    )
      .then((res) => {
        return res.json();
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: ["useTopicQuery", discussionId]
  });
}

export function usePostCommunityTracking() {
  // TODO: switch to react-query mutation?
  const session = useAuthStore(state => state.session);
  const sessionToken = useAuthStore(state => state.sessionToken);
  const userData = useAuthStore(state => state.userData);

  const postCommunityTracking = useCallback(({
    duration,
    verb
  } : {
    duration: number
    verb: "viewed" | "experienced"
  }) => {
    if (!session || ! sessionToken || !userData) return;

    const body = {
      actor: {
        mbox: `mailto:${userData?.email ?? ""}`,
        name: `${userData?.external_id}`,
        objectType: "Agent"
      },
      context: {
        "contextActivities": {},
        "registration": sessionToken
      },
      object: {
        // eslint-disable-next-line max-len
        id:`https://test-openlearning-learningobject.s3.eu-south-1.amazonaws.com/${userData?.organization_id}/${userData?.id_user}/null/null/null`,
        objectType: "Activity"
      },
      result:  {
        duration: secondsTo8061Duration(duration)
      },
      verb: {
        display:{
          "en-US": verb
        },
        id: (verb === "viewed") ? "https://id.tincanapi.com/verb/viewed" : "https://adlnet.gov/exapi/verbs/experienced"
      },
      version: "1.0.0"
    };
    
    // don't bother with session recovering on 401...
    fetch(
      `${xapiTracker}/${userData?.organization_id}/statements`,
      {
        body: JSON.stringify(body),
        headers:{
          "authorization": session?.getAccessToken().getJwtToken(),
          "content-type": "application/json",
          "x-ada-session-token": sessionToken,
          "X-Experience-Api-Version": "1.0.0"
        },
        method: "POST"
      }
    )
      .then((res)=> {
        if (!res.ok) {
          throw res.statusText;
        } else if (res?.bodyUsed) {
          return res.json();
        } else {
          return res;
        }
      })
      .catch((err)=> {
        // eslint-disable-next-line no-console
        console.error("post community tracking", err);
      });
  }, [session, sessionToken, userData]);

  return postCommunityTracking;
}
