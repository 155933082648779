import {
  QueryClient,
  type QueryFunction,
  type QueryKey,
  type QueryOptions
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import type { LoadersList } from "../types";
import { getError } from "../utils/error";

/**
 * react query's query objects
 */
type QueryObject = {
  queryFn: QueryFunction
  queryKey: QueryKey
  options?: QueryOptions
}

export function fetcherFactory() {
  // NOTE: to mock an expired session use:
  // eslint-disable-next-line max-len
  // https://web-learning-session-manager-v1.openlearning-svil.digitedacademy.net/swagger-ui/index.html#/session-test-controller/removeSessionUsingDELETE
  return async function (
    url: RequestInfo | URL,
    option: RequestInit
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      fetch(url, option)
        .then((res) => {
          if (res.status === 401) {
            reject(ERRORS.UNAUTHORIZED);
          } else if (!res.ok) {
            res
              .text()
              .then((response) => { reject(response) });
            // what if the response has no text?
          } else {
            resolve(res);
          }
        })
        /**
         * NOTE, in some circumstances fetch was failing with 
         * a 401 without resolving the promise of the response
         */
        .catch((error: unknown) => {
          const message = getError(error)?.[0]?.message ?? "Failed to fetch";
          reject(message);
        });
    });
  };
}

type LoaderFactory = (queries: QueryObject[]) => () => Promise<LoadersList>

/** loaderFactory
 * creates a function to be used with react router-6 and react-query
 * cfr. https://reactrouter.com/en/main/guides/deferred for alternate pattern
 * @param queryClient
 * @returns a function to be used in the router declaration
 */
export function loaderFactory(queryClient: QueryClient): LoaderFactory {
  /**
  * @param queries an array of react-query queries
  * @returns a LoaderFunction for react-router 6
  */
  return function (queries: QueryObject[] ) {
  /**
  * @returns a map of stringified query keys to cache contents (if available)
  * or promises which resolve to a query result (if no cache is available)
  */
    return async function () {
      return (queries ?? [])
        .reduce(async(acc, query) => ({
          ...acc,
          [query.queryKey.toString()]:
            queryClient.ensureQueryData(query)
        }), {});
    };
  };
}
