import {
  useMutation,
  useQuery,
  type QueryKey,
  useQueryClient
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import { getEnvironmentVariables } from "../utils/general";

export type NotificationResponse = {
  messageList: Notification[]
}

export type Notification = {
  createdDate: string
  description: string
  eventSentId: number
  isRead: string
  isSent: string
  outputChannel: string
  sendDate: string
  title: string
  userId: string
}

const { basePath } = getEnvironmentVariables();

export function useNotificationMutation() {
  const queryClient = useQueryClient();
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  // const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  // const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  // const learnerId = useAuthStore(state => state.userData?.id_user) ?? "";

  // const refreshSession = useAuthStore(state => state.refreshSession);
  const fetcher = fetcherFactory();
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  
  const uri = "/notificator/messages";
  // const params = `corporateId${corporateId}&learnerId${learnerId}&initiativeId=${initiativeId}$`;

  const uriGet = "/notificator/messages";
  const paramsGet1 = "outputChannel=WEB";
  const paramsGet2 = "outputChannel=WEB&readStatus=Y";
  const paramsGet3 = "outputChannel=WEB&readStatus=N";
  
  return useMutation({
    mutationFn: ({
      messagesId,
      status
    } : {
      messagesId: number | number[]
      status: "Y" | "N"
    }) => fetcher(
      // `${basePath}${uri}?${params}`,
      `${basePath}${uri}`,
      {
        body: JSON.stringify({
          "messageIds": Array.isArray(messagesId) ? messagesId : [messagesId],
          "readStatus": status
        }),
        headers:{
          "authorization": idToken,
          "x-ada-session-token": sessionToken,
          
          "content-type":"application/json"
        },
        method: "POST"
      })
      .catch((error) => {
        
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ uriGet, paramsGet1,isSeedingSession ] });
      queryClient.invalidateQueries({ queryKey: [ uriGet, paramsGet2,isSeedingSession ] });
      queryClient.invalidateQueries({ queryKey: [ uriGet, paramsGet3,isSeedingSession] });
    }
  });
}

export function useNotificationQuery({
  enabled=true,
  queryKey=[],
  readStatus
} : {
  enabled?: boolean,
  queryKey?: string[]
  readStatus?: "Y" | "N"
}) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const fetcher = fetcherFactory();

  const uri = "/notificator/messages";
  const params = `outputChannel=WEB${readStatus ? `&readStatus=${readStatus}` : ""}`;

  return useQuery<
    unknown,
    unknown,
    Notification[],
    QueryKey
  >({
    enabled: Boolean(enabled && sessionToken && idToken && initiativeId) && !isSeedingSession,
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: NotificationResponse) => {
        return (apiAdapter(data.messageList) as Notification[])?.sort((a, b) => (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime() )
        );
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [ uri, params, isSeedingSession, ...queryKey ]
  });
}
