import {
  Divider,
  Grid,
  Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import it from "date-fns/locale/it";
// import { newsXmlParsed } from "./news";
import {
  // useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Calendar,
  CardRanking,
  GridMain,
  Icon,
  Spinner, 
  CardUserProgress, 
  CardUserGoals } from "../../components";
import { CardGoals } from "../../components/CardGoals/CardGoals.component";
import { FiltersBar } from "../../components/FiltersBar/FiltersBar.component";
import type { FilterItem } from "../../components/FiltersBar/FiltersBar.types";
import { HomeAlert } from "../../components/HomeAlert/HomeAlert.component";
import { HomeCard } from "../../components/HomeCard/HomeCard.component";
import { KeepLearningCard } from "../../components/KeepLearningCard/KeepLearningCard.component";
// import { NewsCard } from "../../components/NewsCard/NewsCard.component";
// import * as NewsCardVariants from "../../components/NewsCard/NewsCard.variants";

import { WidgetDrawer } from "../../components/WidgetDrawer/WidgetDrawer.component";
import {
  CONTENTS_NAME,
  PAGE_NAME
} from "../../consts";
import {
  // useContentQuery,
  useInfiniteContentQuery,
  useSmartConfiguratorGenericQuery,
  useStructuresQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import type {
  LearningObject,
  MetaData
} from "../../types";

export function Homepage() {
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [learningObjectsTypes, setLearningObjectsTypes] = useState<Set<String>>(new Set([]));
  const [filters, setFilters] = useState<FilterItem[]>([{
    icon: "Icons_obbligatorio",
    selected: false,
    value: "MANDATORY"
  },
  {
    icon: "AI",
    selected: false,
    value: "AI_SUGGESTED"
  },
  {
    icon: "Icons_invito",
    selected: false,
    value: "HR_SUGGESTED"
  },
  {
    icon: "manager_suggested",
    selected: false,
    value: "MANAGER_SUGGESTED"
  }
  ]);

  const { data: smartConfigurationTexts } = useSmartConfiguratorGenericQuery({});

  const bannerInfo = smartConfigurationTexts?.find((conf) => conf.key4 == "bannerText");

  const { data: pageStructureForYou } = useStructuresQuery({
    pageName: PAGE_NAME.FOR_YOU_OVERVIEW
    // pageName: PAGE_NAME.EXPLORE_OVERVIEW
  });
  
  // const randomExplorerCarousel = useMemo(() => (
  //   pageStructureForYou
  //     ? Math.floor(Math.random() * pageStructureForYou?.relativePaths?.length)
  //     : 0
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // ), []);

  const {
    data: homeCards,
    hasNextPage: homeCardsHaveNextPage,
    isFetchingNextPage: homeCardsAreFetchingNextPage,
    fetchNextPage: homeCardsFetchNextPage,
    isLoading: homeCardsAreLoading
  } = useInfiniteContentQuery<
    & Record<string, LearningObject[]>
    & { metadata: MetaData }
  >({
    enabled: Boolean(pageStructureForYou?.relativePaths?.[0].apiPath),
    learningObjectsType: Array.from(learningObjectsTypes),
    pageSize: 12,
    path: pageStructureForYou?.relativePaths?.[0].apiPath?? ""
    // path: pageStructureForYou?.relativePaths?.[randomExplorerCarousel].apiPath?? "", // :D
    // topics: []
  });

  const ApiHomeCards = homeCards?.pages?.reduce((acc, page) => {
    const contents = page?.[CONTENTS_NAME.FOR_YOU] ?? [];
    return acc.concat(contents);
  }, [] as LearningObject[]);



  const { t } = useTranslation();
  // const { data: newsFeedGet } = useNewsFeed();

  // console.log(newsFeedGet);



  return (
    <>
      { !!bannerInfo &&
        <HomeAlert HomeAlertText={ bannerInfo.value1 }/>
      }
      <GridMain
        paddingLeft={ 0 }
      >
  
        <Grid
          container
          item
          component="article"
          flexDirection="column"
          width="calc(100% - 334px)"
          sx={ {
            paddingRight: "20px",

            [theme.breakpoints.down("sm")]: {
              paddingRight: "0",
              width: "100%"
            }
          } }
        >
          {
            (homeCardsAreLoading) ? (
              <Spinner alignSelf="center" padding={ 4 } size={ 80 } thickness={ 3 } />
            ) : (
              <>
                <FiltersBar
                  marginLeft={ isMobile ? "20px" : "40px" }
                  filters={ filters }
                  onClickHandler={ (filterValue)=> {
                    const newFilters = [ ...filters ];
                    const selectedFilter = newFilters.find((filter) => filter.value === filterValue);
                    if (!selectedFilter) return;

                    const newLearningObjectTypes = new Set(learningObjectsTypes);
                    if (selectedFilter.selected === false){
                      newLearningObjectTypes.add(filterValue);
                    } else if (selectedFilter.selected === true) {
                      newLearningObjectTypes.delete(filterValue);
                    }

                    selectedFilter.selected = !selectedFilter?.selected;
                    setFilters(newFilters);
                    setLearningObjectsTypes(newLearningObjectTypes);
                  } }
                />
                <Stack
                  divider={
                    <Divider sx={ {
                      border: `1px solid ${theme.customColors.border}`,
                      borderRadius: "1rem",
                      marginLeft: "auto",
                      width: "calc(100% - 40px )",

                      [theme.breakpoints.down("sm")]: {
                        width: "100%"
                      }
                    } }
                    />
                  }
                  gap={ isMobile ? 2 : 5 }
                  paddingLeft={ "40px" }
                  width={ "100%" }
                  sx={ {
                    overflowX: "hidden",

                    [theme.breakpoints.down("sm")]: {
                      overflowX: "visible",
                      paddingLeft: "20px",

                      ".lfRhmr":{
                        left: "-1.2rem",
                        position: "relative",
                        top: "auto"
                      }
                    }
                  } }
                >

                  {
                    ApiHomeCards?.map((homeCard, index) => (
                      <HomeCard
                        aiItemType={ homeCard.aiItemType }
                        key={ `api-home-card--${index.toString()}` }
                        badge={ homeCard.badge }
                        certificate={ homeCard.certificate }
                        categories={ homeCard.topicTags?.slice(0,3) }
                        coverPublicURL={ homeCard.coverPublicURL }
                        duration={ homeCard.duration }
                        enrollType={ homeCard.enrollType }
                        ecm_specialization={ homeCard.ecm_specialization }
                        ecmRegistration={ homeCard.ecmRegistration }
                        ecmType={ homeCard.ecmType }
                        editionNumber={ homeCard?.editionNumber }
                        endDate={ homeCard?.editions?.[0].endDate }
                        expirationDate={ homeCard.expirationDate }
                        id={ homeCard.id }
                        isAutoSubscribeEnable={ homeCard.isAutoSubscribeEnable }
                        isExpired={ homeCard.isExpired }
                        isFavourite={ homeCard.isFavourite }
                        isMandatory={ homeCard.isMandatory }
                        isSuggestedAI={ homeCard.isSuggestedAI }
                        isSuggestedHR={ homeCard.isSuggestedHR }
                        isSuggestedManager={ homeCard.isSuggestedManager }
                        learningObjectType={ homeCard.learningObjectType  }
                        learningObjectTypology={ homeCard.learningObjectTypology }
                        percentageOfCompletion={ homeCard.percentageOfCompletion }
                        rating={ homeCard.ratingScore ?? 0 }
                        shortDescription={ homeCard.shortDescription  }
                        startDate={ homeCard?.editions?.[0].startDate  }
                        status={ homeCard.status || "N" }
                        title={ homeCard.title }
                      />
                    ))
                  }


                  {
                    (homeCardsHaveNextPage && !homeCardsAreFetchingNextPage) ? (
                      <Button
                        disableRipple
                        disabled={ !homeCardsHaveNextPage }
                        onClick={ () => homeCardsFetchNextPage() }
                        sx={ {
                          gap:"0.5rem",
                          margin:"0 auto",
                          width:"fit-content"
                        } }
                      >
                        { t("load_more") }

                        <Icon
                          color={ theme.customColors.systemPrimary02 }
                          icon="dropdown"
                          size={ 20 }
                        />
                      </Button>
                    ) : null
                  }
                  {
                    homeCardsAreFetchingNextPage ? (
                      <Spinner alignSelf="center" padding={ 2 } size={ 100 } thickness={ 2 } />
                    ) : null
                  }
                </Stack>
              </>

            )
          }
        </Grid>
        { useMediaQuery(theme.breakpoints.down("sm")) ? (
          <WidgetDrawer />
        ) : (
          <Grid
            item
            component="aside"
            flexDirection="column"
            gap={ 2 }
            display="flex"
            width="334px"
            sx={ {
              borderLeft: `1px solid ${theme.customColors.border}`,
              padding: theme.spacing(3.875, 0, 2, 2.5)
            } }
          >
            <Calendar />
            <CardRanking isUserPage={ false }/>
            <KeepLearningCard />
            <CardGoals />
            { /* <NewsCard { ...NewsCardVariants.Default }/> */ }
            <CardUserProgress isProfilePage={ false }/>
            <CardUserGoals isProfilePage={ false }/>
          </Grid>
        ) }
      </GridMain>
    </>
  );
}
