import { create } from "zustand";
import { NAVBAR_ITEMS } from "../consts";
import type { NavbarItem } from "../types";
import { 
  getNavItem, 
  persistNavItem 
} from "../utils/navbar";

export type NavbarState = {
  item: NavbarItem
}

type NabvbarActions = {
  readonly setNavbarItem: (item: NavbarItem) => void
}

const defaultNavbarState: NavbarState = {
  item: getNavItem() ?? NAVBAR_ITEMS.FOR_YOU
};

export const useNavbarStore = create<NavbarState & NabvbarActions>((set) => {
  
  return {
    ...defaultNavbarState,
    setNavbarItem: (item: NavbarItem) => {
      set({ item });
      persistNavItem(item.toString());
    }

  };
});
