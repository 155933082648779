import { TabsContentProps } from "./TabsContent.types";
import {
  TabsContentActivities,
  TabsContentCommunity,
  TabsContentDetail,
  TabsContentDetailWatch,
  TabsContentEditions,
  TabsContentInstruments,
  TabsContentRelated
} from "../../components";
import { TAB_ITEMS } from "../../consts";

export function TabsContent({
  contentActivities,
  contentEditions,
  contentLinks,
  currentLO,
  contentRelated,
  contentTools,
  isDetailPage,
  sharedPosts,
  setOpenModalEcm,
  tabType,
  titleEditions,
  ...props
}: TabsContentProps) {
  switch (tabType) {
  case TAB_ITEMS.ACTIVITIES:
    return (
      contentActivities && currentLO ? (
        <TabsContentActivities
          contentActivities={ contentActivities }
          currentLO={ currentLO }
          setOpenModalEcm={ setOpenModalEcm }
          title = { currentLO?.title ?? "" }
          { ...props }
        />
      ) : null
    );

  case TAB_ITEMS.COMMUNITY:
    return (
      sharedPosts  ? (
        <TabsContentCommunity
          loId={ currentLO?.id.toString() ?? "" }
          sharedPosts={ sharedPosts }
        />
      ) : null
    );

  case TAB_ITEMS.DETAIL:
    return (
      <>
        {
          (isDetailPage==true && currentLO) ? (
            <TabsContentDetail
              currentLO={ currentLO }
              isDetailsPage={ isDetailPage }
              { ...props }
            />
          ) : null
        }
        {
          (isDetailPage === false && currentLO) ? (
            <TabsContentDetailWatch
              currentLO={ currentLO }
              isDetailsPage={ isDetailPage }
              { ...props }
            />
          ) : null
        }
      </>
    );

  case TAB_ITEMS.EDITIONS:
    return (
      contentEditions && titleEditions ? (
        <TabsContentEditions
          currentLO={ currentLO }
          contentEditions={ contentEditions }
          titleEditions={ titleEditions }
          rootId={ props.rootId }
          courseId={ props.courseId }
          { ...props }
        />
      ) : null
    );

  case TAB_ITEMS.INSTRUMENTS:
    if (contentLinks && contentTools === undefined) {
      return (
        <TabsContentInstruments
          contentLinks={ contentLinks }
          contentTools={ contentTools }
          currentLO={ currentLO }
          { ...props }
        />
      );
    } else if (contentLinks === null && contentTools) {
      return (
        <TabsContentInstruments
          contentLinks={ contentLinks }
          contentTools={ contentTools }
          currentLO={ currentLO }
          { ...props }
        />
      );
    } else if (contentLinks && contentTools) {
      return (
        <TabsContentInstruments
          contentLinks={ contentLinks }
          contentTools={ contentTools }
          currentLO={ currentLO }
          { ...props }
        />
      );
    } else { return null }

  case TAB_ITEMS.RELATED:
    return (
      contentRelated ? (
        <TabsContentRelated
          contentRelated={ contentRelated }
          currentLO={ currentLO }
          { ...props }
        />
      ) : null
    );

  default:
    return (
      <></>
    );
  }
}
