import {
  Box,
  BoxProps,
  Stack,
  Typography
} from "@mui/material";
import { common } from "@mui/material/colors";
import {
  alpha,
  styled
} from "@mui/material/styles";
import {
  ButtonBookmark,
  ButtonLink,
  ButtonProps,  Icon,
  StatusBox
} from "../../components";
import { OverlayStack } from "../CtaOverlay/CtaOverlay.styles";
import { PlayerLoader } from "../PlayerLoader";

export const StyledButtonBookmark = styled(ButtonBookmark)<
  ButtonProps & { $selected: boolean }
>(({ $selected, theme }) => ({
  alignItems: "center",
  border: "none",
  bottom: "45px",
  cursor: "pointer",
  display: "flex",
  height: "30px",
  minWidth:"30px",
  opacity: 0,
  padding: 0,
  position: "absolute",
  right: 0,
  transition: "all .1s ease 0s",
  zIndex: "1",

  "&:before": {
    /* eslint-disable max-len */
    backgroundImage: $selected
      ? `url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.1722 1.69176C4.41121 2.28471 3.91797 3.21647 3.91797 4.26118V18.8588C3.91797 20.6518 5.3695 22.1059 7.15925 22.1059H21.7591C23.5488 22.1059 25.0004 20.6518 25.0004 18.8588V4.24706C25.0004 2.45412 23.5488 1 21.745 1H9.34359' stroke='%23${theme.customColors.systemPrimary02.replace("#", "")}' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M1.23957 4.25977C1.08456 4.55624 1 4.89506 1 5.248V22.6833C1 23.8974 2.01466 24.8998 3.31117 24.9986H18.7566H22.477C23.1393 24.9986 23.7453 24.7021 24.1963 24.2362' stroke='%23${theme.customColors.systemPrimary02.replace("#", "")}' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");`
      : `url("data:image/svg+xml,%3Csvg width='26' height='30' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.1722 1.69176C4.41121 2.28471 3.91797 3.21647 3.91797 4.26118V18.8588C3.91797 20.6518 5.3695 22.1059 7.15925 22.1059H21.7591C23.5488 22.1059 25.0004 20.6518 25.0004 18.8588V4.24706C25.0004 2.45412 23.5488 1 21.745 1H9.34359' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M1.23957 4.25977C1.08456 4.55624 1 4.89506 1 5.248V22.6833C1 23.8974 2.01466 24.8998 3.31117 24.9986H18.7566H22.477C23.1393 24.9986 23.7453 24.7021 24.1963 24.2362' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E ");`,
    /* eslint-enable max-len */
    backgroundRepeat: "no-repeat",
    content: " '' !important ",
    display: "flex",
    height: "30px",
    minWidth: "25px"
  },
  "&:hover":{
    transform: "scale(1.2)"
  }
}));

export const StyledButtonLink = styled(ButtonLink)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontWeight: "400",
  gap: "0.5rem",
  opacity: 0,
  pointerEvents: "none",
  position: "absolute",
  textTransform: "none",
  transition: "all .3s linear",
  zIndex: "1",

  left: "42px",
  top: "32px",

  "&:hover": {
    background: "none",
    boxShadow: "none"
  }
}));

export const StyledIcon = styled(Icon)(() => ({}));

export const StyledCoverLoader = styled(Box)<
  BoxProps & {
  $cover?: string
}
  >(({
    $cover,
    theme }) => ({
    background: `url(${$cover})`,
    backgroundPosition: "center",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
    justifyContent:"flex-end",
    minHeight: "750px",
    overflow: "hidden",

    width: "100%",

    "> main >span":{

      right:"42px"
    },

    [`${StyledButtonBookmark}, ${StyledButtonLink},  ${StyledStatusBox}`]:{
      opacity:1,
      pointerEvents:"auto"
    },

    ".video-caption": {
      height: "fit-content",
      pointerEvents: "none"
    },

    ".icon-play":{
      border:`1px solid ${theme.customColors.borderTag}`,
      borderRadius:"50%",
      cursor: "pointer",
      height:"70px",
      margin:"auto",
      width:"70px",

      alignItems:"center",
      display:"flex",
      justifyContent: "center",

      position:"absolute",

      bottom:0,
      left:0,
      right:0,
      top:0
    },


    [theme.breakpoints.down("sm")]: {
      [`${StyledButtonLink}`]:{
        fontSize: "0.75rem",

        [`${StyledIcon}`]:{
          height: "15px",
          width: "12px"
        }
      },

      ".video-caption":{
        "h3":{
          fontSize: "1rem"
        }
      }
    }
  }));

export const StyledPlayerLoader = styled(PlayerLoader)(({ theme }) => ({
  overflow: "hidden",
  position:"relative",

  "&::before":{
    backgroundImage: theme.linearGradients.gradientD,
    content:"''",
    inset:0,
    opacity:1,
    position:"absolute",
    transition:"all .3s linear",
    zIndex:0
  },

  height: "100%",
  width: "100%",

  ">main >span":{
    right:"42px"
  },
  [`${StyledButtonBookmark}`]:{
    right:"170px"
  },

  ".video-caption": {
    height: "fit-content",
    pointerEvents: "none"
  },

  ".video-js": {
    height: "100%",
    width: "100%",

    padding: "0",

    "video": {
      objectFit: undefined
    },

    "span": {
      "&:before":{
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        position: "static"
      }
    },

    ".vjs-big-play-button": {
      alignItems: "center",
      background: "transparent",
      border: `1px solid ${theme.customColors.borderTag}`,
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",

      height: "70px",
      width: "70px",
      zIndex:1,

      "span":{
        "&:before":{
          //eslint-disable-next-line max-len
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='21' height='26' viewBox='0 0 21 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.09943 24.0796C3.44445 25.2429 1.16729 24.0421 1.17974 22.0157L1.24196 12.0212L1.24196 3.29003C1.24196 1.16353 3.74309 0.000217722 5.36074 1.38869L18.9614 10.345C20.2929 11.3832 20.2431 13.4096 18.8619 14.3728L9.15599 21.2151' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`,
          content: " '' !important ",
          display: "flex",
          position: "static",

          height: "24px",
          width: "18px"
        },
        height: "24px",
        width: "18px"
      }

    },

    ".vjs-control-bar": {
      alignItems: "center",
      background: "transparent",
      bottom: "40px",
      flexWrap: "wrap",
      height: "5rem",
      margin: "0 auto ",
      maxWidth: "1440px",
      padding:"0 42px",
      transform: "translateY(0) !important",
      zIndex:1,

      ".vjs-playback-rate":{
        display: "none",
        height:"40px",
        width:"24px",

        ".vjs-playback-rate-value":{
          height:"fit-content",
          margin:"auto",

          bottom:0,
          rigth:0
        }
      },

      ".vjs-current-time ": {
        marginLeft: "8px"
      },
      ".vjs-current-time, .vjs-duration, .vjs-time-divider ": {
        fontSize: "1rem",
        margin: "0",
        padding: "0"
      },
      ".vjs-fullscreen-control": {
        order: "9999",
        "span":{
          "&:before":{
            //eslint-disable-next-line max-len
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.45921 1H4.22392C2.67098 1 1.40039 2.27059 1.40039 3.82353V8.05882' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M25.4006 8.05882V3.82353C25.4006 2.27059 24.13 1 22.5771 1H18.3418' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M18.3418 25.0002H22.5771C24.13 25.0002 25.4006 23.7296 25.4006 22.1767V17.9414' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.40039 17.9414V22.1767C1.40039 23.7296 2.67098 25.0002 4.22392 25.0002H8.45921' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");`
          }
        }
      },
      ".vjs-picture-in-picture-control": {
        display: "none"
      },
      ".vjs-play-control": {
        "span":{
         
          "&:before": {
            // eslint-disable-next-line max-len
            backgroundImage:`url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.52355 20H2.49543C1.11795 20 0 18.88 0 17.5V2.50001C0 2.13001 0.0798538 1.77001 0.239561 1.43001C0.359342 1.18001 0.658794 1.08001 0.908337 1.19001C1.15788 1.31001 1.26768 1.61001 1.1479 1.86001C1.04808 2.06001 1.00815 2.28001 1.00815 2.50001V17.5C1.00815 18.33 1.67693 19 2.50541 19H3.53353C4.36201 19 5.03079 18.33 5.03079 17.5V2.50001C5.03079 1.79001 4.52172 1.17001 3.823 1.03001C3.55349 0.980014 3.37382 0.710014 3.43371 0.440014C3.48362 0.170014 3.74315 -0.00998624 4.02263 0.0500138C5.1905 0.280014 6.03894 1.31001 6.03894 2.50001V17.5C6.03894 18.88 4.92099 20 3.54351 20H3.52355Z' fill='%23${theme.customColors.systemPrimary01.replace("#", "")}'/%3E%3Cpath d='M12.5071 20C11.1297 20 10.0117 18.88 10.0117 17.5V2.5C10.0117 1.12 11.1297 0 12.5071 0H13.5053C14.8828 0 16.0008 1.12 16.0008 2.5V16.8C16.0008 17.08 15.7812 17.3 15.5017 17.3C15.2222 17.3 15.0026 17.08 15.0026 16.8V2.5C15.0026 1.67 14.3338 1 13.5053 1H12.5071C11.6787 1 11.0099 1.67 11.0099 2.5V17.5C11.0099 18.33 11.6787 19 12.5071 19H13.156L13.5452 18.95C13.8148 18.91 14.0643 19.11 14.1042 19.38C14.1342 19.65 13.9445 19.9 13.675 19.94L13.2258 20H12.5171H12.5071Z' fill='%23${theme.customColors.systemPrimary01.replace("#", "")}'/%3E%3C/svg%3E");`
          }
        },

        "&.vjs-paused":{
          "span":{
            "&:before":{
              //eslint-disable-next-line max-len
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='21' height='26' viewBox='0 0 21 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.09943 24.0796C3.44445 25.2429 1.16729 24.0421 1.17974 22.0157L1.24196 12.0212L1.24196 3.29003C1.24196 1.16353 3.74309 0.000217722 5.36074 1.38869L18.9614 10.345C20.2929 11.3832 20.2431 13.4096 18.8619 14.3728L9.15599 21.2151' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`
            }
          }
        }
      },

      ".vjs-progress-control": {
        margin: "0",
        order: "0",
        width: "100%",

        maxWidth: "1440px",
        padding:"0 42px",


        [theme.breakpoints.down("sm")]: {
          top: "-1.8em"
        },

        ".vjs-load-progress":{
          borderRadius: "40px",
          
          ">div": {
            background: theme.customColors.backgroundSecondary,
            borderRadius: "40px"
          }
        },

        ":hover .vjs-progress-holder": {
          fontSize: "inherit"
        },

        // "&:hover ": {

        //   ".vjs-progress-holder .vjs-play-progress": {
        //     "&:before": {
        //       bottom: "-0.9em",
        //       height: "30px",
        //       width: "30px"
        //     }
        //   }
          
        // },

        ".vjs-play-progress": {
          backgroundColor: theme.palette.secondary?.main,
          borderRadius: "40px",
          position: "relative",

          "&:before": {
            backgroundColor: theme.palette.secondary?.main,
            border: `2px solid ${theme.customColors.borderTag}`,
            borderRadius: "40px",
            bottom: "-0.5em",
            content: "''",
            display: "flex",
            height: "20px",
            width: "20px",
            zIndex:0,

            position: "absolute"
          }
        
        },

        ".vjs-progress-holder": {
          backgroundColor: theme.customColors.backgroundSecondary,
          borderRadius: "40px",
          height: "7px",
          margin: "0"
        },

        ".vjs-time-tooltip": {
          top: "-5.4em"
        }
      },
      ".vjs-subs-caps-button": {
        alignItems: "center",
        cursor: "pointer",
        display: "none",
        order: "9999",
        padding: "0",

        "span": {
          "&:before": {
            //eslint-disable-next-line max-len
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='32' height='30' viewBox='0 0 32 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.4 18.6V4.2C31.4 2.44 29.96 1 28.2 1H4.2C2.44 1 1 2.44 1 4.2V21.8C1 23.56 2.44 25 4.2 25H28.152C28.712 25 29.256 24.856 29.736 24.584L31.4 23.64' stroke='%23${theme.customColors.systemPrimary01}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.7998 15.4004H20.1998' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M23.3994 15.4004H26.5994' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.7998 18.5996H8.9998' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.2002 18.5996H26.6002' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");`
          }
        },

        ".vjs-menu ":{
          ".vjs-menu-content":{
            bottom: "2.5em",
            zIndex: 4
          }
        }
      },
      ".vjs-time-divider ": {
        minWidth: "unset",
        width: "10px"
      },
      ".vjs-volume-panel ": {
        marginRight: "0",

        "span":{
          "&:before":{
            //eslint-disable-next-line max-len
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 12.9905V11.1185C1 9.06994 2.18736 7.39223 3.66524 7.39223H6.49469C6.96206 7.39223 7.41679 7.21563 7.821 6.89775L11.7368 3.73661C12.5831 3.06552 13.6315 3.91321 13.6315 5.27303V17.3878' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.9131 6.12109C19.1762 7.8871 19.9468 10.3065 19.9468 12.9909C19.9468 15.6752 19.2015 17.9886 17.9889 19.737' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.4131 1C23.6362 4.09051 25.0004 8.3819 25.0004 13.1148C25.0004 17.8477 23.6868 21.9272 21.5647 25' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 12.9922V14.8642C1 16.9127 2.18736 18.5904 3.66524 18.5904H6.48206C6.94942 18.5904 7.41679 18.767 7.83363 19.0849L11.1052 21.6809C11.8883 22.299 12.861 22.299 13.6441 21.6456L13.7452 21.5573' stroke='%23${theme.customColors.systemPrimary01.replace("#", "")}' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");`
          }
        },

        ".vjs-volume-bar": {
          borderRadius: "4px",
          height: "0.3rem"
        },
        ".vjs-volume-learningObjectTypology": {
          backgroundColor: theme.palette.secondary?.main,
          borderRadius: "4px",
          height: "0.3rem"
        }
      },
      ".vjs-volume-panel, .vjs-play-control, .vjs-fullscreen-control, .vjs-subs-caps-button": {
        "&:hover": {
          transform: "scale(1.2)"
        },
        "span":{
          "&:before":{
            content: "'' !important",
            display: "flex",
            height: "24px",
            width: "24px"
          },
          height: "24px",
          width: "24px"
        },
        transition: "all .3s ease"
      },
      "> div, > button": {
        alignItems: "center",
        margin: "0 0.5rem",
        order: "1",
        width: "fit-content"
      }
    },

    ".vjs-poster": {
      "img": {
        objectFit: "cover"
      }
    },

    ".vjs-title-bar": {
      display: "none"
    }
  },

  "> div": {
    height: "100%"
  },
  ">main":{
    pointerEvents:"none",
    zIndex: 1
  },

  [` >main >span, .video-caption, ${StyledButtonBookmark}, ${StyledStatusBox}`]: {
    opacity: 0,
    pointerEvents: "none",
    transition: "all .3s linear"
  },

  "&.brightcove-is-paused": {
    [`>main >span, ${StyledButtonLink}, ${StyledButtonBookmark}`]: {
      pointerEvents: "auto"
    },
    [`>main >span, .video-caption,
    ${StyledButtonLink},
    ${StyledButtonBookmark},
    ${StyledButtonBookmark},
    ${StyledStatusBox}`]: {
      opacity: 1
    },

    "&::before":{
      opacity:1,
      zIndex:1
    }
  },

  "&.brightcove-is-playing":{

    ".vjs-big-play-button": {
      display: "none"
    },

    ".vjs-control-bar": {
      opacity: "0 !important"
    },

    "&::before":{
      opacity:0,
      zIndex:0
    },

    "&:hover":{
      [` ${StyledButtonBookmark}, ${StyledStatusBox},  .vjs-control-bar`]: {
        opacity: "1 !important",
        pointerEvents: "auto"
      },

      "&::before":{
        opacity:1,
        zIndex:1
      }
    }
  },

  "&:hover": {
    [`> main >span, ${StyledButtonLink}`]: {
      pointerEvents: "auto"
    },
    [`> main >span, .video-caption, ${StyledButtonLink}, ${StyledStatusBox}`]: {
      opacity: 1
    }
  },

  [theme.breakpoints.down("sm")]: {
    [`${StyledButtonLink}`]:{
      fontSize: "0.75rem",

      [`${StyledIcon}`]:{
        height: "15px",
        width: "12px"
      }
    },
    ".video-caption":{
      "h3":{
        fontSize: "1rem"
      }
    }
  }
}));

export const StyledStatusBox = styled(StatusBox)(({ theme }) => ({
  left: "0",
  marginBottom: "42px",
  opacity: "0",
  position: "absolute",
  top: "25%",
  zIndex: theme.zIndex.appBar,

  height: "60px",
  width: "60px",

  "svg":{
    height:"30px !important",
    width:"30px !important"
  },

  [theme.breakpoints.down("sm")]: {
    left: "-20px",

    height: "40px",
    width: "40px",

    [`${StyledIcon}`]:{
      height: "19px",
      width: "19px"
    }
  }
}));

export const VideoStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.background?.paper,
  [`${StyledPlayerLoader}`]:{
    ".vjs-control-bar":{
      height:"fit-content"
    }
  },
  "main":{
    padding:"16px"
  },

  [theme.breakpoints.down("sm")]:{
    height: "450px",

    [`${OverlayStack}`]: {
      display: "none"
    }
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight:"700",
  lineHeight: 7 / 6,
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`
}));

//video caption

export const VideoCaptionStyles = styled(Stack)(() => ({
  position:"absolute",

  bottom:"20px",
  left:"42px",

  fontSize:"2rem",
  maxWidth:"600px",
  padding:"0 0 68px",
  zIndex:"1"

}));
