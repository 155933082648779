import {
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  DetailTypography,
  BigCarouselCardStack,
  TitleTypography
} from "./BigCarouselCard.styles";
import type { BigCarouselCardProps } from "./BigCarouselCard.types";
import {
  CardCta,
  CardSignature, CardType,
  Icon,
  LinkWithInteractableOverlay
} from "../../components";

export function BigCarouselCard({
  booked,
  grandParentId,
  coverPublicURL,
  duration,
  editionNumber,
  endDate,
  enrollType,
  expirationDate,
  id,
  isHighLightEdition,
  isAutoSubscribeEnable,
  isStandAlone,
  isBookmarkPage=false,
  isFavourite,
  labelMoreInfo=false,
  learningObjectType,
  learningObjectTypology,
  percentageOfCompletion,
  notice,
  // onClickFavourite,
  parentId,
  shortDescription,
  simple=true,
  startDate,
  status,
  title,
  ...props
}: BigCarouselCardProps & { simple: boolean }) {
  const theme = useTheme();
  const { t }= useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <BigCarouselCardStack
      component="section"
      coverPublicURL={ coverPublicURL }
      gap={ isMobile ? 1 : 2 }
      { ...props }
    >
      {
        notice ?
          <Stack direction="row" gap={ 1 }
          >
            <Icon icon={ "Icons_info" } color={ theme.customColors.backgroundWarning } size={ 20 }/>
            <Typography color={ theme.customColors.backgroundWarning }>
              { notice + " " + t("remaining_places")  }
            </Typography>
          </Stack>   :
          null
      }

      <LinkWithInteractableOverlay
        href={ `/esplora/dettaglio/${id}/${learningObjectTypology}` }
        tabIndex={ 0 }
      >
        {
          learningObjectType ?
            <CardType
              learningObjectType={ learningObjectType }
              learningObjectTypology={ learningObjectTypology }
              isTest={ false }
              isSurvey={ false }
              sx={ {
                fontWeight:700,

                ">p":{
                  marginBottom:"1rem"
                },

                [theme.breakpoints.down("sm")]:{
                  width: "100%"
                }
              }
              }/> :
            null
        }

        <TitleTypography>
          { title }
        </TitleTypography>
      </LinkWithInteractableOverlay>
      {
        shortDescription ?
          <DetailTypography>
            { shortDescription }
          </DetailTypography> :
          null
      }

      <CardSignature
        editionNumber={ editionNumber }
        expirationDate={ expirationDate }
        duration={ duration }
        endDate={ endDate }
        gap={ 2 }
        isHighLightEdition={ isHighLightEdition }
        learningObjectTypology={ learningObjectTypology }
        startDate={ startDate }
      />
      {
        <CardCta
          isAutoSubscribeEnable={ isAutoSubscribeEnable }
          // updateQueryFunction={ onClickFavourite }
          enrollType={ enrollType }
          isStandAlone={ isStandAlone }
          booked={ booked || false }
          className={ "card-cta" }
          disableFavourites={ false }
          id={ id }
          ecm_specialization={ false }
          ecmRegistration={ false }
          learningObjectTypology={ learningObjectTypology }
          isFavourite={  isFavourite }
          isBookmarkPage={ isBookmarkPage }
          status={ status }
          learningObjectType={ learningObjectType }
          percentageOfCompletion={ percentageOfCompletion }
          simple={ simple }
          isCard={ true }
          labelMoreInfo={ labelMoreInfo }
          grandParentId={ grandParentId }
          parentId={ parentId }
          sx={ {
            ">div": {
              [theme.breakpoints.down("sm")]:{
                gap: theme.spacing(2)
              }
            }
          } }
        />
      }

    </BigCarouselCardStack>
  );
}
