import {
  InlineModal,
  StyledIframe
} from "./ScormWrapper.styles";
import type { Level } from "../../types";

export function ScormWrapper({
  activityId,
  // close,
  courseId,
  // fullScreen=false,
  isOpen,
  learningObjectTypology,
  parentId
} : {
  activityId: number
  // close: () => void
  courseId: string | null
  // fullScreen?: boolean
  isOpen: boolean
  learningObjectTypology: Level
  parentId: string | null
}) {
  const uri = `${window.location.origin}/esplora/scorm/${activityId}/${learningObjectTypology}`;
  const params = `courseId=${courseId ?? null}&rootId=${parentId ?? null}`;

  return (
    <InlineModal $open={ isOpen } >
      { /* {
        fullScreen ? (
          <Stack alignItems="flex-end">
            <Stack
              alignItems="center"
              direction="row"
              gap={ 1 }
              onClick={ close }
              paddingY={ 0.5 }
              paddingX={ 1 }
              sx={ { cursor: "pointer" } }
            >
              <Typography>
                { t("close") }
              </Typography>
              <Icon
                icon="close"
                size={ 20 }
              />
            </Stack>
          </Stack>
        ) : null
      } */ }
      <StyledIframe
        src={ `${uri}?${params}` } 
        title={ `iframe-scorm-${learningObjectTypology}` }
      />
    </InlineModal>
  );
}
