import {
  Stack,
  TextFieldProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  forwardRef,
  Ref
} from "react";
import type { SelectInputProps } from "./SelectInput.types";
import {
  Icon,
  TextInput
} from "../../components";

export const ResultsStack = styled(Stack)<{$isVisible?:boolean}>(({ theme, $isVisible=true }) => ({

  backgroundColor: theme.customColors.backgroundSecondary,
  borderRadius: $isVisible? "4px" :"10%",
  bottom: theme.spacing(-1),
  boxShadow:"2px 2px 16px rgba(0, 0, 0, 0.4);",
  color: theme.customColors.textInput,
  maxHeight:"150px",
  opacity:$isVisible ? 1 : 0,
  pointerEvents:$isVisible ? "auto" : "none",
  position: "absolute",
  transform: $isVisible ? "translateY(100%) scale(1)" : " translateY(100%) scale(0.5)",
  transition:  "all .2s linear" ,
  width: "100%",
  zIndex: $isVisible ?  theme.zIndex.mobileStepper : 0,

    
  "&:hover": {
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: `${theme.customColors.systemPrimary02} !important`
    }
  }
}));

export const ResultTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textInput,
  cursor: "pointer",
  fontWeight: 400,
  lineHeight: 9 / 4,
  padding: theme.spacing(0, 2.5)
}));

export const SearchCancel = styled(Icon)(() => ({}));

const _StyledTextInput = forwardRef((
  props: TextFieldProps,
  ref?: Ref<HTMLDivElement>
) => (
  <TextInput { ...props } ref={ ref }/>
));
_StyledTextInput.displayName = "_StyledTextInput";

export const StyledTextInput = styled(_StyledTextInput)<
  Omit<
    SelectInputProps,
    "callBack" |
    "setValue" |
    "setValueSelected" |
    "valueSelected" |
    "resultsApi" |
    "type"
  > 
>(({
  theme,
  value
}) => ({
 

  "&::-webkit-input-placeholder ":{
    color : theme.customColors.textInput,
    fontWeight:500,
    opacity:1
  },
  ".MuiInputBase-input": {
    color : theme.customColors.textInput,
    fontWeight:500,
    paddingBottom: "0.4rem",
    paddingTop: "0.4rem"
  },

  ".MuiInputBase-root": {
    backgroundColor: theme.customColors.backgroundSecondary,
    borderRadius: 0
  },

  [`${SearchCancel}`]: {
    "path": {
      fill: (value )
        ? theme.palette.primary?.main
        : undefined
    }
  },
  [`${theme.breakpoints.down("sm")}`]:{
    margin:theme.spacing(1,0)
  }
}));
