import {
  Grid,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { secondsToHours, secondsToMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  CarouselCardGrid,
  CarouselSlideBox,
  StyledStatusBox,
  StyledSwipeableDrawer
} from "./CarouselCardMobile.styles";
import type {
  CarouselCardMobileProps,
  StatusBoxProps
} from "./CarouselCardMobile.types";
import {
  CardCategories,
  CardCta,
  CardSignature,
  CardType,
  Icon,
  LinkWithInteractableOverlay
} from "../../components";
import { STATUS_LO } from "../../consts";
import { useAccent } from "../../hooks";
import { hasPassedExpiration } from "../../utils/datetime";
import {
  DetailTypography,
  StyledDurationBadge,
  TitleTypography
} from "../CarouselCard/CarouselCard.styles";

export function CarouselCardMobile({
  booked,
  coverPublicURL,
  duration,
  disabled,
  ecmRegistration,
  ecmType,
  endDate,
  editionNumber,
  enrollType,
  expirationDate,
  id,
  isAutoSubscribeEnable,
  ecm_specialization,
  isMandatory,
  isTest,
  isSurvey,
  learningObjectType,
  learningObjectTypology,
  open,
  percentageOfCompletion,
  setOpen,
  shortDescription,
  startDate,
  status,
  title,
  ...props
}: CarouselCardMobileProps) {
  const theme = useTheme();
  const isExpired = hasPassedExpiration(expirationDate);
  const accentColor = useAccent({ isExpired, isMandatory, status });


  const { t } = useTranslation();

  return (
    <StyledSwipeableDrawer
      id={ `drawer-${title}` }
      anchor="bottom"
      onClose={ () => setOpen({
        label: "",
        openId: "",
        state: false
      }) }
      onOpen={ () => {} }
      open={ open.state || title === open.label }
      variant={ "temporary" }
    >
      <CarouselSlideBox>
        <CarouselCardGrid
          container
          accent={ accentColor }
          className={ "carosel-card--active" }
          component="section"
          direction="column-reverse"
          coverPublicURL={ coverPublicURL }
          padding="0 1.4rem 1.4rem"
        >
          <Grid
            item
            xs={ 6 }
            container
            direction="column"
          >
            <StyledDurationBadge
              $completed={ status === STATUS_LO.C }
              padding={ theme.spacing(0.56, 1) }
              right= { theme.spacing(1) }
              top= { theme.spacing(3) }
            >
              <Icon icon={ "Icons_time" } size={  15  }/>
              { Number(duration)
                     > 3600 ?
                t("hour", { count: secondsToHours(parseInt(`${duration}`)) })
                :
                t("minutes", { count: secondsToMinutes(parseInt(`${duration}`)) })
              }
            </StyledDurationBadge>
            <StatusBox
              sx={ {
                "svg .path": 
                { fill: theme.customColors.systemPrimary01 }
              } }
              accent={ accentColor }
              icon={ (status === STATUS_LO.P) ? "Icons_info" : "completato" }
              color={ theme.customColors.systemPrimary01 }
            />
            <CardType
              ecmType={ ecmType }
              ecm_specialization={ ecm_specialization }
              fontSize="0.875rem"
              lineHeight={ 3 / 2 }
              marginBottom={ "8px" }
              textTransform={ "uppercase" }
              fontWeight={ 700 }
              isSurvey={ Boolean(isSurvey) }
              isTest={ Boolean(isTest) }
              learningObjectTypology={ learningObjectTypology }
              learningObjectType={ learningObjectType }
            />
            <TitleTypography
              $clamp={ 1 }
              fontSize="1.5rem"
              lineHeight={ 5 / 4 }
              marginBottom={ "8px" }
            >
              { title }
            </TitleTypography>
            <CardCategories { ...props } marginBottom={ "8px" }/>
            <Stack gap={ 1 }>
              <DetailTypography
                $clamp={ 3 }
                fontSize="1rem"
                lineHeight={ "1.3125rem" }
              >
                { shortDescription }
              </DetailTypography>
              <CardSignature
                startDate={ startDate }
                endDate={ endDate }
                editionNumber={ editionNumber }
                alignItems="center"
                gap={ 2 }
                expirationDate={ expirationDate }
                duration={ duration }
                learningObjectTypology={ learningObjectTypology }
              />
              <CardCta
                disableFavourites={ Boolean(isTest || isSurvey) }
                marginTop={ theme.spacing(1) }
                gap={ theme.spacing(2) }
                booked={ booked }
                disabled={ disabled }
                ecmRegistration={ ecmRegistration }
                enrollType={ enrollType }
                id={ id }
                isAutoSubscribeEnable={ isAutoSubscribeEnable }
                isCard={ true }
                ecm_specialization={ ecm_specialization }
                learningObjectType={ learningObjectType }
                learningObjectTypology={ learningObjectTypology }
                percentageOfCompletion={ percentageOfCompletion }
                status={ status }
                vertical
              />
            </Stack>
            <LinkWithInteractableOverlay
              $disableLink={ disabled }
              $hideCta
              href={ `/esplora/dettaglio/${id}` }
              tabIndex={ 0 }
            >
              { title }
            </LinkWithInteractableOverlay>
          </Grid>
        </CarouselCardGrid>
      </CarouselSlideBox>
    </StyledSwipeableDrawer>
  );
}

function StatusBox({
  expirationDate,
  icon,
  ...props
} : StatusBoxProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExpired = hasPassedExpiration(expirationDate);

  return (
    <StyledStatusBox
      { ...props }
      width={ isExpired ? "fit-content" : "2.5rem" }
    >
      <Icon
        icon={ icon }
        color={ theme.customColors.systemSecondary05 }
        size={ "20px" }
      />
      {
        (expirationDate && isExpired) ? (
          <Typography
            component="span"
            fontSize="0.75rem"
            fontWeight="500"
          >
            { t("expired_on") } { expirationDate.toString() }
          </Typography>
        ) : null
      }
    </StyledStatusBox>
  );
}
