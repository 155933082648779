import {
  Box, Typography,
  useTheme
} from "@mui/material";
import { getHours, getMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CardGrid,
  CardBodyGrid,
  InfoTypography,
  SeatsTypography
} from "./CardEdition.styles";
import { CardEditionProps } from "./CardEdition.types";
import {
  Icon,
  Link, StyledBoxContact
} from "../../components";
import { LEVEL, STATUS_LO } from "../../consts";
import { 
  useEnrollmentMutation,
  useInvalidateEditionsQuery,
  useInvalidateLearningObjectQuery,
  usePatchMutation
} from "../../queries";
import { formatDate } from "../../services";
import { getError } from "../../utils/error";
import { getLearningObjectType } from "../../utils/general";
import { ButtonText } from "../Button";

export function CardEdition({
  booked,
  bookedSeats,
  editionId,
  endTime,
  enrollType,
  gMapsUrl,
  id,
  learningObjectTypology,
  link,
  location,
  maxSeats,
  rooms,
  courseId,
  rootId,
  startTime,
  status,
  statusEdition,
  teachers,
  ...props
}: CardEditionProps) {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const today = new Date().getTime();
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerText, setBannerText] = useState("");
  const isRejected = statusEdition === "R";
  const isBookable = !booked && (status === "N" || status === "T" || status === "R" );


  const { invalidateLearningObject } = useInvalidateLearningObjectQuery({
    courseId:courseId,
    id:id,
    learningObjectTypology:learningObjectTypology,
    rootId:rootId
  });
  const { invalidateEditions } = useInvalidateEditionsQuery({
    classroomId:id.toString()
  });

  const seatsAvailable = maxSeats - bookedSeats > 0;

  const newStartDate = new Date(startTime);
  const newEndDate =  new Date(endTime);
  const startEdition = formatDate(newStartDate, i18n.language)?.replaceAll("/","-");
  const endEdition = formatDate(newEndDate, i18n.language)?.replaceAll("/","-");
  const getStartHour = getHours(new Date(newStartDate));
  const getEndHour = getHours(new Date(newEndDate));
  const startHour = getStartHour > 0 ? `${getStartHour}` : "00";
  const endHour = getEndHour > 0 ? `${getEndHour}` : "00";
  const getStartMinutes = getMinutes(new Date(newStartDate));
  const getEndMinutes = getMinutes(new Date(newEndDate));
  const startMinutes = getStartMinutes > 0 ? `.${getStartMinutes}` : ".00";
  const endMinutes = getEndMinutes > 0 ? `.${getEndMinutes}` : ".00";
  const firstTeacher = teachers?.[0]?.completeName;
  const otherTeachersLength = teachers?.length - 1;

  const idEnrollment = rootId ? 
    rootId : courseId ? 
      courseId : id;

  const typologyEnrollment = rootId ? 
    LEVEL.PATH : courseId ? 
      LEVEL.COURSE : 
      getLearningObjectType(learningObjectTypology);

  // console.log(idEnrollment, typologyEnrollment);

  const currentDay =
    booked && (
      (today >= new Date(startTime).getTime()  -  (2*60*60*1000)) &&
      (today <=  new Date(endTime).getTime())
    );

  const roomsString = rooms && rooms.length > 0 ? rooms.toString().replaceAll(","," - ") : null;

  const {
    mutate: addEnrollmentMutation,
    error: enrollmentMutationError,
    isError: enrollmentMutationIsError,
    isSuccess: enrollmentMutationHadSuccess,
    isLoading:enrollmentIsLoading
  } = useEnrollmentMutation({
    enrollType:enrollType,
    learningObjectId: idEnrollment,
    learningObjectTypology:typologyEnrollment
  });

  const {
    mutate: addEnrollmentEditionMutation,
    error: enrollmentEditionMutationError,
    isError: enrollmentEditionMutationIsError,
    isSuccess: enrollmentEditionMutationHadSuccess,
    isLoading:enrollmentEditionIsLoading
  } = usePatchMutation({
    editionId:editionId,
    learningObjectId: id,
    learningObjectTypology:learningObjectTypology
  });

  useEffect(()=> {

    if(enrollmentMutationHadSuccess){
      addEnrollmentEditionMutation();
    }
  },[
    addEnrollmentEditionMutation, 
    enrollmentIsLoading, 
    enrollmentMutationError, 
    enrollmentMutationHadSuccess, 
    enrollmentMutationIsError
  ]);

  useEffect(()=> {

    if(
      (enrollmentMutationIsError && enrollmentMutationError) || 
      (enrollmentEditionMutationIsError && enrollmentEditionMutationError)
    ){

      setBannerText(
        getError(enrollmentMutationError)?.[0]?.message
        ?? getError(enrollmentMutationError)?.[0]?.message
        ?? getError(enrollmentEditionMutationError)?.[0]?.message
        ?? getError(enrollmentEditionMutationError)?.[0]?.message
        ?? ""
      );
    }

    if(enrollmentEditionMutationHadSuccess && !enrollmentEditionIsLoading){
      invalidateLearningObject();
      invalidateEditions();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    enrollmentEditionIsLoading, 
    enrollmentEditionMutationError, 
    enrollmentEditionMutationHadSuccess, 
    enrollmentEditionMutationIsError, 
    enrollmentIsLoading, 
    enrollmentMutationError, 
    enrollmentMutationHadSuccess, 
    enrollmentMutationIsError]);

  return (
    <>

      <CardGrid
        container
        booked={ booked }
        { ...props }
      >
        <CardBodyGrid
          container
          item
          booked={ booked }
          currentDay={ currentDay }
          gap="1rem"
          width="50%"
        >
          {
            startEdition === endEdition ?
              <InfoTypography fontWeight={ 700 }>
                <Icon
                  icon="Icons_scadenza-e-edizioni"
                  size="20px"
                />
                { /* { formatDate(date, i18n.language) } */ }
                { startEdition }
              </InfoTypography> :
              <InfoTypography fontWeight={ 700 }>
                <Icon
                  icon="Icons_scadenza-e-edizioni"
                  size="20px"
                />
                { t("from") + " " +  startEdition + " " + t("to") + " " + endEdition }

              </InfoTypography>
          }

          <InfoTypography className="location">
            <Icon
              icon={ link ? "Icons_meeting" : "Icons_location" }
              size="20px"
            />
            {
              link ? (
                <Link
                  href={ link }
                  target={ "_blank" }
                  style={ {
                    pointerEvents:!currentDay? "none" : "auto"
                  } }
                >
                  { t("meeting_online") }
                </Link>
              ) : (
                gMapsUrl ? (
                  <p>
                    <Link
                      href={ gMapsUrl }
                      target={ "_blank" }
                      style={ {
                        textDecoration:"underline"
                      } }
                    >
                      { location + " " }
                    </Link>
                    { roomsString
                      ?  " - " + t("room", { count:rooms.length }) + " : " + roomsString
                      : ""
                    }
                  </p>
                ) : (
                  <p>
                    { location }{ roomsString
                      ?  " - " + t("room", { count:rooms.length }) + " : " + roomsString
                      : ""
                    }
                  </p>
                )
              )
            }

          </InfoTypography>
          <InfoTypography className="time">
            <Icon
              icon="Icons_time"
              size="20px"
            />
            {  startHour + startMinutes  } - { endHour  + endMinutes }
          </InfoTypography>
          {
            teachers && teachers?.length > 0 ? 
              <InfoTypography className="teachers">
                <Icon icon="Icons_Profilo"
                  size="20px"/>
                { `${firstTeacher}` }  
                {
                  teachers?.length > 1 ? 
                    t("more", { count: otherTeachersLength }) 
                    : 
                    null 
                }
              </InfoTypography> : 
              null
          }
          
        </CardBodyGrid>
        <div className="separator"  ></div>
        <CardBodyGrid
          item
          container
          alignItems="center"
          booked={ booked }
          currentDay={ currentDay }
          gap={ theme.spacing(1) }
          width="40%"
        >
          <StyledBoxContact $visible={ bannerVisible }
            sx={ {
              left:"unset",
              right:0,
              top:0
            } }>
            <Typography
              display={ "flex" }
              fontWeight= { 500 }
              color= { theme.customColors.textInput }
              maxWidth={ "calc(100% - 44px)" }
            >
              { bannerText }
            </Typography>
            <Icon
              icon="close"
              size={ 19 }
              color= { theme.palette.primary?.main }
              onClick={ ()=> { setBannerVisible(false) } }
            />
          </StyledBoxContact>

          <Box>
            <SeatsTypography component="span">{ bookedSeats || 0 }</SeatsTypography>
            <SeatsTypography
              component="span"
              color={ theme.palette.primary?.main }
              fontWeight={ 600 }
            > / </SeatsTypography>
            <SeatsTypography component="span" fontWeight={ 700 }>{ maxSeats }</SeatsTypography>
          </Box>
          <InfoTypography color={ theme.customColors.systemSecondary03 }>
            <Icon
              color={ theme.customColors.systemSecondary03 }
              icon={ "Icons_obbligatorio" }
              size="20px"

            />
            { t("places_available", { count:maxSeats - bookedSeats }) }
          </InfoTypography>
        
          <ButtonText 
            sx={ {
              [theme.breakpoints.down("sm")]:{
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "129px"
              }
            } }
            disabled={ isRejected || !seatsAvailable || !isBookable }
            onClick={ ()=> {
              if(!booked){
                if(isBookable && enrollType === "REQUESTED_AUTO_ENROLL") {
                  addEnrollmentMutation();
                }
                else {
                  setBannerVisible(true);
                  setBannerText(t("contact_text"));
                }
              }
             
              
            } }>
            {  
              statusEdition === STATUS_LO.W    ? 
                t("validation_text") :  
                statusEdition === STATUS_LO.E ? 
                  t("registered") :
                  t("register_request") 
                 
            }
          </ButtonText>
        
        </CardBodyGrid>
      </CardGrid>
    </>

  );
}
