import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GuardedLink,
  DndCapabilityStack,
  Spinner,
  ModalOnboardingSkip
} from "../../components";
import { CategoriesCapability } from "../../components/DndCapabilities/DndCapabilities.types";
import { StyledBottomBar } from "../../components/OnBoarding/OnBoarding.styles";
import { Progress } from "../../components/Progress/Progress.component";
import {
  CAPABILITY_BUCKETS
} from "../../consts";
import { 
  type capabilityType,
  usePutCapabilities,
  useUserCapabilitiesQuery,
  OnBoardingItemType
} from "../../queries/onboarding";
import { useAuthStore } from "../../stores";
import { 
  getPurposeBucket, 
  setLevelCapabilityBucket
} from "../../utils/dndStack";



export function ProfileCapabilities() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { state:routerState } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const refetchUserInfo = useAuthStore(state => state.refetchUserInfo);
  const userInfo = useAuthStore(state => state.userInfo);



  const[capabilities, setCapabilities]=useState<CategoriesCapability>(null);
  const [capabilitiesSelected, setCapabilitiesSelected]=useState<capabilityType[] | null>(null);
  const[openModalSkip, setOpenModalSkip]=useState(false);

  
  const pageSize= 40;

  const {
    data:userCapabilitiesData,
    hasNextPage: hasMoreCapabilities,
    fetchNextPage: fetchMoreCapabilities,
    // isError: isErrorAreas,
    // isFetchingNextPage: isFetchingMoreCapabilities,
    isInitialLoading:isFetchingCapabilities
  } = useUserCapabilitiesQuery({
    pageSize:pageSize
  });

  
  useEffect(()=> {
    const capabilitiesApi = userCapabilitiesData?.pages?.reduce((acc, page) => {
      const contents = page.output ?? [];
      return acc?.concat(contents);
    }, [] as OnBoardingItemType[]);
     
    

    setCapabilities(
      capabilitiesApi?.map((category) => {
        const userCategorySelected = userInfo?.capabilities?.find(capability => capability.id === category.id)?.purpose;
        return {
          active:false,
          bucket: userCategorySelected ? getPurposeBucket(userCategorySelected) : null,
          disabled:false,
          isDisabled:false,
          type:"executive",
          ...category,
          id:category.id.toString()
        };
      }) ?? null);

  },[
    userCapabilitiesData, 
    isFetchingCapabilities, 
    userInfo?.capabilities] );

  useEffect(()=> {
    setCapabilitiesSelected(capabilities?.flatMap((capability)=> {
      if(capability.bucket !== null){
        return {
          id:Number(capability.id),
          purpose:setLevelCapabilityBucket(capability.bucket)
        } as capabilityType;
      }
      else return [];
    }) ?? null);

  },
  [
    capabilities
  ]);


  const {
    mutate:mutateCapabilities,
    isSuccess:mutateCapabilitiesisSuccess
  } =  usePutCapabilities({
    capabilities:capabilitiesSelected
  });

  useEffect(()=> {
    if(mutateCapabilitiesisSuccess){
      refetchUserInfo();
      navigate( routerState?.isSummary ? "../summary" : !routerState?.first ? "/profile" :  "../preferences", {
        state:{
          ...routerState,
          onBoardingPercentage: "80%"
        }
      });
    }
    
  },[
    capabilitiesSelected,
    routerState, 
    mutateCapabilitiesisSuccess, 
    navigate,
    refetchUserInfo
  ]);

  const { t } = useTranslation();

  return (
    <Stack
      gap={ isMobile ? 1 : 3 }
      width={ "100%" }
    >
      <Typography
        color="primary"
        fontSize={ isMobile ? theme.spacing(2.5) : theme.spacing(4) }
        fontWeight={ 700 }
        lineHeight={ 19 / 16 }
        textAlign={ "start" }
      >
        { t("skills_desc") }
      </Typography>
      <Typography
        fontSize={ isMobile ? theme.spacing(1.75): theme.spacing(2.5) }
        lineHeight={ 21 / 20 }
      >
        { t("skills_choose") }
      </Typography>
      {
        capabilities && capabilities.length   && !isFetchingCapabilities ? 
          <DndCapabilityStack
            height={ !isMobile ? "550px" : "fit-content" }
            buckets={ CAPABILITY_BUCKETS }
            hasNextPage={ Boolean(hasMoreCapabilities) }
            fetchNextPage={ fetchMoreCapabilities }
            state={ [capabilities, setCapabilities] }
            totalElements={ userCapabilitiesData?.pages?.[0]?.metadata?.total_elements ?? 0 }
          /> : 
          <Spinner/>
      }
    
      <StyledBottomBar 
        sx={ {
          justifyContent:!routerState?.first ? "center" : undefined
        } }
      >

        {
          routerState?.first ? 
            <Progress percentageOfCompletion={ !capabilitiesSelected?.length ? 
              routerState?.onBoardingPercentage : "80%" }/> : 
            null
        }
        
      
        <Box
          display={ "flex" }
          gap={ 2 }
        >
          {
            routerState?.first ? 

              <GuardedLink
                variant="outlined"
                href=""
                onClick={ () => {
                  setOpenModalSkip(true);
                  return false;
                } }
              >
                { t("later") }
              </GuardedLink> : null
          }
     
          <GuardedLink
            href={ routerState?.isSummary ? "../summary" : !routerState?.first ? "/profile" :  "../preferences" }
            onClick={ () => {
              if(capabilitiesSelected){
                mutateCapabilities();
              }
              else {
                return true;
              }
               
            } }
            state={ {
              ...routerState,
              onBoardingPercentage: "80%"
            } }
          >
            { routerState?.first ? t("continue")  : t("confirm") }
          </GuardedLink>
        </Box>
       
      </StyledBottomBar>
      {
        <ModalOnboardingSkip 
          open={ openModalSkip }
          onClose={ () =>  setOpenModalSkip(false) }
        />
      }
    </Stack>
  );
}
