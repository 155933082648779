import { useTheme, useMediaQuery, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  BadgeGoals,
  GridBox,
  Icon,
  Link
} from "../../components";

import { useAchievementsQuery } from "../../queries";
import { useNavbarStore } from "../../stores";

export function ContentGoals (){

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  const {
    data: learnerAchievements
  } = useAchievementsQuery({});
  return (

    <GridBox
      columns={ (!learnerAchievements || !(learnerAchievements.length > 0 )) ? 1 : isMobile ? 4 : isTablet ? 4  : 6 }
      gap={ 2 }
      sx={ {
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns:(learnerAchievements && learnerAchievements?.length > 0)
            ? "repeat(4, 1fr)"
            : "unset",
          justifyContent: "flex-start"
        }
      } }
    >
      {
        (!learnerAchievements || !(learnerAchievements.length > 0 )) ? (
          <>
            <Typography
              fontSize={ isMobile ? "1rem" : "1.5rem" }
              fontWeight={ 700 }
              lineHeight={ "1.25" }>
              { t("no_certification") }
            </Typography>
            <Typography
              fontSize={ isMobile ? "0.875rem" : "1.25rem" }
              lineHeight={ isMobile ? "1.3125" : "1.05" }
            >
              { t( "new_certifications") }
            </Typography>
            <Link
              display="flex"
              alignItems="center"
              href={ "/esplora" }
              gap={ "10px" }
              onClick={ ()=> {
                setNavbarItem("EXPLORE");
              } }
            >
              <Typography
                sx={ {
                  alignItems:"center",
                  fontSize: isMobile ? "12px" : theme.spacing(2.25),
                  fontWeight:500,
                  gap:theme.spacing(1),
                  justifyContent:"flex-start",
                  lineHeight:"1",

                  ".icon path":{
                    fill:theme.palette.primary?.main
                  }
                } }
              >
                { t("go_to_explore") }
              </Typography>
              <Icon 
                icon={ "arrow_right_horizontal" }
                color={ theme.customColors?.systemPrimary02 }
                size={ 20 }/>
            </Link>
          </>
        ) : (
          learnerAchievements?.map((badge) => (
            <BadgeGoals
              key={ `badge-${badge.certificate?.id || badge.badge?.id}` }
              small={ false }
              badgeDisabled={ true }
              badge={ badge.badge }
              certificate={ badge.certificate }
            />
          ))
        )
      }
    </GridBox>
  );
}
