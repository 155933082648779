import {
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import {
  ERRORS,
  SERVICES
} from "../consts";
import { fetcherFactory } from "../services/fetcher";
import { useAuthStore } from "../stores";
import { getEnvironmentVariables } from "../utils/general";

export type RelativePath = {
  apiPath: string
  id: number
  serviceType:string
  title: string
}

export type PageStructureResponse = {
  pageName: string,
  relativePaths: RelativePath[]
}

const { basePath } = getEnvironmentVariables();


export function useStructuresQuery(
  {
    enabled=true,
    pageName,
    queryKey=[]
  } : {
    enabled?: boolean,
    pageName: string,
    queryKey?: string[]
}) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id?.toString()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id?.toString()) ?? "";
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);
  const fetcher = fetcherFactory();

  const smartConfiguratorServices =
    Object.entries(smartConfiguration ?? {})
      .reduce((acc, [key, val]) => {
        if (SERVICES.includes(key)) {
          acc[key] = Boolean(val);
        }
        return acc;
      }, {} as Record<string, boolean>);


  return useQuery<
    unknown,
    unknown,
    PageStructureResponse,
    QueryKey
  >(
    {
      enabled: enabled && Boolean(
        corporateId
        && idToken
        && sessionToken
        && initiativeId
        && pageName
        && Object.keys(smartConfiguration ?? {}).length
      ) && !isSeedingSession,
      queryFn: () => fetcher(
        `${basePath}/learning-catalogue/${corporateId}/${initiativeId}/pageStructures?pageName=${pageName}`,
        {
          headers:{
            "Authorization": idToken,
            "x-ada-session-token": sessionToken
          }
        })
        .then((res) => {
          return res.json();
        })
        .then((data: PageStructureResponse) => {
          data.relativePaths = smartConfiguration ? (
            data.relativePaths.filter((relativePath) => (
              smartConfiguratorServices[relativePath.serviceType]
               || Boolean(relativePath.serviceType) === false
            ))
          ) : data.relativePaths;
          return data;
        })
        .catch((error) => {
          if (error === ERRORS.UNAUTHORIZED) {
            setUnauthorized(true);
            throw error; // rethrow so that react query doesn't complain about undefined return value
          } else {
  
            throw error;
          }
        }),
      queryKey: [
        `${idToken}|${sessionToken}`,
        "pageStructures",
        Object.values(smartConfiguratorServices).join(""),
        pageName,
        isSeedingSession,
        ...queryKey
      ]
    }
  );
}
