import { Avatar, BottomNavigation, BottomNavigationAction, Box,
  Dialog,
  Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { 

  useEffect,
  useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AttachmentsBox,
  ContentBox,
  ModifyContentModalBox, 
  TextContent, 
  TitleInputBase, 
  TitleTypography, 
  ForbiddenLabelTypography,
  BodyInputBase
} from "./ModifyContentModal.styles";
import type { ModifyContentModalProps } from "./ModifyContentModal.types";

import { 
  ButtonText, 
  Icon , 
  CreateModalSuccess,
  Spinner
} from "../../components";
import { StyledError } from "../../components/CreateContentModal/CreateContentModal.styles";
import { POST_TYPE } from "../../consts";
import { useGetProfileImageQuery } from "../../queries";
import { 
  useModifyPostQuery
} from "../../queries/community";
import { useAuthStore } from "../../stores";
import { stringAvatar } from "../../utils/community";

export function ModifyContentModal({
  body,
  closeDrawer,
  id,
  onClose,
  open,
  postType,
  selectedTopic,
  selectedFilter,
  ...props
}: ModifyContentModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = useAuthStore(state => state.userData);
  const [bodyValue, setBodyValue] = useState<string | undefined>(body);
  const [linkValue, setLinkValue] = useState<string | undefined>("linkvalue" in props ? props.linkvalue : undefined);
  const [linkLabel, setLinkLabel] = useState<string | undefined>("linklabel" in props ? props.linklabel : undefined);
  const [showLinkForbiddenLabel, setShowLinkForbiddenLabel]= useState(false);
  const [showBodyForbiddenLabel, setShowBodyForbiddenLabel]= useState(false);
  const [setUrl, setSetUrl]= useState(Boolean(linkValue));

  const { data: profileImage  } = useGetProfileImageQuery({});


  const {
    mutate: mutateModify,

    isLoading: modifyIsLoading,
    isError,
    isSuccess,
    error
  } = useModifyPostQuery( 
    postType === POST_TYPE.FREE ? {
      description: bodyValue,
      id: id,
      linkLabel: linkLabel,
      linkValue: linkValue,
      postType: postType,
      selectedFilter:selectedFilter ,
      selectedTopic:selectedTopic
    } : {
      description: bodyValue,
      id,
      loId: "loId" in props ? props.loId : "",
      postType,
      selectedFilter:selectedFilter,
      selectedTopic:selectedTopic
    });

  //managing fobidden word
  useEffect(() => {
    if( !modifyIsLoading && isError) {
      const errorObject = JSON.parse(error as string);

      if(errorObject.message == "Forbidden word has been detected.") {

        errorObject.errors.forEach((error: Record<string, string|Array<string>>) => {
          let errorBody = "" as string; 
        
          switch (error.field) {
       
          case "body":
        
            errorBody = bodyValue ? replaceForbidden(bodyValue, error.words as string[]) : "";
            setBodyValue(errorBody);
  
            setShowBodyForbiddenLabel(true);
            break;
      
          case "attachments_link":
            setShowLinkForbiddenLabel(true);
            break;
          default:
            break;
          }
        });
      }
    }
  },[
    bodyValue,
    error, 
    isError, 
    modifyIsLoading
  ]);


  const contentItems = getContentItems({ size: isMobile ? "15px" : "20px" });
  

  const handlePublish = () => {
    mutateModify();
  };
  

  
  return  (
  
    <Dialog
      maxWidth={ false }
      open={ open }
      onClose={ onClose }
      scroll={ "paper" }
      sx={ {
        ".MuiDialog-paper ": {
          background: theme.linearGradients.gradientB,
          borderRadius:"8px",
          maxHeight:"calc(100% - 32px)",
          padding: "10px"
        }
      } }
    >
     
      <ModifyContentModalBox
        container
        { ...props }
      >
        { 
          isError  ? 
             
            <StyledError>
              <Icon 
                color={ theme.customColors.systemSecondary03 }
                icon="Icons_obbligatorio"
                size={ 20 }
              />
              { t("forbidden_label_topic") }

            </StyledError>: 
            null
        }
        {
          modifyIsLoading ? 

            <Spinner/> : 
           
            isSuccess ?
              <CreateModalSuccess
                title={ t("modified_post") }
                onClose={ ()=> {
                  closeDrawer();
                }
                }
              /> :
        
              <>
                <Box
                  sx={ { marginRight: isMobile ? "20px" : 0 } }
                >
                  <Icon
                    icon={ "close" }
                    size={ "19px" }
                    cursor="pointer"
                    color={ theme.palette.primary?.main }
                    onClick={ ()=> {
                      closeDrawer();
                    } }
                  />
                </Box>
                <Box
                  paddingRight={ "16px" }
                  maxWidth={ isMobile ? "335px" : "582px" }
                >
                  <TextContent>
                    <TitleTypography>
                      { "Modifica il tuo post" }
                    </TitleTypography>
                  </TextContent>
                  <Divider
                    sx={ {
                      background:theme.customColors.border,
                      height:"0px",
                      margin:"16px 0",
                      width:isMobile ? "331px":"582px"
                    } }
                  />
                  <Box
                    sx={ {
                      alignItems: "center",
                      display: "flex",
                      gap:theme.spacing(2),
                      margin:"24px 0"
                    } }
                  >
                    <Avatar
                      { ...stringAvatar(`${userData?.name} ${userData?.surname} `) }
                      sx={ {
                        height:isMobile ? "40px" : "56px",
                        width:isMobile ? "40px" : "56px"
                      } }
                      src={ profileImage }
                    />
                    <Typography
                      sx={ {
                        fontSize: isMobile ? "16px" : "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "21px"
                      } }
                    >
                      { userData?.name }  { userData?.surname }
                    </Typography>
                  </Box>
          
                  
                  <Stack>
                    { showBodyForbiddenLabel && 
                      <ForbiddenLabelTypography key={ "forbidden-label-body" }>
                        { t("forbidden_label_topic") }
                      </ForbiddenLabelTypography>
                    }
                
                    <BodyInputBase
                      multiline
                      value={ bodyValue }
                      onChange={ (changeEvent) => setBodyValue(changeEvent.target.value) }
                      placeholder={ `${t("talk_about")}` }  
                    />
                  </Stack>
          
                  <ContentBox marginTop={ "16px" }>
                  

                    <Box
                      sx={ {
                        [theme.breakpoints.down("sm")]: {
                          width:"205px"
                        }
                      } }
                    >
                      <BottomNavigation
            
                        sx={ {
                          backgroundColor:"#0000",
                          gap: "32px",
                          justifyContent:" flex-start"
                        } }
                        showLabels>
                        {
                          postType === POST_TYPE.FREE ? contentItems.map(({ icon, label }, index) => {
                            return (
                              <BottomNavigationAction
                                sx={ {
                                  color:theme.customColors.textPrimary,
                                  flex:"unset",
                                  flexDirection:"row",
                                  fontSize:"16px",
                                  fontStyle: "normal",
                                  gap:"8px",
                                  lineHeight:"21px",
                                  padding:"0px"
                                } }
                                key={ `key_${index}`  }
                                label={ label }
                                icon={ icon }
                              />
                            );
                          }) : null
                        }
                      </BottomNavigation> 
                    </Box>
                    { setUrl ? 
                      <>
                        { showLinkForbiddenLabel && 
                          <ForbiddenLabelTypography key={ "forbidden-label-link" }>
                            { t("forbidden_label_topic") }
                          </ForbiddenLabelTypography>
                        }
                        <AttachmentsBox
                          padding= "16px 0px"
                        >
                          <Stack
                            flexDirection={ "column" }
                            gap={ theme.spacing(2) }
                            padding="0px 8px"
                            flexGrow={ "1" }
                          >
                            <TitleInputBase 
                              value={ linkValue }
                              onChange={ (changeEvent) => {
                                setLinkValue(changeEvent.target.value);
                              } }
                              placeholder={ `${t("insert_url")}` }
                              sx={ {
                                [theme.breakpoints.down("sm")]:{
                                  width: "252px"
                                }
                              } }
                            />
                            <TitleInputBase 
                              value={ linkLabel }
                              onChange={ (changeEvent) => {
                                const uri = changeEvent.target.value.replace(/.+\/\/|www.|\.+/g, "");
                                const encoded = encodeURI(uri);
                                const decoded = decodeURI(encoded);
                                setLinkLabel(decoded);
                              }
                                
                              }
                              placeholder={ `${t("insert_link_title")}` }
                              sx={ {
                                [theme.breakpoints.down("sm")]:{
                                  width: "252px"
                                }
                              } }
                            />
                          </Stack>
                          <Box
                            padding={ "0px" }
                          >
                            <Icon
                              color={ theme.palette.primary?.main }
                              icon={ "close" }
                              width={ "27px" }
                              height={ "19px" }
                              cursor="pointer"
                              onClick={ ()=> {
                                setSetUrl(!setUrl);
                              } }
                            />
                          </Box>
                        </AttachmentsBox>
                      </>
                      : 
                
                      null 
                
                    }
                

                    <Stack
                      flexDirection="row"
                      justifyContent={ "center" }
                      marginTop={ "16px" }
                    >
                      <ButtonText
                        sx={ {
                          gap:theme.spacing(3),
                          maxWidth:"120px"
                        } }
                        disabled={ 
                          !bodyValue 
                        }
                        onClick={ ()=> handlePublish() }
                      >
                        { t("modify") }
                      </ButtonText>
                    </Stack>
                  </ContentBox>
                </Box>
              </>
        }
       
      </ModifyContentModalBox>
    </Dialog>
   
  );

  function replaceForbidden(html: string, forbiddenWords: Array<string>): string {
    let initialHtml = html;

    const resultHtml = forbiddenWords.reduce(
      (partialHtml, currentWord) => {
        var reg = new RegExp(currentWord, "gmi");
        return partialHtml.replace(reg, `<span style="color: red;"><ins><strong>${currentWord}</strong></ins></span>`);
      },
      initialHtml
    );

    return resultHtml;
  }

  function getContentItems({ size }: { size: string }) {
    return [
      {
        icon: <Icon  color={ setUrl ? theme.palette.primary?.main : theme.customColors.systemPrimary01 } 
          icon={ "Icons_link" } size={ size } />,
        label: <Typography
          color={ setUrl ? theme.palette.primary?.main : theme.customColors.textPrimary }
          fontSize={ "16px" }
          onClick={ ()=> setSetUrl(true) }
        >
          { "Link" }
        </Typography> }
    ];
  }

}
